import Icon, { IconProps } from "components/Icons"
import React from "react"

const LogoutIcon: React.FC<IconProps> = ({ strokeWidth = 2, ...restProps }) => {
  return (
    <Icon {...restProps} stroke="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
        d="M5 12h12"
        vectorEffect="non-scaling-stroke"
      />
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
        d="M13 16l4-4-4-4"
        vectorEffect="non-scaling-stroke"
      />
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
        d="M15 4V1H1v22h14v-3"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export default LogoutIcon
