import { MenuResponse } from "@ignite/api/menus"
import MenuItem from "@mui/material/MenuItem"
import Dropdown from "components/Dropdown"
import RelativeLink from "components/RelativeLink"
import React from "react"

type StyleProps = {
  mobileView?: boolean
}

type MenuPickerProps = StyleProps & {
  content: MenuResponse
}

const MenuPicker: React.FC<MenuPickerProps> = ({
  content,
  mobileView,
  ...restProps
}) => {
  return (
    <>
      {content?.children.map((menu) =>
        menu.children.length > 0 ? (
          <Dropdown
            key={menu.id}
            defaultButtonTitle={menu.title || ""}
            title={menu.title || ""}
            sx={({ spacing }) => ({
              verticalAlign: "middle",
              fontSize: 14,
              fontWeight: 600,
              padding: (mobileView && "0 0 10px 20px") || 0,
              marginLeft: (mobileView && spacing(0)) || spacing(10),
              width: (mobileView && "fit-content") || "auto",
            })}
            updateTitleOnSelect={false}
            {...restProps}
          >
            {menu.children.map((item, i) => (
              <MenuItem key={`${item.id}-${i}`}>
                <RelativeLink href={item.url}>{item.title}</RelativeLink>
              </MenuItem>
            ))}
          </Dropdown>
        ) : (
          <RelativeLink
            key={menu.id}
            href={menu.url}
            sx={({ spacing }) => ({
              verticalAlign: "middle",
              fontSize: "14px !important",
              fontWeight: "600 !important",
              padding: (mobileView && "0 0 10px 20px") || 0,
              marginLeft: (mobileView && spacing(0)) || spacing(10),
              width: (mobileView && "fit-content") || "auto",
            })}
          >
            {menu.title}
          </RelativeLink>
        )
      )}
    </>
  )
}

export default MenuPicker
