import FormControl from "@mui/material/FormControl"
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import MuiSelect, { SelectProps as MuiSelectProps } from "@mui/material/Select"
import ChevronIcon from "components/Icons/ChevronIcon"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ spacing, breakpoints, typography }) => ({
  root: {
    minWidth: "inherit",
    "& .MuiInputBase-formControl": {
      color: "currentColor",
    },
    "& .MuiInputBase-root, .MuiFormLabel-root, .MuiMenuItem-root": {
      [breakpoints.down("md")]: {
        ...typography["primary-200"],
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-outlined": {
      padding: spacing(2),
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      color: "currentColor",
      transform: `translate(${spacing(2)}, ${spacing(2)}) scale(1)`,
      lineHeight: "20px",
    },
    "& .MuiInputLabel-shrink": {
      color: "currentColor",
      transform: `translate(${spacing(2)}, ${spacing(0)}) scale(0.75)`,
      [breakpoints.down("md")]: {
        transform: `translate(${spacing(2)}, ${spacing(3)}) scale(0.75)`,
      },
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
  },
  menuItem: {
    [breakpoints.down("md")]: {
      ...typography["primary-200"],
    },
  },
}))

export type SelectItemType = {
  value: string | number
  text: string
  selected: boolean
}
const IconComponent: React.FC = () => <ChevronIcon direction="down" size="sm" />

type SelectProps = {
  label: string
  items: SelectItemType[]
  InputLabelProps?: InputLabelProps
} & MuiSelectProps

const Select: React.FC<SelectProps> = ({
  label,
  items,
  onChange,
  InputLabelProps,
  autoWidth = true,
  ...restSelectProps
}) => {
  const { classes } = useStyles()

  return (
    <FormControl variant="outlined" className={classes.root}>
      <InputLabel {...InputLabelProps}>{label}</InputLabel>
      <MuiSelect
        IconComponent={IconComponent}
        onChange={onChange}
        {...restSelectProps}
        autoWidth={autoWidth}
      >
        {items.map((item: SelectItemType, i: number) => (
          <MenuItem
            key={`${item.value}-${i}`}
            value={item.value}
            className={classes.menuItem}
          >
            {item.text}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

export default Select
