import { CartName, CartResponse } from "@ignite/api/cart"
import { useCartContext } from "@ignite/react/context/cartContext"
import { useMarketContext } from "@ignite/react/context/marketContext"
import { getMarketType } from "@ignite/utils/market"
import useSystemLinks from "@ignite/utils/systemLinksUtil"
import Badge from "@mui/material/Badge"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import CartIcon from "components/Icons/CartIcon"
import React from "react"
import { useNavigate } from "react-router-dom"

type CartButtonProps = IconButtonProps & {
  cartName?: CartName
}

const CartButton: React.FC<CartButtonProps> = ({
  cartName = "basket",
  ...props
}) => {
  const {
    state: {
      carts: { data: carts },
      open,
    },
    actions: { toggle },
  } = useCartContext()

  const {
    state: { marketOptions },
  } = useMarketContext()

  const cartByName = carts?.reduce<Record<CartName, CartResponse>>(
    (acc, cart) => {
      acc[cart.name] = cart
      return acc
    },
    {} as Record<CartName, CartResponse>
  )

  const cart = cartByName ? cartByName[cartName] : null

  const toggleCart = () => {
    if (open[cartName] || (cart && cart.totalItems > 0)) {
      toggle(cartName)
    }
  }

  const links = useSystemLinks()
  const navigate = useNavigate()

  const handleCartClick = () => {
    const isRetailPortalMarket =
      getMarketType(marketOptions.data) === "b2bRetailPortal"

    if (isRetailPortalMarket) {
      navigate(links["businessCart"])
    } else {
      toggleCart()
    }
  }

  return (
    <IconButton
      onClick={handleCartClick}
      color="inherit"
      aria-label="cart"
      {...props}
      size="large"
    >
      <Badge
        badgeContent={cart?.totalItems || 0}
        color="primary"
        overlap="rectangular"
        data-testid="cart-badge"
      >
        <CartIcon />
      </Badge>
    </IconButton>
  )
}

export default CartButton
