import { useCartContext } from "@ignite/react/context/cartContext"
import { useMarketContext } from "@ignite/react/context/marketContext"
import { getMarketType } from "@ignite/utils/market"
import useSystemLinks from "@ignite/utils/systemLinksUtil"
import Box from "@mui/material/Box"
import Fade from "@mui/material/Fade"
import MuiSnackbar, { SnackbarProps } from "@mui/material/Snackbar"
import React from "react"
import { useNavigate } from "react-router-dom"

import SnackbarContent from "./SnackbarContent"

const Snackbar: React.FC<SnackbarProps> = ({ ...props }) => {
  const {
    state: { snackbarMessage },
    actions: { reset },
  } = useCartContext()

  const {
    state: { marketOptions },
  } = useMarketContext()

  const links = useSystemLinks()
  const navigate = useNavigate()

  const defaultCta = () => {
    reset("snackbarMessage")

    const isRetailPortalMarket =
      getMarketType(marketOptions?.data) === "b2bRetailPortal"

    const checkoutUrl = isRetailPortalMarket
      ? links["businessCheckout"]
      : links["checkout"]
    navigate(checkoutUrl)
  }

  const onClose = () => {
    reset("snackbarMessage")
  }

  return (
    (snackbarMessage && (
      <MuiSnackbar
        data-testid="cart-snackbar"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={(snackbarMessage && true) || false}
        autoHideDuration={6000}
        onClose={onClose}
        {...props}
      >
        <Fade>
          <Box display="flex" justifyContent="flex-end" maxWidth={1.0}>
            <SnackbarContent
              headerTitle={snackbarMessage.headerTitle}
              cta={snackbarMessage.cta || defaultCta}
              onClose={onClose}
              cartItem={snackbarMessage.item}
              buttonText={snackbarMessage.buttonText}
              SnackbarBodyComponent={snackbarMessage?.bodyComponent}
            />
          </Box>
        </Fade>
      </MuiSnackbar>
    )) ||
    null
  )
}

export default Snackbar
