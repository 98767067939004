import { Address, CheckoutResponseModel } from "@ignite/api/checkout"

import { asyncState, createStateContext } from "."

const defaultState = {
  checkout: asyncState<CheckoutResponseModel>(),
  address: asyncState<Address>(),
}

const [useCheckoutContext, checkoutContextCreator] = createStateContext(
  defaultState,
  {}
)

export { checkoutContextCreator, useCheckoutContext }
