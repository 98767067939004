import { useMenuContext } from "@ignite/react/context/menuContext"
import FooterMenu from "components/Footer/FooterMenu"
import FooterMenuItem from "components/Footer/FooterMenuItem"
import React, { useMemo } from "react"

const Menu: React.FC = () => {
  const {
    state: { menus },
  } = useMenuContext()

  const menu = useMemo(
    () => menus.data?.find((menu) => menu.id === "footer") ?? null,
    [menus]
  )

  return (
    menu && (
      <FooterMenu justifyAlignment="space-around">
        {menu.children &&
          menu.children?.map((tc) => (
            <FooterMenuItem
              key={tc.title}
              title={tc.title}
              url={tc.url}
              container
            >
              {tc.children &&
                tc.children?.map((cc) => (
                  <FooterMenuItem
                    item
                    key={cc.title}
                    title={cc.title}
                    url={cc.url}
                  />
                ))}
            </FooterMenuItem>
          ))}
      </FooterMenu>
    )
  )
}

export default Menu
