import { HrefLang } from "@ignite/api/contents"
import { MarketOption } from "@ignite/api/markets"
import { SelectChangeEvent } from "@mui/material/Select"
import MobileMenuSelect from "components/MobileMenu/MobileMenuSelect"
import React from "react"

type LanguagePickerMobileProps = {
  languages: MarketOption[]
  selectedLanguage: MarketOption
  hrefLangs: HrefLang[]
  onChange: (fromLanguage: string | any, toLanguage: string) => void
}

const convertLanguageDataToSelectArray = (languages: MarketOption[]) => {
  if (!languages) {
    return []
  }

  const selectData = languages.map((item) => ({
    text: item.displayName,
    value: item.code,
    selected: item.isSelected,
  }))

  return selectData
}

const LanguagePickerMobile: React.FC<LanguagePickerMobileProps> = ({
  languages,
  selectedLanguage,
  hrefLangs,
  onChange,
}) => {
  if (!languages || !selectedLanguage) {
    return null
  }

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newLanguage = event.target.value as string

    onChange(selectedLanguage, newLanguage)

    const url =
      (hrefLangs && hrefLangs[newLanguage]?.href) ?? `/${newLanguage}/`

    window.location.href = url
  }

  return (
    <MobileMenuSelect
      items={convertLanguageDataToSelectArray(languages)}
      onChange={handleChange}
      label={selectedLanguage?.displayName || ""}
      align="right"
    />
  )
}

export default LanguagePickerMobile
