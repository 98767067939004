import request from "./request"

type ClaimsApi = {
  submitClaim: (data: FormData) => Promise<void>
}

const claimsApi: ClaimsApi = {
  submitClaim: (formData) =>
    request.post(`/claim/submit`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
}

export default claimsApi
