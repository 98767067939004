import Box, { BoxProps } from "@mui/material/Box"
import Text from "components/Text"
import React from "react"
import colors from "theme/colors"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
  root: {
    color: colors.red.main,
  },
}))

export type CartLineItemMessageClassKey = "root"

const CartLineItemMessage: React.FC<BoxProps> = ({
  children,
  ...restProps
}) => {
  const { classes } = useStyles()

  return (
    <Box {...restProps}>
      <Text variant="primary-100" className={classes.root}>
        {children}
      </Text>
    </Box>
  )
}

export default CartLineItemMessage
