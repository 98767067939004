import Box, { BoxProps } from "@mui/material/Box"
import { DrawerProps } from "@mui/material/Drawer"
import React from "react"

type DrawerContainerProps = Pick<DrawerProps, "anchor"> & BoxProps

const DrawerContainer: React.FC<DrawerContainerProps> = ({
  anchor,
  children,
  ...boxProps
}) => {
  if (anchor === "left" || anchor === "right") {
    return (
      <Box
        width={480}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        maxWidth="100vw"
        {...boxProps}
      >
        {children}
      </Box>
    )
  }

  return children || (null as any)
}

export default DrawerContainer
