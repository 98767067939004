import { HrefLang } from "@ignite/api/contents"
import { MarketOption } from "@ignite/api/markets"
import MenuItem from "@mui/material/MenuItem"
import Dropdown from "components/Dropdown"
import React from "react"

type LanguagePickerDesktopProps = {
  defaultButtonTitle: string
  languages: MarketOption[]
  selectedLanguage: MarketOption
  hrefLangs: HrefLang[]
  onClick: (fromLanguage: string | any, toLanguage: string) => void
}

const LanguagePickerDesktop: React.FC<LanguagePickerDesktopProps> = ({
  defaultButtonTitle,
  languages,
  selectedLanguage,
  hrefLangs,
  onClick,
  ...restProps
}) => {
  if (!defaultButtonTitle || !languages || !selectedLanguage) {
    return null
  }

  return (
    <Dropdown defaultButtonTitle={defaultButtonTitle} {...restProps}>
      {languages &&
        languages.map((language, i) => (
          <MenuItem
            component="a"
            onClick={() => {
              onClick(selectedLanguage, language.code)
            }}
            href={hrefLangs[language.code]?.href ?? `/${language.code}/`}
            key={i}
          >
            {language.displayName}
          </MenuItem>
        ))}
    </Dropdown>
  )
}

export default LanguagePickerDesktop
