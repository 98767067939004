import defaultApi, { IgniteApi } from "@ignite/api"
import React from "react"

const ApiContext = React.createContext(null as any as IgniteApi)
const ApiContextProvider = ApiContext.Provider

export const useApiContext = () => React.useContext(ApiContext)

type Props = {
  api?: IgniteApi
  children?: React.ReactNode
}

const ApiContextContainer: React.FC<Props> = ({
  children,
  api = defaultApi,
}) => <ApiContextProvider value={api}>{children}</ApiContextProvider>

export default ApiContextContainer
