import { useApiContext } from "@ignite/react/context/ApiContextContainer"
import { useAuthContext } from "@ignite/react/context/authContext"
import { useUserContext } from "@ignite/react/context/userContext"
import useValidationSchema from "@ignite/react/hooks/useValidationSchema"
import useSystemLinks from "@ignite/utils/systemLinksUtil"
import Box from "@mui/material/Box"
import FormHelperText from "@mui/material/FormHelperText"
import Button from "components/Button"
import FormikTextField from "components/Forms/FormikTextField"
import Text from "components/Text"
import { Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ForgotPasswordForm from "shell/Header/ForgotPasswordForm"
import { makeStyles } from "tss-react/mui"
import * as Yup from "yup"

type FormValues = {
  username: string
  password: string
}

const useStyles = makeStyles()(({ spacing }) => ({
  info: {
    fontSize: 14,
  },
  input: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "black",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
    textUnderlineOffset: spacing(0.3),
    wordBreak: "break-all",
    textAlign: "right",
    marginTop: spacing(1),
  },
}))

const MobileMenuAuthForm: React.FC = () => {
  const api = useApiContext()
  const {
    state: { user },
    useAsyncAction,
  } = useAuthContext()

  const { useAsyncAction: useUserAsyncAction } = useUserContext()

  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false)
  const [errorMessageState, setErrorMessageState] = useState<string | null>()

  const initialValues: FormValues = {
    username: "",
    password: "",
  }

  const links = useSystemLinks()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && user.data && user.data.id) {
      const url = links["businessOverview"]
      navigate(url)
      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, links])

  const getMe = useUserAsyncAction("user", async () => {
    try {
      const userResponse = await api.users.getMe()
      return userResponse
    } catch (err) {
      console.error(err)
      return null
    }
  })

  const getUser = useAsyncAction("user", async (userName: string) => {
    try {
      const userResponse = await api.users.getUser(userName)
      await getMe()
      return userResponse
    } catch (err) {
      console.error(err)
      return null
    }
  })

  const handleSubmit = useAsyncAction(
    "tokenResponse",
    async (values: FormValues, actions: any) => {
      try {
        const tokenResponse = await api.users.login(
          values.username,
          values.password
        )
        await getUser(values.username)
        actions.setSubmitting(false)
        setErrorMessageState(null)
        return tokenResponse
      } catch (err) {
        setErrorMessageState(t("mobilemenu.form_text_generic_error"))
        return null
      }
    }
  )

  const validationSchema = useValidationSchema(() => {
    return Yup.object().shape({
      username: Yup.string().label("Username").required(),
      password: Yup.string().label("Password").required(),
    })
  })

  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <Box p={4}>
      {forgotPasswordOpen ? (
        <ForgotPasswordForm onSetForgotPasswordOpen={setForgotPasswordOpen} />
      ) : (
        <>
          <Text paragraph className={classes.info}>
            {t("mobilemenu.login_description")}
          </Text>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <Box mt={6}>
                  <FormikTextField
                    className={classes.input}
                    label={t("mobilemenu.login_username")}
                    name="username"
                    id="username"
                  />
                </Box>

                <Box mt={6}>
                  <FormikTextField
                    className={classes.input}
                    label={t("mobilemenu.login_password")}
                    name="password"
                    id="password"
                    type="password"
                  />
                  <Text
                    component="p"
                    variant="secondary-100"
                    className={classes.link}
                    onClick={() => setForgotPasswordOpen(true)}
                  >
                    {t("login.forgot_password")}
                  </Text>
                </Box>

                {errorMessageState && (
                  <FormHelperText error sx={{ mt: 6, textAlign: "center" }}>
                    {errorMessageState}
                  </FormHelperText>
                )}

                <Box mt={8} display="flex" justifyContent="center">
                  <Button disabled={isSubmitting || !isValid} type="submit">
                    {t("mobilemenu.login_cta_label")}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Box>
  )
}

export default MobileMenuAuthForm
