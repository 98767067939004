import React from "react"

import Icon, { IconProps } from "./"

const CheckIcon: React.FC<IconProps> = ({ strokeWidth = 2, ...restProps }) => (
  <Icon strokeWidth={strokeWidth} {...restProps}>
    <path d="M20 6L9 17l-5-5" vectorEffect="non-scaling-stroke" />
  </Icon>
)

export default CheckIcon
