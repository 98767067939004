import { useMenuContext } from "@ignite/react/context/menuContext"
import MenuIcon from "@mui/icons-material/Menu"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import React from "react"

type MobileMenuButtonProps = Pick<IconButtonProps, "edge">

const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({
  edge = "start",
}) => {
  const {
    actions: { toggle },
  } = useMenuContext()

  return (
    <IconButton
      onClick={toggle}
      edge={edge}
      color="inherit"
      aria-label="menu"
      size="large"
    >
      <MenuIcon />
    </IconButton>
  )
}

export default MobileMenuButton
