import { CartItem, CartName, CartResponse } from "@ignite/api/cart"
import { useApiContext } from "@ignite/react/context/ApiContextContainer"
import { useCartContext } from "@ignite/react/context/cartContext"
import emitEvent from "@ignite/utils/eventEmitter"
import useSystemLinks from "@ignite/utils/systemLinksUtil"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Text, { TextProps } from "components/Text"
import React from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "tss-react/mui"

import CloseIcon from "../Icons/CloseIcon"
import CartCheckoutButton from "./CartCheckoutButton"
import CartLineItem from "./CartLineItem"

const useStyles = makeStyles()(({ spacing }) => ({
  heading: {
    flexGrow: 1,
  },
  closeCta: {
    cursor: "pointer",
  },
  divider: {
    margin: `${spacing(4)} 0`,
  },
}))

const DefaultCheckoutButtonComponent = CartCheckoutButton

const DefaultLineItemComponent = CartLineItem

type CartContentProps = CartResponse & {
  noCheckoutButton?: boolean
  showShippingTotal?: boolean
  showTaxTotal?: boolean
  disabled?: boolean
  placement: string
  headingProps?: TextProps
  shippingMessage?: string
  CheckoutButtonComponent?: typeof CartCheckoutButton
  LineItemComponent?: typeof CartLineItem
  onCheckout?: () => void
  onCloseClick?: () => void
  onCartUpdating?: () => void
  onCartUpdated?: () => void
}

const CartContent: React.FC<CartContentProps> = ({
  items,
  name,
  disabled = false,
  totalString,
  noCheckoutButton,
  placement,
  headingProps,
  LineItemComponent = DefaultLineItemComponent,
  CheckoutButtonComponent = DefaultCheckoutButtonComponent,
  onCloseClick,
  showShippingTotal,
  showTaxTotal,
  shippingTotalString,
  shippingMessage,
  taxTotalString,
  onCartUpdating,
  onCartUpdated,
  onCheckout,
}) => {
  const api = useApiContext()
  const {
    state: {
      carts: { data: carts, loading },
    },
    useAsyncAction,
  } = useCartContext()

  const links = useSystemLinks()

  const { classes } = useStyles()
  const { t } = useTranslation()
  const px = 6
  const py = 4

  const cartsByName = carts?.reduce<Record<CartName, CartResponse>>(
    (acc, cart) => {
      acc[cart.name] = cart
      return acc
    },
    {} as Record<CartName, CartResponse>
  )

  const updatedCarts = (cart: CartResponse) => {
    if (!cartsByName) return carts

    cartsByName[cart.name] = cart
    return Object.values(cartsByName)
  }

  const raiseRemovedFromCart = (lineItem: CartItem | any) => {
    if (lineItem) {
      emitEvent("removeFromCart", { lineItem, actionField: placement })
    }
  }

  const handleQuantityChange = useAsyncAction(
    "carts",
    async ({ code, quantity }) => {
      if (!api) return carts

      onCartUpdating && onCartUpdating()

      const newCart = await api.cart.setCartItemQuantity(
        name,
        code,
        quantity || 1
      )

      onCartUpdated && onCartUpdated()

      return updatedCarts(newCart)
    },
    {
      deps: [name],
    }
  )

  const handleRemoveCartItem = useAsyncAction(
    "carts",
    async ({ code }) => {
      if (!api) return carts

      if (cartsByName) {
        raiseRemovedFromCart(
          cartsByName[name]?.items.find((line) => line.code === code)
        )
      }

      onCartUpdating && onCartUpdating()

      const newCart = await api.cart.removeFromCart(name, code)

      onCartUpdated && onCartUpdated()

      return updatedCarts(newCart)
    },
    {
      deps: [name],
    }
  )

  return (
    <Box className="ig-cart" py={py}>
      <Box display="flex" px={px} pb={py}>
        <Text
          className={classes.heading}
          component="h2"
          variant="primary-450"
          bold
          {...headingProps}
        >
          {t("cart.heading")}
        </Text>
        {onCloseClick && (
          <CloseIcon className={classes.closeCta} onClick={onCloseClick} />
        )}
      </Box>
      <Divider />
      <Box px={px} my={py}>
        {items &&
          items.map((item) => (
            <LineItemComponent
              key={`${item.id}-${item.code}-${item.quantity}`}
              loading={loading || disabled}
              onQuantityChange={handleQuantityChange}
              onRemove={handleRemoveCartItem}
              {...item}
            >
              <Divider className={classes.divider} />
            </LineItemComponent>
          ))}
      </Box>

      {showShippingTotal && (
        <Box display="flex" flexWrap="wrap" alignItems="center" px={px} my={py}>
          <Box flexGrow="1">
            <Text variant="primary-100" bold>
              {t("cart.total_shipping")}
            </Text>
          </Box>
          <Text variant="primary-200" bold>
            {shippingTotalString}
          </Text>
          {shippingMessage && (
            <Box my={2} textAlign="right" width="100%">
              <Text variant="secondary-100">{shippingMessage}</Text>
            </Box>
          )}
        </Box>
      )}

      {showTaxTotal && (
        <Box display="flex" flexWrap="wrap" alignItems="center" px={px} my={py}>
          <Box flexGrow="1">
            <Text variant="primary-100" bold>
              {t("checkout.tax_total_string")}
            </Text>
          </Box>
          <Text variant="primary-200" bold>
            {taxTotalString}
          </Text>
        </Box>
      )}

      <Box display="flex" alignItems="center" px={px} my={py}>
        <Box flexGrow="1">
          <Text variant="primary-200" bold>
            {t("cart.total_price")}
          </Text>
        </Box>
        <Text variant="primary-400" bold>
          {totalString}
        </Text>
      </Box>
      {!noCheckoutButton && (
        <CheckoutButtonComponent
          px={px}
          my={py}
          link={links["checkout"]}
          disabled={loading || disabled}
          onCheckout={onCheckout}
          data-testid="cart-to-checkout"
          buttonText={t("cart.to_checkout_cta")}
        />
      )}
    </Box>
  )
}

export default CartContent
