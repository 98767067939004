import { MenuIdentifier } from "@ignite/api/menus"
import { useMenuContext } from "@ignite/react/context/menuContext"
import Box, { BoxProps } from "@mui/material/Box"
import React, { useMemo } from "react"

import MegaMenuComponent from "./MegaMenuComponent"
import MegaMenuContent from "./MegaMenuContent"
import MegaMenuLinks from "./MegaMenuLinks"
import MiniMenuComponent from "./MiniMenuComponent"

export type SharedProps = {
  MenuItemComponent?: React.FC
  MenuItemComponentProps?: any
  MegaMenuContentComponent?: typeof MegaMenuContent
  MegaMenuContentComponentProps?: typeof MegaMenuContent.defaultProps
  MegaMenuComponent?: typeof MegaMenuComponent
  MegaMenuComponentProps?: typeof MegaMenuComponent.defaultProps
  MiniMenuComponent?: typeof MiniMenuComponent
  MiniMenuComponentProps?: typeof MiniMenuComponent.defaultProps
}

type MegaMenuProps = BoxProps &
  SharedProps & {
    identifier?: MenuIdentifier
  }

const MegaMenu: React.FC<MegaMenuProps> = ({
  MenuItemComponent,
  MenuItemComponentProps,
  MegaMenuContentComponent,
  MegaMenuContentComponentProps,
  MegaMenuComponent,
  MegaMenuComponentProps,
  MiniMenuComponent,
  MiniMenuComponentProps,
  identifier = "main",
  ...props
}) => {
  const {
    state: {
      menus: { data: menus },
    },
  } = useMenuContext()

  const menu = useMemo(
    () => menus?.find((menu) => menu.id === identifier),
    [identifier, menus]
  )

  return (
    <Box component="nav" alignSelf="stretch" flexGrow={1} {...props}>
      {menu && menu.children && menu.children.length > 0 && (
        <MegaMenuLinks
          menu={menu}
          MenuItemComponent={MenuItemComponent}
          MenuItemComponentProps={MenuItemComponentProps}
          MegaMenuContentComponent={MegaMenuContentComponent}
          MegaMenuContentComponentProps={MegaMenuContentComponentProps}
          MegaMenuComponent={MegaMenuComponent}
          MegaMenuComponentProps={MegaMenuComponentProps}
          MiniMenuComponent={MiniMenuComponent}
          MiniMenuComponentProps={MiniMenuComponentProps}
        />
      )}
    </Box>
  )
}

export default MegaMenu
