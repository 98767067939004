import { CartItem } from "@ignite/api/cart"
import Box from "@mui/material/Box"
import MuiSnackbarContent, {
  SnackbarContentProps as MuiSnackbarContentProps,
} from "@mui/material/SnackbarContent"
import { Theme } from "@mui/material/styles"
import React from "react"
import { makeStyles } from "tss-react/mui"

import SnackbarButton from "./SnackbarButton"
import SnackbarHeader from "./SnackbarHeader"
import SnackbarProduct from "./SnackbarProduct"

type SnackbarContentProps = MuiSnackbarContentProps & {
  cartItem?: CartItem
  cta: () => void
  onClose: () => void
  headerTitle?: string
  buttonText?: string
  /**
   * Component can be replaced with a component of your choice.
   */
  SnackbarHeaderComponent?: typeof SnackbarHeader
  SnackbarHeaderComponentProps?: typeof SnackbarHeader.defaultProps
  /**
   * Component can be replaced with a component of your choice.
   */
  SnackbarBodyComponent?: React.FC
  SnackbarBodyComponentProps?: any

  /**
   * Component can be replaced with a component of your choice.
   */
  SnackbarProductComponent?: typeof SnackbarProduct
  SnackbarProductComponentProps?: typeof SnackbarProduct.defaultProps
  /**
   * Component can be replaced with a component of your choice.
   */
  SnackbarButtonComponent?: typeof SnackbarButton
  SnackbarButtonComponentProps?: typeof SnackbarButton.defaultProps
}

const SnackbarContent: React.FC<SnackbarContentProps> = ({
  cartItem,
  cta,
  onClose,
  headerTitle,
  buttonText,
  SnackbarHeaderComponent = SnackbarHeader,
  SnackbarHeaderComponentProps,
  SnackbarProductComponent = SnackbarProduct,
  SnackbarProductComponentProps,
  SnackbarBodyComponent,
  SnackbarBodyComponentProps,
  SnackbarButtonComponent = SnackbarButton,
  SnackbarButtonComponentProps,
  ...snackbarContentProps
}: SnackbarContentProps) => {
  const { classes } = useStyles()

  return (
    <MuiSnackbarContent
      classes={{ root: classes.root, message: classes.message }}
      message={
        <Box display="flex" flexDirection="column">
          <SnackbarHeaderComponent
            onClose={onClose}
            title={headerTitle}
            className={classes.header}
            {...SnackbarHeaderComponentProps}
          />

          {cartItem && (
            <SnackbarProductComponent
              cartItem={cartItem}
              {...SnackbarProductComponentProps}
            />
          )}

          {SnackbarBodyComponent && (
            <SnackbarBodyComponent {...SnackbarBodyComponentProps} />
          )}

          {buttonText && (
            <SnackbarButtonComponent
              cta={cta}
              buttonText={buttonText}
              className={classes.goToCartButton}
              {...SnackbarButtonComponentProps}
            />
          )}
        </Box>
      }
      {...snackbarContentProps}
    ></MuiSnackbarContent>
  )
}

const useStyles = makeStyles()(
  ({ palette, typography, breakpoints, spacing, shape }: Theme) => ({
    root: {
      backgroundColor: palette.common.white,
      color: palette.text.primary,
      borderRadius: shape.borderRadius,
      padding: spacing(0, 3, 4, 7),
      width: 390,
    },
    message: {
      width: "100%",
    },
    header: {
      marginBottom: spacing(2),
      color: palette.text.primary,
    },
    goToCartButton: {
      marginTop: spacing(6),
      marginRight: spacing(4),
      ...typography["primary-300"],
      fontWeight: typography.fontWeightBold,
      padding: "16px 36px",
      textTransform: "none",
      [breakpoints.down("md")]: {
        lineHeight: 1,
      },
      backgroundColor: palette.branding?.yellow["main"],
      color: palette.common.black,
      borderRadius: 48,
      "&:hover": {
        backgroundColor: `${palette.branding?.yellow["dark"]} !important`,
      },
    },
  })
)

export default SnackbarContent
