import useBreakpoint from "@ignite/react/hooks/useBreakpoint"
import Box, { BoxProps } from "@mui/material/Box"
import Button from "components/Button"
import Text from "components/Text"
import React from "react"
import { makeStyles } from "tss-react/mui"

type TopBarProps = {
  text?: string
  ctaLabel?: string
  cta?: () => void
  containerProps?: BoxProps
  loading?: boolean
  children?: React.ReactNode
}

const useStyles = makeStyles()(({ spacing, breakpoints, palette }) => ({
  root: {
    backgroundColor: palette.branding?.green[600],
    padding: spacing(4),
    [breakpoints.up("md")]: {
      minHeight: 72,
    },
  },
  ctaButton: {
    padding: `${spacing(2)} ${spacing(8)}`,
    [breakpoints.up("md")]: {
      padding: `${spacing(1)} ${spacing(8)}`,
    },
  },
}))

const TopBar: React.FC<TopBarProps> = ({
  text,
  ctaLabel,
  cta,
  containerProps,
  children,
  loading,
}) => {
  const { classes } = useStyles()
  const isSmall = useBreakpoint("md")

  return (
    <Box
      className={classes.root}
      justifyContent={isSmall ? "space-between" : "center"}
      alignItems="center"
      display="flex"
      {...containerProps}
    >
      {text && (
        <Text variant={isSmall ? "primary-200" : "primary-400"} bold={!isSmall}>
          {text}
        </Text>
      )}

      {ctaLabel && (
        <Box ml={isSmall ? 1 : 6}>
          <Button
            disabled={loading}
            size="small"
            className={classes.ctaButton}
            variant="outlined"
            onClick={cta}
          >
            {ctaLabel}
          </Button>
        </Box>
      )}

      {children}
    </Box>
  )
}

export default TopBar
