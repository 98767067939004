import React from "react"

import Icon, { IconProps } from "./"

const MinimizeIcon: React.FC<IconProps> = ({
  fill = "currentColor",
  ...restProps
}) => {
  return (
    <Icon {...restProps} fill={fill} stroke="none">
      <path vectorEffect="non-scaling-stroke" d="M19 13H5v-2h14v2z" />
    </Icon>
  )
}

export default MinimizeIcon
