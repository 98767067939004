import React from "react"

import Skeleton, { SkeletonProps } from "./Skeleton"

type FormSkeletonProps = SkeletonProps & {
  rows?: number
  height?: number
  margin?: number
  randomWidth?: boolean
}

const WIDTHS = [
  33, 80, 50, 24, 60, 20, 44, 10, 18, 70, 63, 27, 90, 43, 58, 20, 40, 32,
]

const FormSkeleton: React.FunctionComponent<FormSkeletonProps> = ({
  className,
  rows = 1,
  margin = 16,
  height = 32,
  randomWidth = false,
  ...props
}) => (
  <div
    style={{
      height: (height + margin) * rows - margin,
    }}
    className={className}
  >
    <Skeleton {...props}>
      {Array.from("x".repeat(rows)).map((v, i) => (
        <rect
          key={i}
          x={0}
          y={i * (height + margin)}
          rx={5}
          ry={5}
          width={`${(randomWidth && WIDTHS[i % WIDTHS.length]) || 100}%`}
          height={height}
        />
      ))}
    </Skeleton>
  </div>
)

export default FormSkeleton
