import RelativeLink from "components/RelativeLink"
import React from "react"

type SearchTextLinkProps = {
  highlight?: string
  href: string
  children: string
  onClick?: () => void
}

const SearchTextLink: React.FC<SearchTextLinkProps> = ({
  children,
  highlight,
  href,
  onClick,
}) => {
  const segments = highlight
    ? children.split(new RegExp(`(${highlight})`, "gi")).filter(Boolean)
    : [children]

  return (
    <RelativeLink href={href} onClick={onClick}>
      {segments.map((seg, idx) =>
        seg.toLowerCase() === highlight?.toLowerCase() ? (
          <strong key={idx}>{seg}</strong>
        ) : (
          <span key={idx}>{seg}</span>
        )
      )}
    </RelativeLink>
  )
}

export default SearchTextLink
