import CloseIcon from "@mui/icons-material/Close"
import MuiDialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import Text from "components/Text"
import React from "react"
import { makeStyles } from "tss-react/mui"

type Props = {
  onClose?: VoidFunction
  showCloseButton?: boolean
  className?: string
  children?: React.ReactNode
}

const useStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    padding: 0,
  },
  closeButton: {
    position: "absolute",
    right: spacing(4),
    top: spacing(4),
    width: 14,
    height: 14,
    color: palette.text.primary,
  },
}))

const DialogTitle: React.FC<Props> = ({
  onClose,
  showCloseButton,
  children,
  ...restProps
}) => {
  const { classes } = useStyles()

  return (
    <>
      <MuiDialogTitle className={classes.root} component="span" {...restProps}>
        {showCloseButton && onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      {children && (
        <Text component="p" variant="primary-450" bold paragraph>
          {children}
        </Text>
      )}
    </>
  )
}

export default DialogTitle
