import { useUserContext } from "@ignite/react/context/userContext"
import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import SecondaryArrowIcon from "components/Icons/SecondaryArrowIcon"
import UserIcon from "components/Icons/UserIcon"
import React from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "tss-react/mui"

type MobileMenuToolbarProps = {
  isRoot?: boolean
  isPanel?: boolean
  onBackClicked?: () => void
  onClose?: () => void
  toolbarContent?: React.ReactNode
}

const useStyles = makeStyles()(({ palette, spacing, typography }) => ({
  root: {
    color: palette.getContrastText(palette.header.secondary),
    backgroundColor: palette.header.secondary,
  },
  b2bToolbar: {
    justifyContent: "space-between",
  },
  b2bContent: {
    fontSize: typography["primary-100"].fontSize,
    paddingLeft: spacing(2),
    lineHeight: typography["primary-200"].lineHeight,
    "& .MuiSvgIcon-root": {
      width: spacing(3),
      height: spacing(3),
    },
  },
  b2bIcon: {
    marginRight: spacing(3),
  },
}))

const MobileMenuToolbar: React.FC<MobileMenuToolbarProps> = ({
  isRoot = false,
  isPanel = false,
  onBackClicked,
  onClose,
  toolbarContent = <Box flexGrow={1} />,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const {
    state: {
      user: { data: userMe },
    },
  } = useUserContext()

  return (
    <>
      {userMe ? (
        <Toolbar className={classes.b2bToolbar}>
          {(isPanel || !isRoot) && (
            <Box onClick={onBackClicked} display="flex" alignItems="center">
              <IconButton aria-label="back" color="inherit" size="large">
                <SecondaryArrowIcon direction="left" />
              </IconButton>
              {t("general.go_back")}
            </Box>
          )}
          {isRoot && (
            <Box
              display="flex"
              alignItems="center"
              className={classes.b2bContent}
            >
              <UserIcon className={classes.b2bIcon} />
              {userMe.firstName}
            </Box>
          )}

          <IconButton
            aria-label="close"
            onClick={onClose}
            color="inherit"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      ) : (
        <Toolbar className={cx(classes.root, "toolbar")}>
          {(isPanel || !isRoot) && (
            <Box onClick={onBackClicked} display="flex" alignItems="center">
              <IconButton aria-label="back" color="inherit" size="large">
                <SecondaryArrowIcon direction="left" />
              </IconButton>
              {t("general.go_back")}
            </Box>
          )}

          {toolbarContent}

          <IconButton
            aria-label="close"
            onClick={onClose}
            color="inherit"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      )}
    </>
  )
}

export default MobileMenuToolbar
