import Box from "@mui/material/Box"
import { SelectChangeEvent } from "@mui/material/Select"
import ChevronIcon from "components/Icons/ChevronIcon"
import Select, { SelectItemType } from "components/Select"
import React from "react"

type MobileMenuSelectProps = {
  items: SelectItemType[]
  onChange: (e: SelectChangeEvent<unknown>) => void
  label: string
  align?: "left" | "right"
  IconComponent?: typeof ChevronIcon
}

const DefaultIconComponent: React.FC = () => (
  <ChevronIcon direction="up" size="sm" />
)

const MobileMenuSelect: React.FC<MobileMenuSelectProps> = ({
  items,
  onChange,
  label,
  align = "left",
  IconComponent = DefaultIconComponent,
}) => {
  if (!items || !onChange || !label) {
    return null
  }

  return (
    <Box display="flex">
      <Box
        sx={({ palette, spacing, typography }) => ({
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: `${spacing(2)} ${spacing(4)}`,
          border: `1px solid ${palette.ui.main}`,
          borderRightWidth: align === "left" ? 0 : 1,
          borderRadius: align === "left" ? "48px 0 0 48px" : "0 48px 48px 0",
          color: palette.primary.main,
          minHeight: spacing(14),
          paddingTop: 0,
          paddingBottom: 0,
          "& .MuiFormControl-root": {
            minWidth: "100%",
          },
          "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink), .MuiSelect-select":
            {
              fontWeight: typography.fontWeightBold,
            },
        })}
      >
        <Select
          MenuProps={{
            anchorOrigin: { vertical: "top", horizontal: "center" },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            marginThreshold: 75,
          }}
          InputLabelProps={{ shrink: false }}
          IconComponent={IconComponent}
          items={items}
          onChange={onChange}
          label={label}
        />
      </Box>
    </Box>
  )
}

export default MobileMenuSelect
