import { ApiLink } from "."
import { BaseContent, ImageType } from "./contents"
import request from "./request"

export type SiteSettingsResponse = {
  properties: {
    environment: string
    currency: string
    market: string
    language: string
    defaultMeasureUnit: string
    cookieDisclaimer: string
    footerContent: BaseContent[]
    topBanners: ApiLink[]
    systemLinks: ApiLink[]
    copyrightNotice: string
    commerceDisabled: boolean
    enableDataLayer: boolean
    footerIcons: ImageType[]
    socialIcons: {
      href: string
      text: string
    }[]
    productPageUniqueSellingPoints: string[]
  }
}

type SiteSettingsApi = {
  getSiteSettings: () => Promise<SiteSettingsResponse>
}

const siteSettingsApi: SiteSettingsApi = {
  getSiteSettings: async () =>
    window.SITE_DATA || (await request.get<SiteSettingsResponse>(`/site`)),
}

export default siteSettingsApi
