import React from "react"

import Icon, { IconProps } from "./"

const FacebookIcon: React.FC<IconProps> = ({
  stroke = "none",
  ...restProps
}) => {
  return (
    <Icon {...restProps} stroke={stroke}>
      <path
        d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11z"
        stroke="currentColor"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12.491 17.25V12h1.553l.206-1.81h-1.759l.003-.905c0-.472.048-.725.774-.725h.971V6.75h-1.553c-1.866 0-2.523.878-2.523 2.355v1.086H9V12h1.163v5.25h2.328z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export default FacebookIcon
