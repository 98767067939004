import Box, { BoxProps } from "@mui/material/Box"
import RelativeLink from "components/RelativeLink"
import React from "react"

import Logo from "./Logo"

type HeaderLogoProps = BoxProps & {
  link?: string | null
  drawer?: boolean
}

const sharedProps: BoxProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({
  link = "/",
  drawer,
  ...props
}) => {
  if (link) {
    return (
      <RelativeLink component={Box} href={link} {...sharedProps} {...props}>
        <Logo drawer={drawer} />
      </RelativeLink>
    )
  }

  return (
    <Box {...sharedProps} {...props}>
      <Logo drawer={drawer} />
    </Box>
  )
}

export default HeaderLogo
