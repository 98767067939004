import useBreakpoint from "@ignite/react/hooks/useBreakpoint"
import Box, { BoxProps } from "@mui/material/Box"
import React from "react"

const DEFAULT_MAX_WIDTH = parseInt(process.env.REACT_APP_MAX_WIDTH || "1728")

export type ContentContainerProps = Omit<BoxProps, "padding"> & {
  containerProps?: BoxProps
  padding?: number
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  margin = 18,
  padding,
  containerProps,
  ...props
}) => {
  const isSmall = useBreakpoint("md")

  const px = (isSmall && 4) || margin || 18
  const py =
    typeof padding === "number" && padding >= 0
      ? padding
      : (isSmall && 10) || margin || 18

  return (
    <Box display="flex" {...props}>
      <Box
        display="flex"
        width="100%"
        height="100%"
        py={py}
        px={px}
        mx="auto"
        maxWidth={DEFAULT_MAX_WIDTH}
        {...containerProps}
      >
        {children}
      </Box>
    </Box>
  )
}

export default ContentContainer
