import { MenuResponse } from "@ignite/api/menus"
import React, { useMemo } from "react"

import MegaMenuDefaultComponent from "./MegaMenuComponent"
import MiniMenuDefaultComponent from "./MiniMenuComponent"

type MegaMenuContentProps = {
  item: MenuResponse

  MegaMenuComponent?: typeof MegaMenuDefaultComponent
  MiniMenuComponent?: typeof MiniMenuDefaultComponent
  MegaMenuComponentProps?: typeof MegaMenuDefaultComponent.defaultProps
  MiniMenuComponentProps?: typeof MiniMenuDefaultComponent.defaultProps

  onClose: () => void
}

const getDepth = (item: MenuResponse, depth = 0): number => {
  if (item && !!item.children && item.children.length > 0) {
    const childDepths = []
    depth++

    for (let i = 0; i < item.children.length; i++) {
      childDepths.push(getDepth(item.children[i]))
    }
    return depth + Math.max(...childDepths)
  }

  return depth
}

const MegaMenuContent: React.FC<MegaMenuContentProps> = ({
  item,
  onClose,

  MegaMenuComponent = MegaMenuDefaultComponent,
  MegaMenuComponentProps = MegaMenuDefaultComponent.defaultProps,
  MiniMenuComponent = MiniMenuDefaultComponent,
  MiniMenuComponentProps = MiniMenuDefaultComponent.defaultProps,
}) => {
  const depth = useMemo(() => getDepth(item), [item])

  // Display as megamenu depending on depth of nested children
  const displayAsMegaMenu = depth < 2 ? false : true
  return (
    (displayAsMegaMenu && MegaMenuComponent && (
      <MegaMenuComponent
        item={item}
        onClose={onClose}
        {...MegaMenuComponentProps}
      />
    )) ||
    (MiniMenuComponent && depth !== 0 && (
      <MiniMenuComponent
        item={item}
        onClose={onClose}
        {...MiniMenuComponentProps}
      />
    )) ||
    null
  )
}

export default MegaMenuContent
