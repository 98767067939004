/* eslint-disable react/jsx-no-undef */
import { CartName } from "@ignite/api/cart"
import { useCartContext } from "@ignite/react/context/cartContext"
import Drawer, { DrawerProps } from "@mui/material/Drawer"
import CartContent from "components/Cart/CartContent"
import React from "react"

import DrawerContainer from "../DrawerContainer"

type CartProps = Pick<DrawerProps, "anchor"> & {
  cartName: CartName
}

const Cart: React.FC<CartProps> = ({ cartName = "basket", anchor }) => {
  const {
    state: {
      open,
      carts: { data: carts },
    },
    actions: { toggle },
  } = useCartContext()

  const cart =
    carts && carts.length > 0 ? carts.find((c) => c.name === cartName) : null

  const isOpen = open[cartName]

  const toggleCart = () => {
    toggle(cartName)
  }

  const anchorValue = anchor || "right"

  return (
    <>
      {cart && (
        <Drawer anchor={anchorValue} open={isOpen} onClose={toggleCart}>
          <DrawerContainer anchor={anchorValue}>
            <CartContent
              placement="basket"
              onCheckout={toggleCart}
              onCloseClick={toggleCart}
              {...cart}
            />
          </DrawerContainer>
        </Drawer>
      )}
    </>
  )
}

export default Cart
