import { BlockRenderer } from "@ignite/react/components/Content/ContentRenderer"
import { useMenuContext } from "@ignite/react/context/menuContext"
import { useUserContext } from "@ignite/react/context/userContext"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import LanguagePicker from "components/LanguagePicker"
import MarketPicker from "components/MarketPicker"
import React from "react"
import { makeStyles } from "tss-react/mui"

import FormSkeleton from "../FormSkeleton"
import RelativeLink from "../RelativeLink"
import { MenuData } from "."
import B2bMobileMenuItems from "./B2bMobileMenuItems"
import MobileMenuItem from "./MobileMenuItem"
import MobileMenuToolbar from "./MobileMenuToolbar"

export type MobileMenuContentProps = {
  menu?: MenuData
  onMenuItemClicked?: (item: MenuData) => void
  onBackClicked?: (item: MenuData) => void
  className?: string
  toolbarContent?: React.ReactNode
  children?: React.ReactNode
}

const useStyles = makeStyles()(({ palette, spacing }) => ({
  nav: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    paddingBottom: spacing(16),
  },
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
  },
  menuSelectBoxes: {
    backgroundColor: palette.header.page,
    width: `calc(100% - ${spacing(8)})`,
    margin: `0 ${spacing(4)}`,
    padding: `0 0 ${spacing(4)}`,
    bottom: 0,
  },
}))

const MobileMenuContent: React.FC<MobileMenuContentProps> = ({
  menu,
  onMenuItemClicked,
  onBackClicked,
  className,
  toolbarContent = <Box flexGrow={1} />,
  children,
}) => {
  const { classes, cx } = useStyles()

  const {
    actions: { toggle },
  } = useMenuContext()

  const handleBackClicked = () => {
    if (onBackClicked && menu && menu.parent) {
      onBackClicked(menu.parent)
    }
  }

  const handleParentClicked = () => toggle()
  const menuIsNotPanel = Object.hasOwnProperty.call(menu, "children")

  const {
    state: {
      user: { data: userMe },
    },
  } = useUserContext()

  const isRoot = menu && !menu.parent

  return (
    <div className={cx(classes.root, className)}>
      <MobileMenuToolbar
        isRoot={menu && !menu.parent}
        toolbarContent={toolbarContent}
        onBackClicked={handleBackClicked}
        onClose={toggle}
      />
      {userMe && isRoot && <B2bMobileMenuItems toggle={toggle} />}
      <List
        component="nav"
        className={classes.nav}
        style={{
          overflowY: isRoot ? "visible" : "scroll",
          paddingTop: userMe && isRoot ? "48px" : isRoot ? "48px" : "0px",
          paddingBottom: userMe && isRoot ? "0px" : "56px",
        }}
        aria-label="menu"
      >
        {menu && menu.title && (
          <Box p={4}>
            <RelativeLink
              variant="primary-400"
              bold
              onClick={handleParentClicked}
              href={menu.url}
            >
              {menu.title}
            </RelativeLink>
          </Box>
        )}

        {(!menu && (
          <Box p={4}>
            <FormSkeleton rows={10} margin={32} />
          </Box>
        )) ||
          (menu && (
            <Box>
              {menuIsNotPanel &&
                menu.children.map((item) => (
                  <MobileMenuItem
                    key={item.id}
                    onClick={onMenuItemClicked}
                    item={item}
                  >
                    {item.title}
                  </MobileMenuItem>
                ))}

              {menu.properties &&
                menu.properties["content"] &&
                menu.properties["content"].length > 0 && (
                  <Box py={6} px={3}>
                    <BlockRenderer content={menu.properties["content"]} />
                  </Box>
                )}
            </Box>
          ))}

        {children}
      </List>
      {menu && !menu.parent && (
        <Box className={classes.menuSelectBoxes}>
          <Grid container>
            <Grid item xs={6}>
              <MarketPicker mobile />
            </Grid>

            <Grid item xs={6}>
              <LanguagePicker mobile />
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  )
}

export default MobileMenuContent
