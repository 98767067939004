import { MenuResponse } from "@ignite/api/menus"
import Box from "@mui/material/Box"
import RelativeLink from "components/RelativeLink"
import React, { useCallback, useMemo, useRef } from "react"
import { makeStyles } from "tss-react/mui"

import { SharedProps } from "."
import MegaMenuContent from "./MegaMenuContent"

const useStyles = makeStyles<void, "mainLinkText">()(
  ({ palette, spacing }, _params, classes) => ({
    mainLink: {
      padding: `0 ${spacing(4)}`,
      [`&.hover .${classes.mainLinkText}`]: {
        transition: "all 200ms cubic-bezier(0.4, 0.0, 0.2, 1)",
        borderBottom: `4px solid ${palette.getContrastText(
          palette.header.primary
        )}`,
      },
      "&.hover .megaMenuContent": {
        opacity: 1,
        visibility: "visible",
      },
    },
    list: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
      justifyContent: "center",
      height: "100%",
    },
    listItem: {
      margin: 0,
      padding: 0,
      height: "100%",
      display: "inline-flex",
    },
    mainLinkText: {
      marginTop: "4px",
      display: "flex",
      pointerEvents: "none",
      alignItems: "center",
      height: "calc(100% - 4px)",
      borderBottom: `4px solid transparent`,
    },
  })
)

type MenuLinksProps = SharedProps & {
  menu: MenuResponse
}

const DefaultMenuItem = ({ children }: { children?: React.ReactNode }) => {
  const { classes } = useStyles()
  return <span className={classes.mainLinkText}>{children}</span>
}

const setHover = (target: HTMLElement, children: number) => {
  target.classList.add("hover")
  if (target.firstChild && target.firstChild.nodeType === Node.ELEMENT_NODE) {
    const firstChild = target.firstChild as HTMLElement
    firstChild.classList.add("hover")

    if (children > 0) {
      document.body.classList.add("menuOpen")
    }
  }
}

const clearHover = (target: HTMLElement) => {
  target.classList.remove("hover")
  if (target.firstChild && target.firstChild.nodeType === Node.ELEMENT_NODE) {
    const firstChild = target.firstChild as Element
    firstChild.classList.remove("hover")

    document.body.classList.remove("menuOpen")
  }
}

const MegaMenuLinks: React.FC<MenuLinksProps> = ({
  menu,
  MenuItemComponent = DefaultMenuItem,
  MenuItemComponentProps,
  MegaMenuContentComponent = MegaMenuContent,
  MegaMenuContentComponentProps,
  MegaMenuComponent,
  MegaMenuComponentProps,
  MiniMenuComponent,
  MiniMenuComponentProps,
}) => {
  const { classes, cx } = useStyles()

  const menuRef = useRef<HTMLUListElement>(null)

  const refs = useMemo(
    () =>
      Array.from({ length: menu.children.length }, () =>
        React.createRef<HTMLElement>()
      ),
    [menu.children.length]
  )

  const handleMouseOver = useCallback(
    (target: HTMLElement, children: number) => {
      setHover(target, children)
    },
    []
  )

  const handleTextMouseLeave = useCallback((e: React.MouseEvent) => {
    const target = e.target as HTMLElement
    clearHover(target)
    handleClose()
  }, [])

  const handleClose = () => {
    if (menuRef.current) {
      Array.from(menuRef.current.getElementsByClassName("hover")).forEach(
        (element: Element) => {
          element.classList.remove("hover")
          document.body.classList.remove("menuOpen")
        }
      )
    }
  }

  return (
    <ul ref={menuRef} className={classes.list}>
      {menu.children.map((item, i) => (
        <li className={classes.listItem} key={item.id + item.title}>
          <Box
            className={cx(`link-${i}`, classes.mainLink)}
            {...({ ref: refs[i] } as any)}
            onMouseEnter={() =>
              handleMouseOver(
                refs[i].current!,
                item.children && item.children.length
              )
            }
            onMouseLeave={handleTextMouseLeave}
          >
            <RelativeLink
              onClick={handleClose}
              href={item.url}
              variant="primary-400"
              bold
            >
              <MenuItemComponent {...MenuItemComponentProps}>
                {item.title}
              </MenuItemComponent>
            </RelativeLink>
            {item.children && item.children.length !== 0 && (
              <MegaMenuContentComponent
                item={item}
                onClose={handleClose}
                MegaMenuComponent={MegaMenuComponent}
                MegaMenuComponentProps={MegaMenuComponentProps}
                MiniMenuComponent={MiniMenuComponent}
                MiniMenuComponentProps={MiniMenuComponentProps}
                {...MegaMenuContentComponentProps}
              />
            )}
          </Box>
        </li>
      ))}
    </ul>
  )
}

export default MegaMenuLinks
