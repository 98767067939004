import Box, { BoxProps } from "@mui/material/Box"
import React from "react"

export type HeaderContentContainerProps = BoxProps

const HeaderContentContainer: React.FC<HeaderContentContainerProps> = ({
  children,
  ...props
}) => (
  <Box px={6} alignItems="center" {...props}>
    {children}
  </Box>
)

export default HeaderContentContainer
