import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material/TextField"
import React, { useMemo } from "react"

export type TextFieldProps = {
  id?: string
} & Omit<MuiTextFieldProps, "hiddenLabel">

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      id,
      name = "input",
      fullWidth = true,
      variant = "standard",
      disabled = false,
      SelectProps,
      select,
      children,
      ...restProps
    },
    ref
  ) => {
    const idValue = useMemo(
      () => id || Math.random().toString(16).substring(2),
      [id]
    )

    if (select && !SelectProps) {
      // This needs to be set inside component so that getContentAnchorEl works
      SelectProps = {
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        },
      }
    }

    return (
      <MuiTextField
        variant={variant}
        id={idValue}
        name={name}
        disabled={disabled}
        fullWidth={fullWidth}
        select={select}
        ref={ref}
        {...restProps}
        slotProps={{
          select: SelectProps,

          inputLabel: {
            shrink: true,
            disabled,
            htmlFor: idValue,
            error: false,
          },
        }}
      >
        {children}
      </MuiTextField>
    )
  }
)

export default TextField
