import { useApiContext } from "@ignite/react/context/ApiContextContainer"
import { useUserContext } from "@ignite/react/context/userContext"
import Collapse from "@mui/material/Collapse"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import TopBarContent from "./ImpersonateUsersTopBarContent"

const ImpersonateUsersTopBar: React.FC = () => {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState<boolean>()

  const {
    state: {
      user: { data: user },
    },
  } = useUserContext()

  const api = useApiContext()
  const { t } = useTranslation()

  const endImpersonation = async (originalUserId: string) => {
    setLoading(true)

    try {
      await api.users.impersonate(originalUserId)
      window.location.reload()
    } catch (err) {
      /** no error handling */
    }

    return null
  }

  useEffect(() => {
    setActive(!!user?.impersonatingUser)
  }, [user])

  return (
    <Collapse in={active}>
      <TopBarContent
        text={`${t("topbar.impersonate_act_as")} ${user?.fullName || ""}`}
        ctaLabel={t("topbar.impersonate_cancel")}
        cta={() => {
          if (user?.impersonatingUser?.id) {
            endImpersonation(user?.impersonatingUser?.id)
          }
        }}
        loading={loading}
      />
    </Collapse>
  )
}

export default ImpersonateUsersTopBar
