import Box, { BoxProps } from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Text from "components/Text"
import React from "react"

import CloseIcon from "../Icons/CloseIcon"

type SnackbarHeaderProps = BoxProps & {
  title?: string
  onClose: () => void
}

const SnackbarHeader: React.FC<SnackbarHeaderProps> = ({
  title = "",
  onClose,
  ...boxProps
}) => {
  return (
    <Box
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      display="flex"
      {...boxProps}
    >
      <Text color="inherit" variant="primary-400" bold>
        {title}
      </Text>
      <IconButton aria-label="close-snackbar" onClick={onClose} size="large">
        <CloseIcon htmlColor="#000" />
      </IconButton>
    </Box>
  )
}

export default SnackbarHeader
