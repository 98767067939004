import {
  createTheme,
  PaletteOptions,
  ThemeOptions,
  TypographyVariantsOptions,
} from "@mui/material/styles"
import colors from "theme/colors"

import { createTypography } from "./typography"

const initialThemeOptions = {
  fontWeightBold: 600,
  fontWeightBoldSecondary: 700,
}

const { breakpoints } = createTheme()
const customTypographyOptions = createTypography(breakpoints)

const spacing = (factor: number) => `${0.25 * factor}rem`

const palette: PaletteOptions = {
  primary: {
    light: colors.branding.purple.light,
    main: colors.branding.purple.main,
    dark: colors.branding.purple.dark,
  },
  secondary: {
    light: colors.branding.green.main,
    main: colors.branding.green.main,
    dark: colors.branding.green.dark,
  },
  grey: {
    ...colors.grey,
  },
  branding: {
    ...colors.branding,
  },
  error: {
    main: colors.system.red,
  },
  warning: {
    main: colors.system.yellow,
  },
  success: {
    main: colors.system.green,
  },
  info: {
    main: colors.system.blue,
  },
  text: {
    primary: colors.black.main,
    secondary: colors.text.secondary,
  },
  body: {
    bgcolor: colors.body.background,
  },
  footer: {
    primary: colors.branding.green.main,
    secondary: colors.white.main,
  },
  header: {
    megaMenu: colors.white.main,
    primary: colors.branding.green.main,
    secondary: colors.branding.green[550],
    marquee: colors.branding.green[300],
    page: colors.white.main,
  },
  ui: {
    light: colors.ui.light,
    main: colors.ui.main,
    dark: colors.ui.dark,
    contrastText: colors.white.main,
  },
  status: {
    inStock: colors.status.instock,
    outOfStock: colors.system.red,
  },
  product: {
    tag: {
      contrastText: colors.white.main,
      light: colors.branding.green.main,
      main: colors.branding.green.main,
      dark: colors.branding.green.dark,
    },
  },
  backdrop: { ...colors.backdrop },
}

const typography: ThemeOptions["typography"] = {
  fontFamily: ["azo-sans-web", "sans-serif"].join(","),
  fontWeightBold: 700,
  fontFamilySecondary: ["Source Serif Pro", "serif"].join(","),
  fontWeightBoldSecondary: 700,
  h1: customTypographyOptions["primary-800"],
  h2: { ...customTypographyOptions["primary-600"], fontWeight: 700 },
  h3: customTypographyOptions["primary-450"],
  h4: customTypographyOptions["primary-400"],
  button: {
    fontSize: "1rem",
  },
  ...customTypographyOptions,
} as TypographyVariantsOptions

const themeOptions: ThemeOptions = {
  palette,
  shape: {
    borderRadius: 0,
  },
  typography,
  spacing,
  // MUI4 default breakpoints, we're keeping them for a while
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overflowY: "scroll",
          overscrollBehavior: "none",
          backgroundColor: palette.body.bgcolor,
          ".nelson-cta-link": {
            fontWeight: 500,
            color: `${colors.branding.purple.main} !important`,
            textDecoration: "none !important",
            textUnderlinePosition: "initial !important",
            "&:active": {
              color: colors.branding.purple.main,
            },
            "&:after": {
              content: "''",
              position: "relative",
              display: "inline-block",
              width: "1em",
              height: "1em",
              top: "2px",
              right: "-0.5em",
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='${encodeURIComponent(
                colors.branding.purple.main
              )}' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke-width='2' d='M5 12h14m-7-7l7 7-7 7' vector-effect='non-scaling-stroke'/%3E%3C/svg%3E")`,
            },
          },
        },
        a: {
          color: "inherit",
          textDecoration: "none",
          cursor: "pointer",
        },
        body: {
          fontSize: typography["primary-300"].fontSize,
          backgroundColor: "transparent",
          "& .fade:after": {
            content: "''",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: palette.backdrop.main,
            transition: "opacity 500ms cubic-bezier(0.4, 0.0, 0.2, 1)",
            opacity: 0,
            zIndex: 2,
            pointerEvents: "none",
          },
          "&.menuOpen": {
            "& .fade:after": {
              opacity: 1,
            },
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backdrop.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: `${spacing(1.5)} ${spacing(4)} ${spacing(1.5)} 0 `,
          "& > span": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: 200,
            display: "block",
            [breakpoints.down("md")]: {
              maxWidth: 140,
            },
          },
        },
        textColorInherit: {
          textTransform: "none",
          fontSize: 20,
          fontWeight: 400,
          color: colors.branding.black[600],
          minWidth: "auto",
          opacity: 1,
          "&.Mui-selected > span": {
            fontWeight: 600,
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: "secondary",
        textColor: "inherit",
      },
      styleOverrides: {
        flexContainer: {
          borderBottom: `2px solid ${colors.grey[400]}`,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: "24px",
          fontWeight: initialThemeOptions.fontWeightBold,
          textAlign: "center",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: "24px",
          fontWeight: initialThemeOptions.fontWeightBold,
          padding: "16px 36px",
          textTransform: "none",
          [breakpoints.down("md")]: {
            lineHeight: 1,
          },
        },
        contained: {
          "&$disabled": {
            color: colors.white.main,
          },
        },
        containedPrimary: {
          backgroundColor: colors.branding.yellow.main,
          color: colors.black.main,
          borderRadius: 48,
          fontWeight: typography.fontWeightBold,
          "&:hover": {
            backgroundColor: `${colors.branding.yellow.dark} !important`,
          },
        },
        containedSecondary: {
          backgroundColor: colors.white.main,
          color: colors.branding.purple.main,
          borderRadius: 48,
          fontWeight: typography.fontWeightBold,
          "&:hover": {
            backgroundColor: `${colors.grey[100]} !important`,
          },
        },
        containedSizeSmall: {
          fontSize: 16,
          lineHeight: "24px",
          padding: "12px 24px",
        },
        containedSizeLarge: {
          fontSize: 16,
          lineHeight: "24px",
          padding: "19px 48px",
        },
        outlined: {
          padding: "16px 36px",
        },
        outlinedPrimary: {
          backgroundColor: colors.white.main,
          color: colors.branding.purple.main,
          border: `1px solid ${colors.branding.purple.main}`,
          borderRadius: 48,
          fontWeight: typography.fontWeightBold,
          "&:hover": {
            backgroundColor: `${colors.grey[100]} !important`,
          },
        },
        outlinedSizeSmall: {
          fontSize: 16,
          lineHeight: "24px",
          padding: "12px 24px",
        },
        outlinedSizeLarge: {
          fontSize: 16,
          lineHeight: "24px",
          padding: "19px 48px",
        },
        text: {
          padding: "6px 8px",
        },
        textPrimary: {
          color: colors.branding.purple.main,
          fontWeight: typography.fontWeightBold,
          "&:hover": {
            color: colors.branding.purple.main,
            backgroundColor: "transparent",
          },
          "&$disabled": {
            color: colors.branding.purple.main,
            opacity: 0.4,
            backgroundColor: "transparent",
          },
        },
        textSizeSmall: {
          fontSize: 16,
        },
        textSizeLarge: {
          fontSize: 16,
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        BackdropProps: {
          style: {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: 4,
          "&:focus": {
            borderRadius: 4,
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          color: colors.black.main,
          backgroundColor: colors.branding.yellow.main,
          fontWeight: 700,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          color: colors.branding.purple.main,
          backgroundColor: colors.branding.purple.light,
        },
        sizeSmall: {
          fontSize: 12,
          paddingTop: 2,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "always",
        color: "textPrimary",
      },
      styleOverrides: {
        root: {
          textUnderlinePosition: "under",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.black.main,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          color: colors.black.main,
          position: "static",
          transform: "translate(0, 0)",
          fontSize: 14,
          marginBottom: -4,
          "&$focused": {
            color: colors.black.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: palette.grey?.[600] || "initial",
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        input: {
          height: 48,
          padding: "16px 12px",
          boxSizing: "border-box",
          border: `1px solid #dededc`,
          borderRadius: 4,
        },
        underline: {
          "&:before": {
            borderBottomColor: colors.grey[650],
          },
          "&:after": {
            borderBottomColor: palette.primary?.["main"],
          },
          "&$focused:not($disabled):after": {
            borderBottomColor: palette.primary?.["main"],
          },
          "&$disabled:after, &$disabled:before": {
            borderBottom: `1px solid ${colors.grey[650]}`,
          },
          "&:hover:not($disabled):not($error):not($focused):before": {
            borderBottom: `2px solid ${colors.grey[650]}`,
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          marginTop: 2,
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
}

const theme = createTheme(themeOptions)

export default theme
