import { MenuResponse } from "@ignite/api/menus"
import ContentContainer from "@ignite/react/components/Content/ContentContainer"
import { BlockRenderer } from "@ignite/react/components/Content/ContentRenderer"
import Box from "@mui/material/Box"
import { Theme } from "@mui/material/styles"
import React from "react"
import { makeStyles } from "tss-react/mui"

import MegaMenuList from "./MegaMenuList"

type MegaMenuComponentProps = {
  item: MenuResponse

  content?: React.FC
  onClose: () => void
}

const useStyles = makeStyles()(({ palette, spacing }: Theme) => ({
  columns: {
    columns: "auto",
    columnWidth: "228px",
    flex: "1 1 556px",
    minWidth: "556px",
    marginBottom: spacing(3),
  },
  megaMenu: {
    opacity: 0,
    color: palette.getContrastText(palette.header.megaMenu),
    backgroundColor: palette.header.megaMenu,
    transition:
      "opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1), visibility 200ms cubic-bezier(0.4, 0.0, 0.2, 1)",
    borderRadius: `0 0 ${spacing(3)} ${spacing(3)}`,
  },
}))

const MegaMenuComponent: React.FC<MegaMenuComponentProps> = ({
  item,

  onClose,
}) => {
  const { properties, children } = item
  const { classes } = useStyles()

  return (
    <ContentContainer
      left={0}
      visibility="hidden"
      position="absolute"
      className={`megaMenuContent ${classes.megaMenu}`}
      width="100%"
    >
      <Box
        alignItems="flex-start"
        display="flex"
        flexWrap="wrap"
        width="100%"
        margin="0 auto"
        maxWidth="calc(100% - 260px)"
      >
        {children && (
          <Box flexGrow="1" className={classes.columns}>
            {children.map((child, i) => {
              return (
                <MegaMenuList
                  key={`${child.id}-${child.title}-${i}`}
                  onClose={onClose}
                  item={child}
                />
              )
            })}
          </Box>
        )}
        {properties && properties["content"] && (
          <Box onClick={onClose} maxWidth="480px">
            <BlockRenderer content={properties["content"]} />
          </Box>
        )}
      </Box>
    </ContentContainer>
  )
}

export default MegaMenuComponent
