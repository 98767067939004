import Box from "@mui/material/Box"
import { IconProps } from "components/Icons"
import CheckIcon from "components/Icons/CheckIcon"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ typography, spacing }) => ({
  root: {
    padding: 0,
    margin: 0,
    fontFamily: typography.fontFamily,
    ...typography["primary-200"],
    "& li": {
      marginLeft: spacing(4),
      " & > *": {
        verticalAlign: "middle",
      },
    },
  },
  icon: {
    width: 16,
    height: 16,
  },
}))

type UniqueSellingPointsWithIcons = {
  text: string
  href: string
}

type UniqueSellingPointsProps = StyleProps & {
  content?: string[]
  contentWithIcons?: UniqueSellingPointsWithIcons[]
}

type StyleProps = Pick<IconProps, "color"> & {
  stacked?: boolean
  className?: string
}

const UniqueSellingPoints: React.FC<UniqueSellingPointsProps> = ({
  content,
  stacked,
  color,
  contentWithIcons,
  className,
}) => {
  const { classes, cx } = useStyles()

  if (!Array.isArray(content) && !Array.isArray(contentWithIcons)) return null

  return (
    <>
      {contentWithIcons && (
        <Box
          component="ul"
          className={cx(classes.root, className)}
          sx={{
            "& > li": {
              display: (stacked && "block") || "inline-block",
            },
          }}
        >
          {contentWithIcons?.map((item, idx) => (
            <li key={`usp-${idx}`}>
              <img alt="item.text" src={item.href} className={classes.icon} />
              <span>{item.text}</span>
            </li>
          ))}
        </Box>
      )}
      {content && (
        <Box
          component="ul"
          className={cx(classes.root, className)}
          sx={{
            display: (stacked && "block") || "inline-block",
          }}
        >
          {content?.map((item, idx) => (
            <li key={`usp-${idx}`}>
              <CheckIcon size="sm" color={color} /> <span>{item}</span>
            </li>
          ))}
        </Box>
      )}
    </>
  )
}

export default UniqueSellingPoints
