import { CartItem } from "@ignite/api/cart"
import { ImageType } from "@ignite/api/contents"
import Box, { BoxProps } from "@mui/material/Box"
import { Theme } from "@mui/material/styles"
import ResponsiveImage from "components/ResponsiveImage"
import Text from "components/Text"
import React from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "tss-react/mui"

import Price from "../Price"

type SnackbarProductProps = BoxProps & {
  cartItem: CartItem
}

const SnackbarProduct: React.FC<SnackbarProductProps> = ({
  cartItem,
  ...boxProps
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const image = {
    url: cartItem.properties.productImageUrl,
    title: t("snackbar.product_image"),
    color: "transparent",
    width: 70,
    height: 70,
    aspectRatio: 100,
  } as ImageType

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      {...boxProps}
    >
      <ResponsiveImage
        image={image}
        width={80}
        height={80}
        objectFit="contain"
        containerProps={{
          className: classes.imageContainer,
        }}
      />
      <Box display="flex" flexDirection="column">
        <Text variant="primary-300">{cartItem.displayName}</Text>
        <Text variant="secondary-200">{cartItem.properties.description}</Text>
        <Box>
          <Text>
            {t("cart.quantity_$$count$$", {
              count: cartItem.quantity,
            })}
          </Text>
          {cartItem.discountTotal > 0 && (
            <Price variant="primary-300" sale>
              {cartItem.totalPriceString}
            </Price>
          )}
          <Price variant="primary-300" disabled={!!cartItem.discountTotal}>
            {cartItem.totalExcludingDisountsString}
          </Price>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles()(({ palette, spacing }: Theme) => ({
  imageContainer: {
    border: `5px solid ${palette.ui.light}`,
    borderRadius: 6,
    backgroundColor: palette.ui.light,
    marginRight: spacing(4),
  },
}))

export default SnackbarProduct
