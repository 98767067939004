import Box from "@mui/material/Box"
import Button, { ButtonProps } from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import { Theme } from "@mui/material/styles"
import ChevronIcon from "components/Icons/ChevronIcon"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ typography }: Theme) => ({
  button: {
    fontSize: typography["primary-200"].fontSize,
    fontWeight: typography.fontWeightMedium,
  },
}))

type DropDownProps = {
  defaultButtonTitle: string | null
  updateTitleOnSelect?: boolean
  IconComponent?: typeof ChevronIcon
} & ButtonProps

const Dropdown: React.FC<DropDownProps> = ({
  defaultButtonTitle,
  IconComponent = ChevronIcon,
  children,
  size = "small",
  updateTitleOnSelect = true,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [buttonTitle, setButtonTitle] = React.useState<string>(
    defaultButtonTitle || ""
  )
  const { classes } = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (e: React.MouseEvent) => {
    if (updateTitleOnSelect) {
      const newTitle = e.target["innerText"]
      setButtonTitle(newTitle)
    }
    handleClose()
  }

  React.useEffect(() => {
    if (defaultButtonTitle) {
      setButtonTitle(defaultButtonTitle)
    }
  }, [defaultButtonTitle])

  React.useEffect(() => {
    const firstChild = children && children[0]
    if (!defaultButtonTitle && firstChild?.props?.children) {
      // Set the title of the button to the first items label if no default is set
      return setButtonTitle(firstChild.props.children)
    }
  }, [defaultButtonTitle, children])

  return (
    <>
      <Button
        color="inherit"
        className={classes.button}
        size={size}
        onClick={handleClick}
        sx={{ px: "5px", py: 1 }}
        {...props}
      >
        <Box mr={1}>{buttonTitle}</Box>
        <IconComponent size="sm" direction="down" />
      </Button>
      {children && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child as any, {
              onClick: (e: React.MouseEvent) => {
                // Close the menu
                handleSelect(e)

                if (child && (child as React.ReactElement).props) {
                  // check if the child has onclick in props before calling it
                  const { onClick } = (child as React.ReactElement).props
                  if (onClick) {
                    onClick()
                  }
                }
              },
            })
          )}
        </Menu>
      )}
    </>
  )
}

export default Dropdown
