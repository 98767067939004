import emitEvent from "@ignite/utils/eventEmitter"
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button"
import React from "react"

export type ButtonProps = MuiButtonProps & {
  event?: string
}

export const Button: React.FC<ButtonProps> = ({
  variant = "contained",
  color = "primary",
  event,
  children,
  onClick,
  className,
  ...restProps
}) => {
  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClick?.(e)

    if (event) {
      emitEvent(event)
    }
  }

  return (
    <MuiButton
      onClick={((event || onClick) && handleOnClick) || undefined}
      color={color}
      variant={variant}
      className={className}
      {...restProps}
    >
      {children}
    </MuiButton>
  )
}

export default Button
