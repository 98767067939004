import { BaseContent } from "@ignite/api/contents"
import { nonRecursiveTreeTraversal } from "@ignite/utils/collection"
import React from "react"

import { isItemComponent } from "."
import MissingComponent from "./MissingComponent"

export const COMPONENT_CACHE = {
  block: {} as Record<string, React.FC<any>>,
  page: {} as Record<string, React.FC<any>>,
}

//we are only interested in traversing items with these properties
const TREE_TRAVERSAL_PREDICATE = {
  type: 1,
  id: 1,
  properties: 1,
}

export type BaseType = keyof typeof COMPONENT_CACHE

export const preloadResources = async <T extends BaseContent = BaseContent>(
  content: T,
  baseType?: BaseType
) => {
  const componentsToLoad: string[] = []
  nonRecursiveTreeTraversal(content, TREE_TRAVERSAL_PREDICATE, (item) => {
    if (isItemComponent(item)) {
      componentsToLoad.push(item.type)
    }
    return item.properties
  })
  await Promise.all(
    componentsToLoad.map((type, index) =>
      loadComponent(baseType || (index === 0 && "page") || "block", type)
    )
  )

  return content
}

const loadComponent = async (baseType: BaseType, type: string) => {
  if (!COMPONENT_CACHE[baseType][type]) {
    try {
      let component: any

      try {
        //we need to be redundant here as webpack won't know which folder to look in otherwise
        //thats why the string 'blocks' and 'pages' need to be hardcoded and not a variable
        if (type.toLowerCase().endsWith("block")) {
          component = await import(`blocks/${type}`)
        } else {
          component = await import(`pages/${type}`)
        }
      } catch (error) {
        console.error(error)
        throw new Error(
          `Missing "${type}" ${baseType}: Make you have "/src/${baseType}/${type}/index.tsx" set up.`
        )
      }
      if (component && !component.default) {
        throw new Error("Component does not export default")
      }
      COMPONENT_CACHE[baseType][type] = component.default
    } catch (error: any) {
      console.error(error.message)
      COMPONENT_CACHE[baseType][type] = () =>
        React.createElement(MissingComponent, {
          type,
          baseType,
        })
    }
  }
  return COMPONENT_CACHE[baseType][type]
}

export default loadComponent
