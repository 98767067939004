import { useApiContext } from "@ignite/react/context/ApiContextContainer"
import { useUserContext } from "@ignite/react/context/userContext"
import { debounce } from "@mui/material/utils"
import Dialog from "components/Dialog"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import ImpersonationUsersForm, {
  FormValues,
  ImpersonationSearchComponentType,
  ImpersonationUser,
} from "./ImpersonateUsersForm"

type ImpersonateUsersDialogProps = {
  searchComponent: ImpersonationSearchComponentType
}
const ImpersonateUsersDialog: React.FC<ImpersonateUsersDialogProps> = ({
  searchComponent,
}) => {
  const { t } = useTranslation()
  const [loadingState, setLoadingState] = useState<"loading" | "done">()
  const [queryState, setQueryState] = useState<string | null>()
  const [items, setItems] = useState<ImpersonationUser[] | null>()
  const {
    state: { dialog },
    actions: { setDialog },
  } = useUserContext()
  const api = useApiContext()

  const [initialValues, setInitialValues] = useState<{
    items: ImpersonationUser[]
    user?: string
  }>({
    items: [],
  })

  const handleImpersonation = async (values: FormValues) => {
    try {
      await api.users.impersonate(values.user)

      setDialog({
        open: false,
        type: "",
      })

      window.location.reload()
    } catch (_) {
      /** no error handling */
    }

    return null
  }

  const handleClose = () => {
    setItems([])
    setDialog({ open: false, type: "" })
  }

  const searchImpersonateUsers = useRef(
    debounce(async (query: string, loadingState?: any) => {
      setQueryState(query)

      if (!loadingState) {
        setLoadingState("loading")
        try {
          const users = await api.users.getImpersonatableUsers()
          setItems(users)
        } catch (_) {
          setItems([])
        }
        setLoadingState("done")
      }
      return
    }, 500)
  ).current

  useEffect(() => {
    if (loadingState !== "done") return

    const _query = (queryState || "").toLocaleLowerCase()
    const _items =
      queryState && items && _query.trim().length > 0
        ? items
            .filter(
              (item) =>
                item.name.toLocaleLowerCase().includes(_query) ||
                item.username.toLocaleLowerCase().includes(_query) ||
                item.organizationName.toLocaleLowerCase().includes(_query)
            )
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            })
        : []
    setInitialValues({
      items: [..._items],
    })
  }, [items, loadingState, queryState])

  return (
    <Dialog
      title={t("impersonation_dialog.title")}
      open={(dialog.type && dialog.type === "impersonation") || false}
      onClose={handleClose}
    >
      <ImpersonationUsersForm
        SearchComponent={searchComponent}
        initialValues={initialValues}
        enableReinitialize
        loading={loadingState === "loading"}
        onQueryChange={(query) => {
          searchImpersonateUsers(query, loadingState)
        }}
        onSubmit={handleImpersonation}
      />
    </Dialog>
  )
}

export default ImpersonateUsersDialog
