import { MenuResponse } from "@ignite/api/menus"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import { Theme } from "@mui/material/styles"
import RelativeLink from "components/RelativeLink"
import Text from "components/Text"
import React from "react"
import { makeStyles } from "tss-react/mui"

type MegaMenuListProps = {
  item: MenuResponse
  onClose: () => void
}

const useStyles = makeStyles()(({ spacing }: Theme) => ({
  column: {
    display: "block",
    breakInside: "avoid",
    pageBreakInside: "avoid",
    marginBottom: spacing(8),
    minWidth: "228px",
  },
  list: {
    padding: 0,
  },
  listTitle: {
    display: "block",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  listItemWrapper: {
    padding: 0,
  },
  listItem: {
    display: "block",
    marginTop: spacing(3),
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

const MegaMenuList: React.FC<MegaMenuListProps> = ({ item, onClose }) => {
  const { url, title, children } = item
  const { classes } = useStyles()
  return (
    <Box className={classes.column}>
      <RelativeLink href={url} onClick={onClose} className={classes.listTitle}>
        <Text variant="primary-400" bold>
          {title}
        </Text>
      </RelativeLink>

      {children && (
        <List className={classes.list}>
          {children.map((child) => {
            return (
              <ListItem
                key={child.id + child.title}
                className={classes.listItemWrapper}
              >
                {child.url && child.title && (
                  <RelativeLink
                    href={child.url}
                    onClick={onClose}
                    className={classes.listItem}
                  >
                    <Text variant="secondary-300">{child.title}</Text>
                  </RelativeLink>
                )}
              </ListItem>
            )
          })}
        </List>
      )}
    </Box>
  )
}

export default MegaMenuList
