import { AccordionTableItem } from "components/Product/IgniteAccordionTable"

import { ApiComponentType, Properties } from "."
import request from "./request"

export type VerticalAlignment = "top" | "center" | "bottom" | "stretch"
export type HorizontalAlignment = "left" | "center" | "right"

export type ImageType = ApiComponentType & {
  url: string
  sm?: ImageType
  title: string
  color: string
  width: number
  height: number
  aspectRatio: number
}

export type IgniteVariation = ApiComponentType & {
  properties: Properties & {
    code: string
    variationKey: string
    displayPriceInclTax: string
    displayPriceExclTax: string
    salePriceExclTax: string
    salePriceInclTax: string
    instock: boolean
    b2BSAPInStock: boolean
    buyable: boolean
    media: ImageType[]
    size: string
    color: string
    embeddedVideo: string
    documents: AttachedDocument[]
    productAttributes?: {
      heading: string
      text: string
      items: AccordionTableItem[]
    }
    productPackage?: {
      heading: string
      items: AccordionTableItem[]
    }
  }
}

/* TODO: This was created in order to make AddToCart comp work
   on Nelson Barcode Scanner. Should maybe be removed and the API
   should send out a IgniteVariation instead? */
export type MinimumViableIgniteVariation = {
  properties: {
    code: string
    instock: boolean
    buyable: boolean
  }
}

export type VideoType = ApiComponentType & {
  url: string
  poster: string
  sm: VideoType
  title: string
}

type AttachedDocument = ApiComponentType & {
  url: string
  properties: Properties & {
    name: string
  }
}

export type ComponentContent = Omit<ContentResponse, "properties">

export type ContentResponse = BaseContent & {
  pageHeading: string
  meta: Meta
  url: string
  hrefLangs: HrefLang[]
}

export type Meta = {
  title: string
  updatedAt: string
  createdAt: string
  publishedAt?: string
  keywords: string
  metaDescription: string
  properties?: Properties
  canonicalUrl?: string
}

export type HrefLang = {
  lang: string
  href: string
}

export type CommercePromotion = {
  trackPromotionImpression: boolean
  trackPromotionClick: boolean
  promotionId: string
  promotionName: string
  promotionCreative: string
  promotionPosition: string
}

export type Layout = Record<string, string | number>
export type BaseContent = ApiComponentType & {
  properties: Properties
  layout?: Layout
}

type ContentsApi = {
  getContentByIdentifier: (identifier: string) => Promise<ContentResponse>
}

let initialRequest = true

const contentsApi: ContentsApi = {
  getContentByIdentifier: async (identifier) => {
    if (initialRequest && window.CONTENT_DATA) {
      initialRequest = false
      window.INITIAL_REQUEST = true
      return window.CONTENT_DATA
    }

    window.INITIAL_REQUEST = false
    return await request.get<ContentResponse>(
      `/contents/${identifier}${window.location.search}`
    )
  },
}

export default contentsApi
