import Box, { BoxProps } from "@mui/material/Box"
import Input from "@mui/material/Input"
import Button from "components/Button"
import React from "react"
import { makeStyles } from "tss-react/mui"

import AddIcon from "../Icons/AddIcon"
import RemoveIcon from "../Icons/RemoveIcon"

type QuantityCounterProps = BoxProps & {
  quantity: number | ""
  disabled: boolean
  max: number
  size?: "narrow" | "normal"
  onDecrement: () => void
  onIncrement: () => void
  onBlur: () => void
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export type QuantityCounterClassKey = "root"

const QuantityCounter: React.FC<QuantityCounterProps> = ({
  quantity,
  disabled,
  max,
  onDecrement,
  onIncrement,
  onInputChange,
  onBlur,
  size = "normal",
  ...boxProps
}) => {
  const { classes, cx } = useStyles()

  return (
    <Box
      className={cx(boxProps.className, classes.root)}
      {...boxProps}
      data-testid="quantity-counter"
    >
      <Button
        variant="outlined"
        className={classes.button}
        disabled={quantity <= 1 || disabled}
        onClick={onDecrement}
        data-testid="counter-decrement"
      >
        <RemoveIcon size="sm" />
      </Button>
      <Input
        value={quantity}
        onChange={onInputChange}
        onBlur={onBlur}
        type="text"
        disabled={disabled}
        disableUnderline
        data-testid="counter-value"
        inputProps={{
          className: classes.input,
        }}
        sx={({ spacing, typography }) => ({
          ...(size === "narrow" && {
            ...typography["primary-100"],
          }),
          "& input": {
            height: size === "narrow" ? spacing(8.5) : spacing(13),
          },
        })}
      />
      <Button
        variant="outlined"
        className={classes.button}
        disabled={quantity >= max || disabled}
        onClick={onIncrement}
        data-testid="counter-increment"
      >
        <AddIcon size="sm" />
      </Button>
    </Box>
  )
}

const useStyles = makeStyles()(({ palette, spacing, typography }) => ({
  root: {
    border: `1px solid ${palette.ui.main}`,
    display: "flex",
    flex: "0",
    marginRight: spacing(6),
    borderRadius: 48,
  },
  button: {
    fontWeight: typography.fontWeightBold,
    color: palette.primary.main,
    padding: 0,
    minWidth: spacing(10),
    border: "none",
    backgroundColor: "transparent",
    userSelect: "none",
    "&:disabled": {
      border: "none",
    },
    "&:hover": {
      border: "none",
      backgroundColor: "transparent",
    },
  },
  input: {
    textAlign: "center",
    color: palette.text.primary,
    padding: 0,
    width: `${spacing(9)} !important`,
    WebkitAppearance: "none",
    border: "none !important",
    backgroundColor: "transparent",
    "&:hover": {
      border: "none",
      backgroundColor: "transparent",
    },
    "&:focus": {
      border: "none",
      backgroundColor: "transparent",
    },
  },
}))

export default QuantityCounter
