import { useSearchContext } from "@ignite/react/context/searchContext"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import SearchIcon from "components/Icons/SearchIcon"
import React from "react"

type SearchButtonProps = IconButtonProps

const SearchButton: React.FC<SearchButtonProps> = (props) => {
  const {
    actions: { toggle },
  } = useSearchContext()

  return (
    <IconButton
      onClick={toggle}
      color="inherit"
      aria-label="search"
      {...props}
      size="large"
    >
      <SearchIcon />
    </IconButton>
  )
}

export default SearchButton
