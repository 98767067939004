import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DefaultDialogActions from "@mui/material/DialogActions"
import MuiDialogContent from "@mui/material/DialogContent"
import React from "react"
import { makeStyles } from "tss-react/mui"

import DefaultDialogTitle from "./DialogTitle"

export type DialogClassKey = "root" | "paper" | "content" | "actions"

type Props = {
  open: boolean
  onClose: VoidFunction
  title?: string | React.ReactElement
  actions?: React.ReactElement[]
  showCloseButton?: boolean
  DialogTitleElement?: React.FC
  DialogActionsElement?: React.FC
  children?: React.ReactNode
}

const useStyles = makeStyles()(({ breakpoints, spacing }) => ({
  root: {
    margin: 0,
  },
  paper: {
    maxWidth: 627,
    borderRadius: 12,
    padding: spacing(4),
    margin: spacing(4),

    [breakpoints.up("md")]: {
      width: 627,
      padding: spacing(12),
    },
  },
  content: {
    padding: 0,
  },
  actions: {
    paddingRight: 0,
    paddingLeft: 0,
  },
}))

const LoginDialog: React.FC<Props> = ({
  open,
  onClose,
  title,
  actions,
  showCloseButton = true,
  DialogTitleElement = DefaultDialogTitle,
  DialogActionsElement = DefaultDialogActions,
  children,
  ...restProps
}) => {
  const { classes } = useStyles()

  if (!children) {
    return null
  }

  return (
    <Dialog
      className={classes.root}
      PaperProps={{ classes: { root: classes.paper } }}
      onClose={onClose}
      maxWidth="sm"
      open={open}
      {...restProps}
    >
      {title && (
        <DialogTitleElement showCloseButton={showCloseButton} onClose={onClose}>
          {title}
        </DialogTitleElement>
      )}

      <MuiDialogContent className={classes.content}>
        {children}
      </MuiDialogContent>

      {actions && (
        <DialogActionsElement className={classes.actions}>
          {React.Children.map(actions, (action) => (
            <Box ml={1}>{action}</Box>
          ))}
        </DialogActionsElement>
      )}
    </Dialog>
  )
}

export default LoginDialog
