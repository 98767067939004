import Button, { ButtonProps } from "components/Button"
import RelativeLink from "components/RelativeLink"
import React from "react"

type ButtonLinkProps = ButtonProps & {
  link: string
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  link,
  event,
  ...restProps
}) => {
  return (
    ((children || link) && (
      <RelativeLink event={event} href={link} component={Button} {...restProps}>
        {children}
      </RelativeLink>
    )) ||
    null
  )
}

export default ButtonLink
