import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useSvgStyles = makeStyles()(() => ({
  xs: {
    width: "8px",
    height: "8px",
  },
  sm: {
    width: "16px",
    height: "16px",
  },
  md: {
    width: "24px",
    height: "24px",
  },
  lg: {
    width: "32px",
    height: "32px",
  },
  xl: {
    width: "48px",
    height: "48px",
  },
}))

export type IconDirection = "up" | "down" | "left" | "right"
export type IconHorizontalDirection = "left" | "right"

export type IconProps = {
  size?: "xs" | "sm" | "md" | "lg" | "xl"
} & SvgIconProps

const Icon: React.FC<IconProps> = ({
  children,
  size = "medium",
  stroke = "currentColor",
  fill = "none",
  strokeLinecap = "round",
  strokeLinejoin = "round",
  className,
  ...restProps
}) => {
  const { classes, cx } = useSvgStyles()

  return (
    <SvgIcon
      className={cx(className, {
        [classes["xs"]]: size === "xs",
        [classes["sm"]]: size === "sm",
        [classes["md"]]: size === "md",
        [classes["lg"]]: size === "lg",
        [classes["xl"]]: size === "xl",
      })}
      {...restProps}
      stroke={stroke}
      strokeLinecap={strokeLinecap}
      strokeLinejoin={strokeLinejoin}
      sx={{
        fill,
      }}
    >
      {children}
    </SvgIcon>
  )
}

export default Icon
