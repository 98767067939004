import request from "./request"

type InvoiceResponseModel = Invoice[]

type GetInvoiceResponseModel = Invoice

export type Invoice = {
  currency: string
  invoiceDate: string
  invoiceNumber: string
  lineItems: LineItem[]
  netValue: number
  soldTo: string
  invoiceTotal: number
}

export type LineItem = {
  description: string
  invoiceQuantity: number
  material: string
  netValue: number
  productImageUrl: string
  salesOrderNumber: string
  subtotal: number
}

type InvoiceApi = {
  getInVoice: (id: string) => Promise<GetInvoiceResponseModel>
  getInvoices: (
    limit?: number,
    offset?: number
  ) => Promise<InvoiceResponseModel>
}

const invoiceApi: InvoiceApi = {
  getInVoice: (id: string) => request.get(`/invoices/${id}`),
  getInvoices: (limit = 20, offset = 0) =>
    request.get(`/invoices/history/all?limit=${limit}&offset=${offset}`),
}

export default invoiceApi
