import { Market } from "@ignite/api/markets"
import { SelectChangeEvent } from "@mui/material/Select"
import MobileMenuSelect from "components/MobileMenu/MobileMenuSelect"
import React from "react"

type MarketPickerMobileProps = {
  markets: Market[]
  selectedMarket: Market
  onChange: (selectedMarket: string) => void
}

const convertMarketDataToSelectArray = (markets: Market[]) => {
  if (!markets) {
    return []
  }

  const selectData = markets.map((item) => ({
    text: item.displayName,
    value: item.marketId,
    selected: item.isSelected,
  }))

  return selectData
}

const MarketPickerMobile: React.FC<MarketPickerMobileProps> = ({
  markets,
  selectedMarket,
  onChange,
  ...restProps
}) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedMarket = event.target.value as string

    if (selectedMarket) {
      onChange(selectedMarket)
    }
  }

  return (
    <MobileMenuSelect
      items={convertMarketDataToSelectArray(markets)}
      onChange={handleChange}
      label={selectedMarket.displayName}
      align="left"
      {...restProps}
    />
  )
}

export default MarketPickerMobile
