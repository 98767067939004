const CACHE: { BOT_DETECTED: boolean | null } = {
  BOT_DETECTED: null,
}

const SEARCH_BOT_AGENTS = (process.env.REACT_APP_SEARCH_BOT_USER_AGENTS || "")
  .toLowerCase()
  .split(",")

const LOWER_CASE_USER_AGENT = navigator.userAgent.toLowerCase()

export const isSearchBot = () => {
  if (CACHE.BOT_DETECTED !== null) {
    return CACHE.BOT_DETECTED
  }

  const botDetected = window.BOT_DETECTED
    ? window.BOT_DETECTED
    : SEARCH_BOT_AGENTS.some((agent: string) =>
        LOWER_CASE_USER_AGENT.includes(agent)
      )

  CACHE.BOT_DETECTED = botDetected

  return botDetected
}
