import React from "react"

import Icon, { IconProps } from "."

const TrashIcon: React.FC<IconProps> = ({
  stroke = "currentColor",
  ...restProps
}) => (
  <Icon {...restProps} strokeWidth="2" stroke="none">
    <path
      vectorEffect="non-scaling-stroke"
      stroke={stroke}
      d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
    />
  </Icon>
)

export default TrashIcon
