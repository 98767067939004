import request from "./request"

export type CartItem = {
  code: string
  discountTotal: number
  discountTotalString: string
  displayName: string
  id: number
  isGift: boolean
  price: number
  priceString: string
  quantity: number
  totalExcludingDisounts: number
  totalExcludingDisountsString: string
  totalPrice: number
  totalPriceString: string
  properties: Record<string, any>
}

export type CartResponse = {
  id: string
  name: CartName
  currency: string
  shippingTotal: number
  subTotal: number
  taxTotal: number
  total: number
  totalString: string
  shippingTotalString: string
  taxTotalString: string
  subTotalString: string
  amountToFreeShipping: string
  totalItems: number
  items: CartItem[]
  properties: Record<string, any>
  validationIssues: {
    key: string
    value: string
  }[]
}

export const CartValidationIssueValue = {
  AdjustedQuantityByAvailableQuantity: "AdjustedQuantityByAvailableQuantity",
  AdjustedQuantityByMaxQuantity: "AdjustedQuantityByMaxQuantity",
}

export type CartName = "basket" | "wishlist"

type CartProperties = {
  RequestedDeliveryDate: string
}

type CartApi = {
  getCarts: () => Promise<CartResponse[]>
  getCart: (name: CartName) => Promise<CartResponse>
  addToCart: (
    name: CartName,
    code: string,
    quantity: number
  ) => Promise<CartResponse>
  addItemsToCart: (
    name: CartName,
    items: {
      itemCode: string
      quantity: number
      properties: Record<string, any>
    }[]
  ) => Promise<CartResponse>
  setCartItemQuantity: (
    name: CartName,
    code: string,
    quantity: number
  ) => Promise<CartResponse>
  removeFromCart: (name: CartName, code: string) => Promise<CartResponse>
  setCartProperties: (
    name: CartName,
    properties: CartProperties
  ) => Promise<CartResponse>
}

const cartApi: CartApi = {
  getCarts: () => request.get<CartResponse[]>(`/carts/`),
  getCart: (name) => request.get<CartResponse>(`/carts/${name}`),
  addToCart: (name, code, quantity) =>
    request.post<CartResponse>(`/carts/${name}/items/${code}`, { quantity }),
  addItemsToCart: (name, items) =>
    request.post<CartResponse>(`carts/${name}/items`, items),
  setCartItemQuantity: (name, code, quantity) =>
    request.put<CartResponse>(`/carts/${name}/items/${code}`, { quantity }),
  removeFromCart: (name, code) =>
    request.delete<CartResponse>(`/carts/${name}/items/${code}`),
  setCartProperties: (name, properties: CartProperties) =>
    request.post<CartResponse>(`/carts/${name}/properties`, properties),
}

export default cartApi
