import { useMenuContext } from "@ignite/react/context/menuContext"
import { useSiteSettingsContext } from "@ignite/react/context/siteSettingsContext"
import { useUserContext } from "@ignite/react/context/userContext"
import jwt from "@ignite/services/jwt"
import useSystemLinks from "@ignite/utils/systemLinksUtil"
import Box from "@mui/material/Box"
import Button from "components/Button"
import LogoutIcon from "components/Icons/LogoutIcon"
import UserIcon from "components/Icons/UserIcon"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import HeaderLogo from "shell/Header/HeaderLogo"
import { makeStyles } from "tss-react/mui"

import MobileMenuAuthForm from "./MobileMenuAuthForm"
import MobileMenuContent, { MobileMenuContentProps } from "./MobileMenuContent"
import MobileMenuPanel from "./MobileMenuPanel"

type RootMenuProps = MobileMenuContentProps & {
  toolbarContent?: React.ReactNode
  LogoComponent?: typeof HeaderLogo
  LogoComponentProps?: typeof HeaderLogo.defaultProps
  setPrevMenu: React.Dispatch<React.SetStateAction<any>>
  setNextMenu: React.Dispatch<React.SetStateAction<any>>
  AuthFormComponent: typeof MobileMenuAuthForm
  children?: React.ReactNode
}

const useStyles = makeStyles()(({ spacing, palette, typography }) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    "& .toolbar": {
      color: palette.getContrastText(palette.header.primary),
      backgroundColor: palette.header.primary,
    },
    "& nav": {
      padding: spacing(14, 0),
    },
  },
  logoutBtn: {
    marginTop: spacing(12),
    border: `1px solid ${palette.ui.main}`,
    ...typography["primary-200"],
  },
  logoutBtnIcon: {
    marginRight: spacing(1),
  },
  authButton: {
    marginTop: 16,
    marginBottom: 0,
    marginLeft: 8,
    padding: `16px 0`,
    border: `1px solid ${palette.ui.main}`,
    color: palette.branding?.black[500],
    fontSize: typography["primary-100"].fontSize,
    fontWeight: typography.fontWeightLight,
    width: "100%",
    marginRight: spacing(2),
  },

  authButtonIcon: {
    width: 12,
    marginRight: 12,
    color: palette.branding?.green["main"],
  },
}))

const RootMenu: React.FC<RootMenuProps> = ({
  toolbarContent,
  LogoComponent = HeaderLogo,
  LogoComponentProps,
  onMenuItemClicked,
  setNextMenu,
  setPrevMenu,
  className,
  children,
  menu,
  AuthFormComponent,
}) => {
  const {
    state: {
      user: { data: userMe },
    },
  } = useUserContext()

  const {
    actions: { toggle },
  } = useMenuContext()

  const navigate = useNavigate()

  const {
    state: { siteSettings },
  } = useSiteSettingsContext()

  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const closeAuthMenu = () => {
    setPrevMenu(menu)
  }

  const handleLogout = async () => {
    jwt.clear()
    window.location.href = "/"
  }

  const showAuthMenu = () => {
    setNextMenu(
      <MobileMenuPanel
        title={t("mobilemenu.login_title")}
        onBackClicked={closeAuthMenu}
      >
        <AuthFormComponent />
      </MobileMenuPanel>
    )
  }

  const onAuthButtonClick = () => {
    if (!userMe) {
      showAuthMenu()
    } else {
      const url = links["myProfile"]
      navigate(url)
      toggle()
    }
  }

  const resellerPortalDisabled =
    siteSettings?.data?.properties["resellerPortalDisabled"]

  const links = useSystemLinks()

  return (
    <MobileMenuContent
      menu={menu}
      className={cx(classes.root, className)}
      onMenuItemClicked={onMenuItemClicked}
      toolbarContent={
        <>
          <LogoComponent
            link={null}
            drawer
            justifyContent="flex-start"
            {...LogoComponentProps}
          />
          {toolbarContent || <Box flexGrow={1} />}
        </>
      }
    >
      {!resellerPortalDisabled && !userMe && (
        <Box display="flex" mt={4}>
          <Button
            variant="outlined"
            size="large"
            className={classes.authButton}
            onClick={onAuthButtonClick}
            fullWidth
          >
            <UserIcon className={classes.authButtonIcon} />
            {t("mobilemenu.login_title")}
          </Button>
        </Box>
      )}
      {userMe && (
        <Box mx={2}>
          <Button
            variant="outlined"
            className={classes.logoutBtn}
            onClick={handleLogout}
            fullWidth
          >
            <LogoutIcon className={classes.logoutBtnIcon} />
            {t("mypages.header_cta_logout")}
          </Button>
        </Box>
      )}
      {children}
    </MobileMenuContent>
  )
}

export default RootMenu
