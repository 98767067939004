import { CartResponse } from "@ignite/api/cart"
import { ContentResponse } from "@ignite/api/contents"
import { usePageContext } from "@ignite/react/context/pageContext"
import { useSiteSettingsContext } from "@ignite/react/context/siteSettingsContext"
import { ProductCardProps } from "components/ProductCard"
import { ConfirmedOrder } from "pages/OrderConfirmationPage"
import { useEffect } from "react"

const DataLayerPublisher: React.FC = () => {
  const {
    state: { page },
  } = usePageContext()

  const {
    state: { siteSettings },
  } = useSiteSettingsContext()

  useEffect(() => {
    if (!siteSettings.data?.properties.enableDataLayer) {
      return
    }

    if (window.BOT_DETECTED) return

    const eventListener = (e: Event) => {
      const customEvent = e as CustomEvent
      const emittedEvent = customEvent.detail

      const payload = getPayloadByEvent(emittedEvent, page && page.data)

      if (window["ENVIRONMENT"] !== "Production") {
        console.log(JSON.stringify(payload))
      }

      if (emittedEvent && window["dataLayer"] && payload) {
        window["dataLayer"].push(payload)
      }
    }
    window.addEventListener("igniteEvent", eventListener)

    return () => {
      window.removeEventListener("igniteEvent", eventListener)
    }
  }, [page, siteSettings])

  return null
}

const removeNonAscii = (str: string) =>
  str
    ?.trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")

const getFriendlyPageName = (pageType: string) => {
  switch (pageType) {
    case "IgniteProduct":
      return "Product Page"

    case "IgniteCategory":
      return "Category Page"

    case "StartPage":
      return "Start Page"

    default:
      return pageType
  }
}

const getTagName = (tag: string | undefined) => tag ?? "standard"

const getPayloadByEvent: any = (
  emittedEvent: any,
  page: ContentResponse | any
) => {
  if (!emittedEvent) return

  if (!emittedEvent.data) return

  const productCard = emittedEvent.data.product as ProductCardProps

  switch (emittedEvent.eventType) {
    case "productClick":
      return {
        event: emittedEvent.eventType,
        ecommerce: {
          click: {
            actionField: {
              list:
                page &&
                `${getFriendlyPageName(page.type)} | ${emittedEvent.data.list}`,
            },
            products: [
              {
                name: removeNonAscii(productCard.displayName),
                id: productCard.code,
                price: productCard.calculatedPriceExclTax?.toString(),
                brand: removeNonAscii(productCard.brand),
                category: removeNonAscii(productCard.categoryPath),
                tag: getTagName(
                  productCard.tags && productCard.tags?.join(",")
                ),
                variant: productCard.variantName ?? "normal",
                list:
                  page &&
                  `${getFriendlyPageName(page.type)} | ${
                    emittedEvent.data.list
                  }`,
                position: productCard.position,
                stockStatus: productCard.availability ?? "unknown",
              },
            ],
          },
        },
      }

    case "productImpression":
      return {
        event: emittedEvent.eventType,
        ecommerce: {
          currencyCode: window["CURRENT_CURRENCY"],
          impressions: [
            {
              name: removeNonAscii(productCard.displayName),
              id: productCard.code,
              price: productCard.calculatedPriceExclTax?.toString(),
              brand: removeNonAscii(productCard.brand),
              category: removeNonAscii(productCard.categoryPath),
              tag: getTagName(productCard.tags && productCard.tags?.join(",")),
              variant: productCard.variantName ?? "normal",
              list:
                page &&
                `${getFriendlyPageName(page.type)} | ${
                  emittedEvent.data.list ?? "list"
                }`,
              position: productCard.position,
              stockStatus: productCard.availability ?? "unknown",
            },
          ],
        },
      }

    case "productPageView":
      return {
        event: emittedEvent.eventType,
        ecommerce: {
          detail: {
            products: [
              {
                name: removeNonAscii(emittedEvent.data.displayName),
                displayName2: removeNonAscii(emittedEvent.data.displayName2),
                id: emittedEvent.data.variation.properties.code,
                price:
                  emittedEvent.data.variation.properties.calculatedPriceExclTax?.toString(),
                brand: removeNonAscii(emittedEvent.data.brand),
                category: removeNonAscii(emittedEvent.data.categoryPath),
                variant:
                  removeNonAscii(
                    emittedEvent.data.variation.properties[
                      emittedEvent.data.variation.properties.variationKey
                    ]
                  ) ?? "normal",
                tag: getTagName(emittedEvent.data.tags),
                position: emittedEvent.data.position,
                stockStatus:
                  emittedEvent.data.variation.properties.availability ??
                  "unknown",
              },
            ],
          },
        },
      }

    // When implementing Add to Cart CTA on product lists, remember to add actionField in the push below
    case "addToCart":
      return {
        event: emittedEvent.eventType,
        ecommerce: {
          currencyCode: window["CURRENT_CURRENCY"],
          add: {
            products: [
              {
                name: removeNonAscii(emittedEvent.data.lineItem.displayName),
                id: emittedEvent.data.lineItem.code,
                price:
                  emittedEvent.data.lineItem.properties.calculatedPriceExclTax?.toString(),
                brand: removeNonAscii(
                  emittedEvent.data.lineItem.properties.brand
                ),
                category: emittedEvent.data.lineItem.properties.category,
                variant:
                  removeNonAscii(
                    emittedEvent.data.lineItem.properties.variant
                  ) ?? "normal",
                tag: getTagName(emittedEvent.data.lineItem.properties.tags),
                quantity: emittedEvent.data.quantity,
                addedPosition: emittedEvent.data.lineItem.id * -1,
              },
            ],
          },
        },
      }
    case "removeFromCart":
      return {
        event: emittedEvent.eventType,
        ecommerce: {
          currencyCode: window["CURRENT_CURRENCY"],
          remove: {
            actionField: {
              list: getFriendlyPageName(emittedEvent.data.actionField),
            },
            products: [
              {
                name: removeNonAscii(emittedEvent.data.lineItem.displayName),
                id: emittedEvent.data.lineItem.code,
                price:
                  emittedEvent.data.lineItem.calculatedPriceExclTax?.toString(),
                brand: removeNonAscii(
                  emittedEvent.data.lineItem.properties.brand
                ),
                category: emittedEvent.data.lineItem.properties.category,
                variant: removeNonAscii(
                  emittedEvent.data.lineItem.properties.variant
                ),
                tag: getTagName(emittedEvent.data.lineItem.properties.tags),
                quantity: emittedEvent.data.quantity,
              },
            ],
          },
        },
      }

    case "promotionClick":
      return {
        event: emittedEvent.eventType,
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: emittedEvent.data.id,
                name: emittedEvent.data.name,
                creative: emittedEvent.data.creative,
                position: emittedEvent.data.position,
              },
            ],
          },
        },
      }

    case "promoView":
      return {
        event: emittedEvent.eventType,
        ecommerce: {
          promoView: {
            promotions: [
              {
                id: emittedEvent.data.id,
                name: emittedEvent.data.name,
                creative: emittedEvent.data.creative,
                position: emittedEvent.data.position,
              },
            ],
          },
        },
      }

    case "virtualPageView":
      return {
        event: emittedEvent.eventType,
        url: emittedEvent.data.url,
        title: emittedEvent.data.title,
      }

    case "changeLanguage":
      return {
        event: emittedEvent.eventType,
        fromLanguage: emittedEvent.data.fromLanguage,
        toLanguage: emittedEvent.data.toLanguage,
      }

    case "clickOnSearch":
      return {
        event: emittedEvent.eventType,
      }

    case "checkout":
      return {
        event: emittedEvent.eventType,
        ecommerce: {
          checkout: {
            actionField: {
              step: emittedEvent.data.step,
              option: emittedEvent.data.option,
            },
            products: (
              emittedEvent.data.checkout.cart as CartResponse
            ).items.map((lineItem) => {
              return {
                name: removeNonAscii(lineItem.displayName),
                id: lineItem.code,
                price: lineItem.properties.calculatedPriceExclTax?.toString(),
                brand: removeNonAscii(lineItem.properties.brand),
                category: lineItem.properties.category,
                variant:
                  removeNonAscii(lineItem.properties.variant) ?? "normal",
                tag: getTagName(lineItem.properties.tags),
                quantity: lineItem.quantity,
                currency: lineItem.properties.currency,
              }
            }),
          },
        },
      }

    case "purchase":
      return {
        event: emittedEvent.eventType,
        ecommerce: {
          purchase: {
            actionField: {
              id: emittedEvent.data.o,
              revenue: emittedEvent.data.r,
              tax: emittedEvent.data.t,
              shipping: emittedEvent.data.s,
              coupon: emittedEvent.data.c,
            },
            products: (emittedEvent.data as ConfirmedOrder).p.map(
              (lineItem) => {
                return {
                  name: removeNonAscii(lineItem.n),
                  id: lineItem.c,
                  price: lineItem.p,
                  brand: removeNonAscii(lineItem.b),
                  category: lineItem.ct,
                  variant: removeNonAscii(lineItem.v) ?? "normal",
                  tag: getTagName(lineItem.t),
                  quantity: lineItem.q,
                  currency: lineItem.cu,
                }
              }
            ),
          },
        },
      }

    case "productExpanders":
      return {
        event: emittedEvent.eventType,
        interaction: "click",
        name: emittedEvent.data.name,
        clickToState: emittedEvent.data.clickToState ? "open" : "close",
      }

    case "sortProduct":
      return {
        event: emittedEvent.eventType,
        optionSelected: emittedEvent.data.option,
      }

    case "productFiltering":
      return {
        event: emittedEvent.eventType,
        filteringCategorySelected: emittedEvent.data.category,
        filteringOptionSelected: emittedEvent.data.value,
      }

    case "highlightCard":
    case "blogCard":
    case "mediaCard":
    case "heroEngagements":
      return {
        event: emittedEvent.eventType,
        ctaType: emittedEvent.data.ctaType,
        message: emittedEvent.data.message,
        cta: emittedEvent.data.cta,
        clickToURL: emittedEvent.data.clickToURL,
      }

    default:
      return null
  }
}

export default DataLayerPublisher
