import api from "@ignite/api"
import { useCartContext } from "@ignite/react/context/cartContext"
import useBreakpoint from "@ignite/react/hooks/useBreakpoint"
import Box from "@mui/material/Box"
import FormHelperText from "@mui/material/FormHelperText"
import Button from "components/Button"
import TextField from "components/Forms/TextField"
import Text from "components/Text"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ spacing }) => ({
  input: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "black",
    },
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
    textUnderlineOffset: spacing(0.3),
    wordBreak: "break-all",
    textAlign: "right",
    marginTop: spacing(1),
  },
}))

type Props = {
  onSetForgotPasswordOpen: (isOpen: boolean) => void
}

const ForgotPasswordForm = ({ onSetForgotPasswordOpen }: Props) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const isSmall = useBreakpoint("md")

  const [errorMessageState, setErrorMessageState] = useState<string | null>()
  const [customerNumberValid, setCustomerNumberValid] = useState(false)
  const [customerNumber, setCustomerNumber] = useState("")

  const {
    actions: { setSnackbar },
  } = useCartContext()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (!value) {
      setCustomerNumberValid(false)
      return
    }
    setCustomerNumberValid(true)
    setCustomerNumber(value)
  }

  const handleRequestPasswordReset = async (customerNumber: string) => {
    try {
      await api.users.requestPasswordReset(customerNumber)
      setErrorMessageState(null)
      setCustomerNumber("")
      setSnackbar({
        headerTitle: t("login.reset_password_request_success"),
        name: "basket",
        bodyComponent: () => <>{t("login.reset_password_email_was_sent")}</>,
      })
      setCustomerNumberValid(false)
    } catch (err) {
      setErrorMessageState(t("login.form_text_generic_error"))
    }
  }

  return (
    <>
      <Text paragraph variant={isSmall ? "primary-200" : "primary-300"}>
        {t("login.forgot_password_description")}
      </Text>

      <Box mt={6}>
        <TextField
          value={customerNumber}
          className={classes.input}
          label={t("my_profile_page.label_customer_number")}
          name="customerNumber"
          id="customerNumber"
          onChange={handleInputChange}
        />
        <Text
          component="p"
          variant="secondary-100"
          className={classes.link}
          onClick={() => {
            onSetForgotPasswordOpen(false)
          }}
        >
          {t("login.back_to_login")}
        </Text>
      </Box>

      {errorMessageState && (
        <FormHelperText error sx={{ mt: 6, textAlign: "center" }}>
          {errorMessageState}
        </FormHelperText>
      )}

      <Box mt={8} display="flex" justifyContent="center">
        <Button
          disabled={!customerNumberValid}
          onClick={() => handleRequestPasswordReset(customerNumber)}
        >
          {t("login.reset_password")}
        </Button>
      </Box>
    </>
  )
}

export default ForgotPasswordForm
