import request from "./request"

export type Hit = {
  displayName: string
  displayName2: string
  defaultVariationDisplayName: string
  tags: string[]
  productUrl: string
  showAddToCart: boolean
  image: {
    type: string
    color: string
    width: number
    height: number
    aspectRatio: number
    url: string
  }
  secondImage: {
    type: string
    color: string
    width: number
    height: number
    aspectRatio: number
    url: string
  }
  code: string
  variants: string[]
  calculatedPriceExclTax: number
  baseType: string
  variationCodes: string[]
  tagsByMarket?: {
    b2B_DAN: unknown[]
    b2B_FIN: unknown[]
    b2B_GER: unknown[]
    b2B_NOR: unknown[]
    b2B_POL: unknown[]
    b2B_SWE: unknown[]
    b2C_DAN: unknown[]
    b2C_FIN: unknown[]
    b2C_GER: unknown[]
    b2C_NOR: unknown[]
    b2C_POL: unknown[]
    b2C_SWE: unknown[]
    default: unknown[]
  }
  hitId: string
  hitType: string
  position: number
  categoryPath: string
  brand: string
  variantName: string
  isOutOfSales: boolean
  availability: string
}

type SearchResponse = {
  // TODO: Remove type "any" and make it work for both requests on the search api
  hits: Hit[] | any[]

  /** Others also searched for. */
  relatedSearches: string[]

  /** Unique ID for this search. Pass to trackClick(..) if user clicks a search hit .*/
  trackId: string
}

export type EanSearchResponse = {
  displayName: string
  displayName2: string
  description: string
  code: string
  inStock: boolean
  buyable: boolean
  mediaUrl: string
  mediaUrls: string[]
  addToCartInterval: number
  displayPriceInclTax: string
}

type SearchApi = {
  getSearch: (query: string, params?: string) => Promise<SearchResponse>

  /** String[] with queries beginning with our input */
  getAutocomplete: (input: string) => Promise<string[]>

  /** If the backend supports tracking which result the user clicked on for statistics - call this method before navigating */
  trackClick: (query: string, hitId: string, trackId: string) => Promise<any>

  ean: (query: string) => Promise<EanSearchResponse>
}

const searchApi: SearchApi = {
  getSearch: (query, params) =>
    request.get<SearchResponse>(`/search/${query}${params ? params : ""}`),
  getAutocomplete: (input) =>
    request.get<string[]>(`/search/autocomplete/${input}`),
  trackClick: (query, hitId, trackId) =>
    request.get<SearchResponse>(
      `/search/track?query=${query}&hitId=${hitId}&trackId=${trackId}`
    ),
  ean: (query) => request.get<EanSearchResponse>(`/search/ean/${query}`),
}

export default searchApi
