import Box from "@mui/material/Box"
import Button, { ButtonProps } from "@mui/material/Button"
import InfoIcon from "components/Icons/InfoIcon"
import SecondaryArrowIcon from "components/Icons/SecondaryArrowIcon"
import RelativeLink, { RelativeLinkProps } from "components/RelativeLink"
import React from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "tss-react/mui"

import HeaderContainer, { HeaderContainerProps } from "./HeaderContainer"
import HeaderLogo from "./HeaderLogo"

const useStyles = makeStyles()(({ breakpoints, typography, palette }) => ({
  root: {
    minHeight: 120,
    [breakpoints.down("md")]: {
      minHeight: 60,
    },
  },
  logo: {
    width: 131,
    position: "absolute",
    top: 0,
  },
  link: {
    color: palette.common.white,
    "& > *": { verticalAlign: "middle" },
    ...typography["primary-200"],
    fontWeight: 500,
    "&:hover": {
      color: palette.common.white,
      fontWeight: 500,
    },
  },
  linktext: {
    [breakpoints.down("md")]: {
      display: "none",
    },
  },
}))

const DefaultLogoComponent = HeaderLogo

type CheckoutHeaderProps = {
  logoWidth?: number
  LogoComponent?: typeof DefaultLogoComponent
  RightComponent?: React.ReactNode
  LeftComponent?: React.ReactNode
  containerProps?: HeaderContainerProps
  leftComponentProps?: ButtonProps
  rightComponentProps?: RelativeLinkProps
}

const HeaderCheckout: React.FC<CheckoutHeaderProps> = ({
  LogoComponent = DefaultLogoComponent,
  LeftComponent = null,
  RightComponent = null,
  containerProps,
  leftComponentProps,
  rightComponentProps,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  return (
    <HeaderContainer
      className={classes.root}
      marquee={false}
      topSection={false}
      hasLeftContent={true}
      {...containerProps}
    >
      <Box flex="1 1 0" justifyContent="flex-start">
        {LeftComponent === null && (
          <Box>
            <Button
              className={classes.link}
              variant="text"
              color="primary"
              startIcon={
                <SecondaryArrowIcon direction="left" strokeWidth={2} />
              }
              {...leftComponentProps}
            >
              <Box className={classes.linktext} component="span">
                {t("checkout.continue_shopping")}
              </Box>
            </Button>
          </Box>
        )}
        {LeftComponent}
      </Box>
      <Box flex="1 1 0" display="flex" justifyContent="center">
        <LogoComponent
          link={`${
            window["HOME_PAGE_ROUTE"]
              ? window["HOME_PAGE_ROUTE"]?.toLowerCase()
              : "/"
          }`}
          className={classes.logo}
        />
      </Box>

      <Box
        component="div"
        display="flex"
        flex="1 1 0"
        justifyContent="flex-end"
      >
        {RightComponent === null && (
          <Box>
            {rightComponentProps?.href && (
              <RelativeLink className={classes.link} {...rightComponentProps}>
                <InfoIcon size="md" />
                <Box
                  className={classes.linktext}
                  component="span"
                  ml={2}
                  mr={2}
                >
                  {t("checkout.help_needed")}
                </Box>
              </RelativeLink>
            )}
          </Box>
        )}
        {RightComponent}
      </Box>
    </HeaderContainer>
  )
}

export default HeaderCheckout
