import React from "react"

import Icon, { IconProps } from "./"

const TwitterIcon: React.FC<IconProps> = ({
  stroke = "none",
  ...restProps
}) => {
  return (
    <Icon {...restProps} stroke={stroke}>
      <path
        d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11z"
        stroke="currentColor"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11.849 9.895l.023.39-.38-.049c-1.38-.182-2.587-.802-3.611-1.843l-.5-.516-.13.381c-.273.85-.098 1.748.47 2.352.304.334.236.381-.288.183-.182-.064-.341-.112-.356-.088-.053.056.129.779.273 1.065.197.397.6.786 1.04 1.017l.371.182-.44.008c-.425 0-.44.008-.395.175.152.517.752 1.065 1.42 1.303l.47.167-.41.254a4.156 4.156 0 0 1-2.033.588c-.342.008-.623.04-.623.063 0 .08.926.525 1.465.7 1.616.516 3.535.293 4.977-.588 1.024-.628 2.048-1.875 2.526-3.083.258-.643.516-1.819.516-2.383 0-.366.023-.413.448-.85.25-.254.485-.532.53-.612.077-.15.069-.15-.318-.016-.645.239-.736.207-.417-.15.235-.255.516-.716.516-.85 0-.025-.114.015-.243.087a4.14 4.14 0 0 1-.668.27l-.41.135-.371-.262c-.205-.143-.493-.302-.645-.35-.387-.111-.979-.095-1.328.032a2.276 2.276 0 0 0-1.48 2.288s-.067-1.01 0 0z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export default TwitterIcon
