import { BaseContent } from "@ignite/api/contents"
import ContentContainer from "@ignite/react/components/Content/ContentContainer"
import { BlockRenderer } from "@ignite/react/components/Content/ContentRenderer"
import { usePageContext } from "@ignite/react/context/pageContext"
import { useSiteSettingsContext } from "@ignite/react/context/siteSettingsContext"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { useTheme } from "@mui/material/styles"
import OrganicDivider from "components/Divider/OrganicDivider"
import FooterSocial from "components/Footer/FooterSocial"
import ImpersonateUsersDialog from "components/Impersonate/ImpersonateUsersDialog"
import SearchTextField from "components/SearchTextField"
import React from "react"
import { makeStyles } from "tss-react/mui"

import Menu from "./Menu"
import SecondaryFooter from "./SecondaryFooter"

const useStyles = makeStyles()(
  ({ breakpoints, palette, typography, spacing }) => ({
    root: {
      minHeight: "30vh",
    },
    mobileflexCenter: {
      [breakpoints.down("md")]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    lightDividerBg: {
      "&:before": {
        backgroundColor: palette.footer.secondary,
      },
    },
    whiteBg: {
      backgroundColor: palette.common.white,
    },
    secondaryContent: {
      "& h4": {
        marginTop: spacing(2),
        fontWeight: 700,
        fontSize: 18,
      },
    },
    content: {
      //prevent override from richtext block
      "& > div": {
        maxWidth: "480px !important",
        paddingTop: "0px !important",
      },
      "& h3": {
        marginTop: spacing(2),
      },
      "& a, & a:hover": {
        color: `${palette.getContrastText(palette.footer.primary)} !important`,
      },
      [breakpoints.down("md")]: {
        "& > div p": {
          "&:nth-of-type(1)": {
            ...typography["secondary-200"],
          },
          "&:nth-of-type(4)": {
            "& small": {
              flex: "50%",
              textAlign: "right",
            },
            "& strong": {
              flex: "50%",
            },
          },
        },
      },
      "& > div p": {
        marginTop: spacing(2),
        marginBottom: 0,
        "& small": {
          fontWeight: "normal",
          ...typography["primary-200"],
        },
        "&:nth-of-type(1)": {
          fontFamily: typography.fontFamilySecondary,
          ...typography["secondary-300"],
          marginBottom: spacing(6),
        },
        "&:nth-of-type(4)": {
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "row wrap",
          lineHeight: "30px",
          alignItems: "baseline",
          "& small": {
            display: "block",
            flex: "60%",
            wordBreak: "break-all",
          },
          "& strong": {
            display: "block",
            flex: "40%",
          },
          "& br": {
            display: "none",
          },
        },
      },
    },
  })
)

const Footer: React.FC = () => {
  const theme = useTheme()
  const { classes, cx } = useStyles()
  const {
    state: { siteSettings },
  } = useSiteSettingsContext()

  const footerContent = siteSettings?.data?.properties.footerContent
  const secondaryContent = siteSettings?.data?.properties[
    "secondaryFooterContent"
  ] as BaseContent[]

  const {
    state: {
      page: { data },
    },
  } = usePageContext()

  return (
    <>
      <Box
        component="footer"
        className={cx({
          [classes.whiteBg]:
            data?.type.toLocaleLowerCase() === "journalpage" ||
            data?.type.toLowerCase() === "contentpage",
        })}
      >
        <OrganicDivider
          variant="footer"
          className={cx({
            [classes.lightDividerBg]:
              data?.type.toLowerCase() === "startpage" ||
              data?.type.toLowerCase() === "igniteproduct" ||
              data?.type.toLowerCase() === "standardpage",
          })}
        />
        <ContentContainer
          className={classes.root}
          bgcolor={theme.palette.footer.primary}
          color={theme.palette.getContrastText(theme.palette.footer.primary)}
          containerProps={{
            color: theme.palette.getContrastText(theme.palette.footer.primary),
          }}
        >
          <Grid container>
            <Grid item md={4} xs={12}>
              <Box className={classes.content} maxWidth={480}>
                {footerContent && <BlockRenderer content={footerContent} />}
              </Box>
            </Grid>
            <Grid item md={5} xs={12}>
              <Menu />
            </Grid>
            <Grid item md={3} xs={12}>
              <Box className={classes.secondaryContent} maxWidth={480}>
                {secondaryContent && (
                  <BlockRenderer content={secondaryContent} />
                )}
              </Box>
            </Grid>
            {siteSettings?.data?.properties?.socialIcons && (
              <Grid item xs={12} className={classes.mobileflexCenter}>
                <FooterSocial
                  items={siteSettings?.data?.properties?.socialIcons}
                />
              </Grid>
            )}
          </Grid>
        </ContentContainer>
        <SecondaryFooter
          copyrightNotice={siteSettings?.data?.properties?.copyrightNotice}
          icons={siteSettings?.data?.properties?.footerIcons}
        />
      </Box>
      <ImpersonateUsersDialog searchComponent={SearchTextField} />
    </>
  )
}
export default Footer
