import { BASE_URL } from "@ignite/api/request"
import emitEvent from "@ignite/utils/eventEmitter"
import Text from "components/Text"
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
} from "react"
import { useNavigate } from "react-router-dom"

import { isLocalHost } from "../@ignite/utils/location"

export const toRelativeLink = (source: string) => {
  if (!source) {
    return ""
  }
  if (source.startsWith("/")) {
    return source
  }
  const url = new URL(source)
  const apiRootUrl = new URL(BASE_URL!)
  const windowHostname = window.location.hostname
  const urlHostname = url.hostname
  const isSourceLocalHost = isLocalHost()
  if (
    urlHostname === windowHostname ||
    (isSourceLocalHost &&
      (urlHostname.endsWith("episerver.net") ||
        urlHostname.endsWith(apiRootUrl.hostname)))
  ) {
    return url.pathname + url.searchParams
  }

  return source
}

type Props = {
  href: string
  rel?: string
  event?: string
  children?: React.ReactNode
}

const useRelativeLinkClickListener = (
  href: string,
  onClick?: (...args: any) => void,
  event?: string
) => {
  const target = useMemo(() => toRelativeLink(href), [href])
  const navigate = useNavigate()
  const handleClicked = useCallback(
    (e: any) => {
      if (onClick) {
        onClick(e)
      }
      if (event) {
        emitEvent(event, { link: target })
      }
      e.preventDefault()
      navigate(target)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [event, onClick, target]
  )

  return handleClicked
}

export type RelativeLinkProps<T = JSX.IntrinsicElements["a"]> = T extends
  | React.ElementType<infer P>
  | React.ComponentType<infer P>
  ? Props & {
      component?: T
    } & P
  : Props

const RelativeLink: <T = typeof Text>(
  props: PropsWithChildren<RelativeLinkProps<T>>
) => ReactElement<any, any> | null = React.forwardRef<
  HTMLElement,
  RelativeLinkProps
>(({ href, event, children, ...props }, ref) => {
  const { onClick, component = Text } = props as any

  const handleClicked = useRelativeLinkClickListener(href, onClick, event)

  const defaultProps = {
    ...props,
    ref,
    href,
    children,
    onClick: handleClicked,
    component: "a",
  }

  if (typeof component === "string") {
    return React.createElement(component, defaultProps)
  }
  const Component = component as any
  return <Component {...defaultProps} />
}) as any

export default RelativeLink
