import { useMenuContext } from "@ignite/react/context/menuContext"
import Box from "@mui/material/Box"
import Text from "components/Text"
import React from "react"
import { makeStyles } from "tss-react/mui"

import MobileMenuToolbar from "./MobileMenuToolbar"

type MobileMenuContentProps = {
  title?: string
  onBackClicked?: () => void
  className?: string
  toolbarContent?: React.ReactNode
  children?: React.ReactNode
}

const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
  },
}))

const MobileMenuPanel: React.FC<MobileMenuContentProps> = ({
  title,
  onBackClicked,
  className,
  toolbarContent = <Box flexGrow={1} />,
  children,
}) => {
  const { classes, cx } = useStyles()

  const {
    actions: { toggle },
  } = useMenuContext()

  return (
    <div className={cx(classes.root, className)}>
      <MobileMenuToolbar
        isPanel
        toolbarContent={toolbarContent}
        onBackClicked={onBackClicked}
        onClose={toggle}
      />
      {title && (
        <Box p={4} pt={8} pb={0}>
          <Text variant="primary-400" bold>
            {title}
          </Text>
        </Box>
      )}
      {children}
    </div>
  )
}

export default MobileMenuPanel
