import { MenuResponse } from "@ignite/api/menus"
import { useMenuContext } from "@ignite/react/context/menuContext"
import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import ListItemText from "@mui/material/ListItemText"
import Toolbar from "@mui/material/Toolbar"
import DrawerContainer from "components/DrawerContainer"
import ChevronIcon from "components/Icons/ChevronIcon"
import RelativeLink from "components/RelativeLink"
import Text from "components/Text"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "tss-react/mui"

type DrawerMenuProps = Readonly<{
  open: boolean
  setOpen: (value: boolean) => void
}>
export default function BusinessDrawerMenu({ open, setOpen }: DrawerMenuProps) {
  const {
    state: {
      menus: { data: menus },
    },
  } = useMenuContext()
  const mypagesMenu = useMemo(
    () => menus?.find((menu) => menu.id === "mypages"),
    [menus]
  )
  const customerServiceMenu = useMemo(
    () => menus?.find((menu) => menu.id === "customerservice"),
    [menus]
  )
  const mainMenu = useMemo(
    () => menus?.find((menu) => menu.id === "main"),
    [menus]
  )
  const anchorValue = "right"
  const { t } = useTranslation()

  return (
    <Drawer anchor={anchorValue} open={open} onClose={() => setOpen(false)}>
      <DrawerContainer anchor={anchorValue}>
        <Toolbar>
          <Box
            width={1.0}
            alignItems="center"
            display="flex"
            justifyContent="flex-end"
          >
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              color="inherit"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>

        {mypagesMenu && (
          <MenuList
            mt={0}
            menu={mypagesMenu}
            headline={t("retailer_portal.my_profile_menu_heading")}
            setOpen={setOpen}
          />
        )}

        {customerServiceMenu && (
          <MenuList
            mt={12}
            menu={customerServiceMenu}
            headline={t("retailer_portal.customer_service_menu_heading")}
            setOpen={setOpen}
          />
        )}

        {mainMenu && (
          <MenuList
            mt={12}
            menu={mainMenu}
            headline={t("retailer_portal.nelson_garden_menu_heading")}
            setOpen={setOpen}
          />
        )}
      </DrawerContainer>
    </Drawer>
  )
}
const useStyles = makeStyles()(({ spacing, typography }) => ({
  list: {
    padding: spacing(0, 4, 4, 4),
  },
  listItem: {
    padding: spacing(3, 0, 3, 0),
  },
  listItemTitle: {
    "& .MuiTypography-root": {
      ...typography["primary-400"],
      fontWeight: typography.fontWeightBold,
    },
  },
  listHeading: {
    marginLeft: spacing(4),
    marginBottom: spacing(1),
    ...typography["primary-450"],
    fontWeight: typography.fontWeightBold,
  },
  iconContainer: {
    right: 0,
  },
}))

type MenuListProps = {
  menu: MenuResponse
  setOpen?: (value: boolean) => void
  toggle?: () => void
  headline: string
  mt: number
}

export function MenuList({
  menu,
  setOpen,
  headline,
  mt,
  toggle,
}: MenuListProps) {
  const { classes } = useStyles()

  return (
    <Box mt={mt}>
      <Text className={classes.listHeading} component="h2">
        {headline}
      </Text>
      <List className={classes.list}>
        {menu?.children.map((menu: MenuResponse, i: number) => (
          <div key={`${menu.id}-${i}`}>
            {menu.url.startsWith("http") ? (
              <a href={menu.url} target="_blank" rel="noopener noreferrer">
                <Item
                  id={menu.id}
                  title={menu.title}
                  setOpen={setOpen && setOpen}
                  toggle={toggle && toggle}
                />
              </a>
            ) : (
              <RelativeLink href={menu.url}>
                <Item
                  id={menu.id}
                  title={menu.title}
                  setOpen={setOpen && setOpen}
                  toggle={toggle && toggle}
                />
              </RelativeLink>
            )}

            <Divider component="li" />
          </div>
        ))}
      </List>
    </Box>
  )
}

type ItemProps = {
  id: string
  title: string
  setOpen?: (value: boolean) => void
  toggle?: () => void
}

function Item({ id, title, setOpen, toggle }: ItemProps) {
  const { classes } = useStyles()

  return (
    <ListItem
      className={classes.listItem}
      disableGutters
      key={id}
      onClick={() => (setOpen ? setOpen(false) : toggle && toggle())}
    >
      <ListItemText className={classes.listItemTitle}>{title}</ListItemText>
      <ListItemSecondaryAction className={classes.iconContainer}>
        <ChevronIcon color="primary" size="sm" direction="right" />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
