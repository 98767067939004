import { useMenuContext } from "@ignite/react/context/menuContext"
import List from "@mui/material/List"
import { styled } from "@mui/material/styles"
import RelativeLink from "components/RelativeLink"
import Text from "components/Text"
import React, { useMemo } from "react"

type MenuItemProps = {
  url: string
  children?: React.ReactNode
}

const StyledText = styled(Text)(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: "inline-block",
}))

const MenuItem: React.FC<MenuItemProps> = ({ url, children }) => (
  <StyledText color="inherit" component="li">
    <RelativeLink href={url}>{children}</RelativeLink>
  </StyledText>
)

type TopMenuProps = {
  MenuItemComponent?: typeof MenuItem
  MenuItemProps?: typeof MenuItem.defaultProps
}

const TopMenu: React.FC<TopMenuProps> = ({
  MenuItemComponent = MenuItem,
  MenuItemProps,
}) => {
  const {
    state: {
      menus: { data: menus },
    },
  } = useMenuContext()

  const topMenu = useMemo(
    () => menus?.find((menu) => menu.id === "top"),
    [menus]
  )

  return (
    (topMenu && topMenu.children.length > 0 && (
      <List>
        {topMenu.children.map((item, i) => (
          <MenuItemComponent key={i} url={item.url} {...MenuItemProps}>
            {item.title}
          </MenuItemComponent>
        ))}
      </List>
    )) ||
    (null as any)
  )
}

export default TopMenu
