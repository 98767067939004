import { Properties } from "."
import { CartName, CartResponse } from "./cart"
import request from "./request"

export type CheckoutRequestModel = {
  deliveryOptionId: string
  paymentOptionId: string
  deliveryAddress: Address
  billingAddress?: Address
  properties: any
}

export type CheckoutResponseModel = {
  cart: CartResponse
  currency: string
  deliveryOptionId: string
  paymentOptionId: string
  paymentMethodName: string
  deliveryAddress: Address
  billingAddress: Address
  availableDeliveryCountries: CountryOption[]
  deliveryOptions: DeliveryOption[]
  paymentOptions: PaymentOption[]
  couponCodes: string[]
  validationSummary: string
  properties: any
  isValidForCompletion: boolean
}

export type OrderResponseModel = {
  order_id: string
  order_group_id: number
  created_date: string
  first_name: string
  last_name: string
  billing_address?: OrderAddress
  delivery_address: OrderAddress
  items: OrderLineItem[]
  tracking_url?: string
  delivery_method: string
  payment_method: string
  payment_method_name: string
  language: string
  delivery_date: string
  currency: string
  discount_amount_string?: string
  order_discount_total_string?: string
  sub_total_string: string
  shipping_total_string: string
  tax_total_string: string
  total_string: string
  vat_number?: string
  discount_amount: number
  shipping_total: number
  sub_total: number
  tax_total: number
  total: number
  email: string
  phone: string
}

type OrderAddress = {
  address_line_1: string
  address_line_2?: string
  postal_code: string
  state_province_region?: string
  city: string
  country: string
}

export type OrderLineItem = {
  code: string
  display_name: string
  short_description: string
  image: string
  discount_total_string: string
  price_string: string
  total_price_string: string
  total_excluding_discount_string: string
  quantity: number
  is_gift: boolean
  total_price: number
  discount_total: number
  price: number
}

export type Organization = {
  addresses?: Address[]
  name?: string
  primaryKeyId?: string
  properties?: Properties
}

export type Address = {
  isDefault?: boolean
  billingDefault?: boolean
  shippingDefault?: boolean
  addressId?: string
  name?: string
  addressType?: number[]
  isBillingAddress?: boolean
  isShippingAddress?: boolean
  firstName: string
  lastName: string
  line1: string
  line2?: string
  city: string
  countryCode: string
  twoLetterCountryCode: string
  countryName?: string
  zipCode: string
  postalCode?: string
  regionCode?: string
  state?: string
  daytimePhoneNumber?: string
  eveningPhoneNumber?: string
  email: string
  organization?: string | Organization // If market is B2B then this is required (VAT Nummer)
  properties?: Properties
}

type CountryOption = {
  id: number
  name: string
  code: string
  isSelected: boolean
  states: Record<string, string>
}

/* Not to be confused with options from e.g Unifaun or Ingrid */
type DeliveryOption = {
  methodId: string
  displayName: string
  basePrice: number
  sortOrder: number
  isDefault: boolean
  allowedCountries: CountryOption[]
}

/* Not to be confused with options from e.g Adyen */
type PaymentOption = {
  id: string
  name: string
  sortOrder: number
  keyword: string
}

type CheckoutApi = {
  getCheckout: (name: CartName) => Promise<CheckoutResponseModel>
  updateCheckout: (
    name: CartName,
    data: CheckoutRequestModel
  ) => Promise<CheckoutResponseModel>

  addCoupon: (name: CartName, code: string) => Promise<CheckoutResponseModel>
  removeCoupon: (name: CartName, code: string) => Promise<CheckoutResponseModel>
  getOrderConfirmation: (ordernumber: string) => Promise<OrderResponseModel>
  complete: (name: CartName) => Promise<CheckoutResponseModel>
}

const checkoutApi: CheckoutApi = {
  getCheckout: (name) =>
    request.get<CheckoutResponseModel>(`/carts/${name}/checkout`),
  updateCheckout: (name, data) =>
    request.post<CheckoutResponseModel>(`/carts/${name}/checkout`, data),
  addCoupon: (name, code) =>
    request.post<CheckoutResponseModel>(`/carts/${name}/coupon/${code}`),
  removeCoupon: (name, code) =>
    request.delete<CheckoutResponseModel>(`/carts/${name}/coupon/${code}`),
  getOrderConfirmation: (ordernumber: string) =>
    request.get<OrderResponseModel>(`/orderconfirmation/${ordernumber}`),
  complete: (name) =>
    request.post<CheckoutResponseModel>(`/carts/${name}/checkout/complete`),
}

export default checkoutApi
