import "i18n"

import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import { IgniteApi } from "@ignite/api"
import CssBaseline from "@mui/material/CssBaseline"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import ApplicationInsightsTracker from "components/ApplicationInsightsTracker"
import DataLayerPublisher from "components/DataLayerPublisher"
import GoogleAnalyticsTracker from "components/GoogleAnalyticsTracker"
import Prerender from "components/Prerender"
import Spinner from "components/Spinner"
import React, { Suspense } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { Route, Routes } from "react-router-dom"
import theme from "theme"

import CustomRouter from "../components/Route/CustomRouter"
import ApiContextContainer from "../context/ApiContextContainer"
import GlobalErrorBoundary from "./GlobalErrorBoundary"
import GlobalStateContainer from "./GlobalStateContainer"
import InitialLoader from "./InitialLoader"

type AppProps = {
  /**
   * Component to show image when error occurs inside react
   */
  errorImage?: React.ReactElement
  /**
   * Children passed to Helmet
   */
  headChildren?: React.ReactNode
  /**
   * Override the default API
   */
  api?: IgniteApi
  /**
   * Array of css urls which are injected in head
   */
  styleSheets?: string[]
  children?: React.ReactNode
}

const muiCache = createCache({
  key: "mui",
})

const GlobalStyles: React.FC<Pick<AppProps, "styleSheets" | "children">> = ({
  children,
  styleSheets = [],
}) => {
  return (
    <Helmet>
      {children}
      {styleSheets.map((url, i) => (
        <link key={i} rel="stylesheet" href={url} />
      ))}
    </Helmet>
  )
}

const App: React.FC<AppProps> = ({
  errorImage,
  api,
  headChildren,
  children,
  styleSheets = ["https://use.typekit.net/vup5jgz.css"],
}) => {
  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <GlobalStyles styleSheets={styleSheets}>
              {headChildren}
            </GlobalStyles>
            <GlobalErrorBoundary errorImage={errorImage}>
              <CssBaseline />
              <Suspense fallback={<Spinner />}>
                <ApiContextContainer api={api}>
                  <GlobalStateContainer>
                    <CustomRouter>
                      <Prerender />
                      <DataLayerPublisher />
                      <GoogleAnalyticsTracker />
                      <ApplicationInsightsTracker />
                      <InitialLoader />
                      <Routes>
                        <Route path="*" element={children} />
                      </Routes>
                    </CustomRouter>
                  </GlobalStateContainer>
                </ApiContextContainer>
              </Suspense>
            </GlobalErrorBoundary>
          </HelmetProvider>
        </ThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  )
}

export default App
