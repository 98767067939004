import React from "react"

import Icon, { IconDirection, IconProps } from "./"

const ChevronIcon: React.FC<IconProps & { direction: IconDirection }> = ({
  direction = "down",
  ...restProps
}) => {
  return (
    <Icon
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
      {...restProps}
    >
      {direction === "down" && (
        <path vectorEffect="non-scaling-stroke" d="M6 9l6 6 6-6" />
      )}
      {direction === "up" && (
        <path vectorEffect="non-scaling-stroke" d="M18 15l-6-6-6 6" />
      )}
      {direction === "left" && (
        <path vectorEffect="non-scaling-stroke" d="M15 18l-6-6 6-6" />
      )}
      {direction === "right" && (
        <path vectorEffect="non-scaling-stroke" d="M9 18l6-6-6-6" />
      )}
    </Icon>
  )
}

export default ChevronIcon
