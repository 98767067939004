import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
} from "react"

import { ReducerFunctions, StateContextCreator } from "."

type StateProviderProps<T = any> = {
  stateContextName: string
  stateContextCreator: StateContextCreator<T>
  stateContextCreators: Record<string, StateContextCreator<T>>
  index: number
  keys: string[]
  children?: React.ReactNode
}

type Props<
  S extends R[keyof R]["state"],
  R extends Record<string, StateContextCreator<any>>,
> = {
  sharedState?: S
  stateContextCreators: R
}

const asyncActionCreator =
  (
    name: string,
    key: string | number | symbol,
    promise: (...args: any) => Promise<any>,
    throwError = false,
    setValue: React.Dispatch<React.SetStateAction<any>>,
    stateRef: { state: any },
    getCurrentState: () => any
  ) =>
  async (...args: any) => {
    let state = getCurrentState()
    let asyncStateObj = state[key]

    if (typeof asyncStateObj === "object") {
      delete asyncStateObj.error
      asyncStateObj.loading = true
      const loadingState = { ...state, [key]: asyncStateObj }
      stateRef.state = loadingState
      setValue(loadingState)
    }
    if ((window as any)["CONTEXT_DEBUG"]) {
      console.log({
        name,
        timeStamp: new Date().getTime(),
        key,
        step: "before-async",
        state: getCurrentState(),
      })
    }

    try {
      const data = await promise(...args)
      if (typeof asyncStateObj === "object") {
        asyncStateObj = {
          data,
          loading: false,
        }
      } else {
        asyncStateObj = data
      }
    } catch (error) {
      if (typeof asyncStateObj === "object") {
        asyncStateObj.loading = false
        asyncStateObj.error = error
      }
      if (throwError) {
        throw error
      }
    }

    state = getCurrentState() as any
    const doneState = { ...state, [key]: asyncStateObj }
    stateRef.state = doneState
    setValue(doneState)
    if ((window as any)["CONTEXT_DEBUG"]) {
      console.log({
        name,
        timeStamp: new Date().getTime(),
        key,
        step: "after-async",
        state: getCurrentState(),
      })
    }
    return doneState
  }

const useMapActions = (
  name: string,
  initState: any,
  reducers: ReducerFunctions<any>,
  setValue: React.Dispatch<React.SetStateAction<any>>
) => {
  const stateRef = useMemo(() => ({ state: initState }), [initState])
  const getCurrentState = useCallback(() => stateRef.state, [stateRef])

  const useAsyncAction = (
    key: string | number | symbol,
    promise: (...args: any) => Promise<any>,
    { throwError = false, deps = [] } = {} as any
  ) =>
    useMemo(
      () =>
        asyncActionCreator(
          name,
          key,
          promise,
          throwError,
          setValue,
          stateRef,
          getCurrentState
        ) as any,
      // we dont want to create a new function if the promise changes, lets keep it static
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [key, throwError, ...deps]
    )

  const actions = useMemo(
    () =>
      Object.entries(reducers).reduce((acc, [key, reducer]) => {
        acc[key] = (payload: any) => {
          if ((window as any)["CONTEXT_DEBUG"]) {
            console.log({
              name,
              timeStamp: new Date().getTime(),
              key,
              step: "before",
              state: getCurrentState(),
            })
          }
          const newState = reducer(getCurrentState(), payload)
          if ((window as any)["CONTEXT_DEBUG"]) {
            console.log({
              name,
              timeStamp: new Date().getTime(),
              key,
              step: "after",
              state: getCurrentState(),
            })
          }
          stateRef.state = newState
          setValue(newState)
        }
        acc["reset"] = (key?: PropertyKey) => {
          const keyType = typeof key
          if (
            key &&
            (keyType === "string" ||
              keyType === "symbol" ||
              keyType === "number")
          ) {
            setValue((currentState: any) => {
              const newState = {
                ...currentState,
                [key]: initState[key],
              }
              stateRef.state = newState
              return newState
            })
          } else {
            setValue(initState)
            stateRef.state = initState
          }
        }
        acc["set"] = (key: any, value?: any) => {
          if (key) {
            setValue((currentState: any) => {
              const newState = {
                ...currentState,
                [key]: value,
              }
              stateRef.state = newState
              return newState
            })
          }
        }
        return acc
      }, {} as any),
    [getCurrentState, initState, name, reducers, setValue, stateRef]
  )
  return { actions, useAsyncAction }
}

const StateProvider: React.FC<StateProviderProps> = ({
  stateContextName,
  children,
  stateContextCreator: {
    state,
    reducers,
    context: { Provider },
  },
  stateContextCreators,
  keys,
  index,
}) => {
  const [value, setValue] = React.useState(state)
  const { actions, useAsyncAction } = useMapActions(
    stateContextName,
    state,
    reducers,
    setValue
  )

  const newIndex = index + 1

  const key = keys[newIndex]
  const childContextCreator = stateContextCreators[key]
  const name = contextNameFromKey(key)

  const providerValue = useMemo(
    () => ({
      state: value,
      setState: (newState: any) => setValue(newState),
      actions,
      useAsyncAction,
    }),
    [value, setValue, actions, useAsyncAction]
  )

  return (
    <Provider value={providerValue}>
      {(childContextCreator && (
        <StateProvider
          stateContextName={name}
          stateContextCreator={childContextCreator}
          stateContextCreators={stateContextCreators}
          index={newIndex}
          keys={keys}
        >
          {children}
        </StateProvider>
      )) ||
        children}
    </Provider>
  )
}

const contextNameFromKey = (key: string) => {
  if (!key) {
    return key
  }
  if (key.endsWith("Creator")) {
    return key.substring(0, key.length - "Creator".length)
  }
  return key
}

const GlobalStateProvider: <
  S extends Partial<R[keyof R]["state"]>,
  R extends Record<string, StateContextCreator<any>>,
>(
  props: PropsWithChildren<Props<S, R>>
) => ReactElement<any, any> | null = ({
  children,
  stateContextCreators,
  sharedState,
}) => {
  const keys = useMemo(() => {
    const contextCreatorKeys = Object.keys(stateContextCreators)
    if (sharedState) {
      contextCreatorKeys.forEach((contextCreatorKey) => {
        const state = stateContextCreators[contextCreatorKey].state
        if (typeof state === "object") {
          Object.entries(state).forEach(([key]) => {
            if (sharedState[key]) {
              state[key] = sharedState[key]
            }
          })
        }
      })
    }

    return contextCreatorKeys
  }, [stateContextCreators, sharedState])

  const key = keys[0]
  const childContextCreator = stateContextCreators[key]
  const name = contextNameFromKey(key)

  return (
    (keys.length > 0 && (
      <StateProvider
        stateContextCreator={childContextCreator}
        stateContextName={name}
        stateContextCreators={stateContextCreators}
        keys={keys}
        index={0}
      >
        {children}
      </StateProvider>
    )) ||
    children ||
    (null as any)
  )
}

export default GlobalStateProvider
