import Box, { BoxProps } from "@mui/material/Box"
import ButtonLink from "components/ButtonLink"
import React from "react"

type CartCheckoutButtonProps = BoxProps & {
  buttonText: string
  link: string
  disabled?: boolean
  onCheckout?: () => void
}

const CartCheckoutButton: React.FC<CartCheckoutButtonProps> = ({
  buttonText,
  link,
  disabled,
  onCheckout,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      <ButtonLink
        onClick={onCheckout}
        disabled={disabled}
        fullWidth
        link={link}
      >
        {buttonText}
      </ButtonLink>
    </Box>
  )
}

export default CartCheckoutButton
