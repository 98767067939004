import React from "react"

import Icon, { IconHorizontalDirection, IconProps } from "./"

const SecondaryArrowIcon: React.FC<
  IconProps & { direction: IconHorizontalDirection }
> = ({ strokeWidth = 1.5, direction = "left", ...restProps }) => {
  return (
    <Icon strokeLinejoin="round" strokeLinecap="round" {...restProps}>
      {direction === "left" && (
        <path
          strokeWidth={strokeWidth}
          d="M19 12H5M12 19l-7-7 7-7"
          vectorEffect="non-scaling-stroke"
        />
      )}
      {direction === "right" && (
        <path
          strokeWidth={strokeWidth}
          d="M5 12h14M12 5l7 7-7 7"
          vectorEffect="non-scaling-stroke"
        />
      )}
    </Icon>
  )
}

export default SecondaryArrowIcon
