import Button, { ButtonProps } from "components/Button"
import { SmallSpinner } from "components/Spinner"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    maxHeight: spacing(13),
  },
}))

type LoadingButtonProps = ButtonProps & {
  loadingText?: string
  loading?: boolean
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loadingText,
  onClick,
  loading,
  children,
  disabled,
  ...restProps
}) => {
  const { classes } = useStyles()
  const loadingTextValue = loadingText || children

  return (
    <Button
      onClick={onClick}
      variant="contained"
      className={classes.root}
      disabled={disabled}
      {...restProps}
    >
      {(loading && (
        <SmallSpinner light={disabled} message={loadingTextValue} />
      )) ||
        children}
    </Button>
  )
}

export default LoadingButton
