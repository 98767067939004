import Input from "@mui/material/Input"
import InputAdornment from "@mui/material/InputAdornment"
import Button from "components/Button"
import SearchIcon from "components/Icons/SearchIcon"
import { SmallSpinner } from "components/Spinner"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ palette }) => ({
  searchButton: {
    borderRadius: 0,
    maxWidth: "72px",
  },
  searchInput: {
    border: "none",
  },
  searchRoot: {
    maxHeight: "48px",
    overflow: "hidden",
    borderTop: `solid 1px ${palette.ui.main}`,
    borderLeft: `solid 1px ${palette.ui.main}`,
    borderRight: `solid 1px ${palette.ui.main}`,
  },
}))

const DefaultLoadingButtonIconComponent = SmallSpinner
const DefaultButtonIconComponent = SearchIcon

type ImpersonateSearchFieldProps = {
  loading?: boolean
  inputRef?: React.Ref<HTMLInputElement | undefined>
  placeholder?: string
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  LoadingButtonIconComponent?: typeof DefaultLoadingButtonIconComponent
  ButtonIconComponent?: typeof DefaultButtonIconComponent
}

const SearchTextField: React.FC<ImpersonateSearchFieldProps> = ({
  onChange,
  onClick,
  inputRef,
  loading,
  placeholder,
  ButtonIconComponent = DefaultButtonIconComponent,
  LoadingButtonIconComponent = DefaultLoadingButtonIconComponent,
}) => {
  const { classes } = useStyles()
  return (
    <Input
      inputRef={inputRef}
      fullWidth
      placeholder={placeholder}
      autoFocus
      type="search"
      classes={{
        input: classes.searchInput,
        root: classes.searchRoot,
      }}
      endAdornment={
        <InputAdornment position="end">
          <Button className={classes.searchButton} onClick={onClick}>
            {loading ? <LoadingButtonIconComponent /> : <ButtonIconComponent />}
          </Button>
        </InputAdornment>
      }
      onChange={onChange}
    />
  )
}

export default SearchTextField
