import { ConnectTokenResponse } from "@ignite/api/connect"
import { TokenResponse, UserResponse } from "@ignite/api/users"

import { asyncState, createStateContext } from "."

const [useAuthContext, authContextCreator] = createStateContext(
  {
    user: asyncState<UserResponse>(),
    tokenResponse: asyncState<TokenResponse | ConnectTokenResponse>(),
  },
  {}
)

export { authContextCreator, useAuthContext }
