import React from "react"

import Icon, { IconProps } from "./"

const AddIcon: React.FC<IconProps> = ({ ...restProps }) => (
  <Icon {...restProps} stroke="none">
    <path
      vectorEffect="non-scaling-stroke"
      d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
      fill="currentColor"
    />
  </Icon>
)

export default AddIcon
