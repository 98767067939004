import { useApiContext } from "@ignite/react/context/ApiContextContainer"
import { useAuthContext } from "@ignite/react/context/authContext"
import { useSiteSettingsContext } from "@ignite/react/context/siteSettingsContext"
import { useUserContext } from "@ignite/react/context/userContext"
import useValidationSchema from "@ignite/react/hooks/useValidationSchema"
import useSystemLinks from "@ignite/utils/systemLinksUtil"
import Box from "@mui/material/Box"
import FormHelperText from "@mui/material/FormHelperText"
import DrawerMenu from "components/BusinessDrawerMenu"
import Button from "components/Button"
import Dialog from "components/Dialog"
import FormikTextField from "components/Forms/FormikTextField"
import TopSectionButton from "components/Header/TopSectionButton"
import Text from "components/Text"
import { Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { makeStyles } from "tss-react/mui"
import * as Yup from "yup"

import ForgotPasswordForm from "./ForgotPasswordForm"
const useStyles = makeStyles()(({ spacing }) => ({
  link: {
    cursor: "pointer",
    textDecoration: "underline",
    textUnderlineOffset: spacing(0.3),
    wordBreak: "break-all",
    textAlign: "right",
    marginTop: spacing(1),
  },
  input: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "black",
    },
  },
  authBtn: {
    "& .MuiSvgIcon-root": {
      width: "12px",
      height: "12px",
    },
  },
}))

type FormValues = {
  username: string
  password: string
}

const AuthSection: React.FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [authDialogOpen, setAuthDialogOpen] = useState(false)
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false)

  const [errorMessageState, setErrorMessageState] = useState<string | null>()

  const api = useApiContext()
  const {
    state: { user },
    useAsyncAction,
  } = useAuthContext()

  const {
    state: { siteSettings },
  } = useSiteSettingsContext()

  const {
    state: {
      user: { data: userMe },
    },
    useAsyncAction: useUserAsyncAction,
  } = useUserContext()

  const initialValues: FormValues = {
    username: "",
    password: "",
  }

  const links = useSystemLinks()
  const navigate = useNavigate()

  const getMe = useUserAsyncAction("user", async () => {
    try {
      const userResponse = await api.users.getMe()
      return userResponse
    } catch (err) {
      console.error(err)
      return null
    }
  })

  const getUser = useAsyncAction("user", async (userName: string) => {
    try {
      const userResponse = await api.users.getUser(userName)
      await getMe()
      return userResponse
    } catch (err) {
      console.error(err)
      return null
    }
  })

  const handleSubmit = useAsyncAction(
    "tokenResponse",
    async (values: FormValues, actions: any) => {
      try {
        const tokenResponse = await api.users.login(
          values.username,
          values.password
        )
        await getUser(values.username)
        actions.setSubmitting(false)
        setErrorMessageState(null)
        return tokenResponse
      } catch (err) {
        setErrorMessageState(t("login.form_text_generic_error"))
        return null
      }
    }
  )

  const onAuthButtonClick = () => {
    if (!userMe) {
      setAuthDialogOpen(true)
    } else {
      setOpen(true)
    }
  }

  useEffect(() => {
    if (user && user.data && authDialogOpen) {
      setAuthDialogOpen(false)

      navigate(links["businessOverview"])
      window.location.reload()
    }
  }, [authDialogOpen, user, links, navigate])

  const validationSchema = useValidationSchema(() => {
    return Yup.object().shape({
      username: Yup.string().label("Username").required(),
      password: Yup.string().label("Password").required(),
    })
  })

  const [open, setOpen] = useState(false)

  return (
    <>
      {!siteSettings?.data?.properties["resellerPortalDisabled"] && (
        <Box className={classes.authBtn}>
          <TopSectionButton onClick={onAuthButtonClick}>
            {userMe
              ? t("retailer_portal.auth_my_profile_label")
              : t("login.auth_cta_label")}
          </TopSectionButton>
        </Box>
      )}

      <DrawerMenu open={open} setOpen={setOpen} />

      <Dialog
        open={authDialogOpen}
        onClose={() => setAuthDialogOpen(false)}
        title={
          forgotPasswordOpen
            ? t("login.forgot_password_dialog_heading")
            : t("login.dialog_heading")
        }
      >
        {forgotPasswordOpen ? (
          <ForgotPasswordForm onSetForgotPasswordOpen={setForgotPasswordOpen} />
        ) : (
          <>
            <Text paragraph>{t("login.description")}</Text>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <Box mt={6}>
                    <FormikTextField
                      className={classes.input}
                      label={t("login.username")}
                      name="username"
                      id="username"
                    />
                  </Box>

                  <Box mt={6}>
                    <FormikTextField
                      className={classes.input}
                      label={t("login.password")}
                      name="password"
                      id="password"
                      type="password"
                    />
                    <Text
                      component="p"
                      variant="secondary-100"
                      className={classes.link}
                      onClick={() => {
                        setForgotPasswordOpen(true)
                      }}
                    >
                      {t("login.forgot_password")}
                    </Text>
                  </Box>

                  {errorMessageState && (
                    <FormHelperText error sx={{ mt: 6, textAlign: "center" }}>
                      {errorMessageState}
                    </FormHelperText>
                  )}

                  <Box mt={8} display="flex" justifyContent="center">
                    <Button disabled={isSubmitting || !isValid} type="submit">
                      {t("login.dialog_cta_label")}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Dialog>
    </>
  )
}

export default AuthSection
