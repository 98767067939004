import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

const BASE_URL = window["BASE_URL"] || process.env.REACT_APP_API_ROOT

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        "path",
        "querystring",
        "cookie",
        "htmlTag",
        "localStorage",
        "navigator",
        "subdomain",
      ],
    },
    load: "currentOnly",
    debug: false,
    lowerCaseLng: true,
    supportedLngs: ["en", "de", "sv", "no", "da", "fi", "pl"],
    ns: [""],
    defaultNS: "",
    fallbackNS: "",
    nonExplicitSupportedLngs: true,
    fallbackLng: false,
    interpolation: {
      escapeValue: false,
    },
    missingInterpolationHandler: () => "",
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: `${BASE_URL}/translations/{{ns}}{{lng}}`,
    },
  })

export default i18n
