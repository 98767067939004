import { useMenuContext } from "@ignite/react/context/menuContext"
import Box from "@mui/material/Box"
import { MenuList } from "components/BusinessDrawerMenu"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

type B2bMobileMenuItemsProps = {
  toggle: () => void
}
export default function B2bMobileMenuItems({
  toggle,
}: B2bMobileMenuItemsProps) {
  const {
    state: {
      menus: { data: menus },
    },
  } = useMenuContext()
  const mypagesMenu = useMemo(
    () => menus?.find((menu) => menu.id === "mypages"),
    [menus]
  )
  const customerServiceMenu = useMemo(
    () => menus?.find((menu) => menu.id === "customerservice"),
    [menus]
  )

  const { t } = useTranslation()

  if (!mypagesMenu || !customerServiceMenu) return null
  return (
    <Box>
      {mypagesMenu && (
        <MenuList
          menu={mypagesMenu}
          toggle={toggle}
          headline={t("retailer_portal.my_profile_menu_heading")}
          mt={0}
        />
      )}
      {customerServiceMenu && (
        <MenuList
          menu={customerServiceMenu}
          toggle={toggle}
          headline={t("retailer_portal.customer_service_menu_heading")}
          mt={8}
        />
      )}
    </Box>
  )
}
