import { ContentResponse } from "@ignite/api/contents"
import { AsyncState } from "@ignite/react/context"
import useSystemLinks from "@ignite/utils/systemLinksUtil"
import Box from "@mui/material/Box"
import ChevronIcon from "components/Icons/ChevronIcon"
import MegaMenu from "components/IgniteMegaMenu"
import MobileMenuButton from "components/MobileMenu/MobileMenuButton"
import React from "react"
import { useNavigate } from "react-router-dom"
import { makeStyles } from "tss-react/mui"

import HeaderLogo from "./HeaderLogo"

type BusinessPagesToolbarProps = {
  isCrawler?: boolean
  page: AsyncState<ContentResponse | null>
}

export default function BusinessPagesToolbar({
  isCrawler,
  page,
}: BusinessPagesToolbarProps) {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const links = useSystemLinks()

  if (!page.data?.type) return null

  const currentPage = page.data?.type.toLowerCase()

  const handleNavigate = () => {
    switch (currentPage) {
      case "businesscartpage":
        {
          const url = links["businessOverview"]
          navigate(url)
        }
        break
      case "businesscheckoutpage":
        {
          const url = links["businessCart"]
          navigate(url)
        }
        break
      case "orderhistorypage":
        {
          const url = links["businessOverview"]
          navigate(url)
        }
        break
      case "businesscompanyinformationpage":
        {
          const url = links["businessOverview"]
          navigate(url)
        }
        break
      case "businesscustomerservicepage":
        {
          const url = links["businessOverview"]
          navigate(url)
        }
        break
    }
  }

  return (
    <Box
      width={1.0}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <ChevronIcon
          className={classes.backArrow}
          onClick={handleNavigate}
          direction="left"
        />
      </Box>
      <Box width={70}>
        <HeaderLogo position="absolute" top={0} />
      </Box>
      <Box>
        <MobileMenuButton edge={false} />
      </Box>
      {/* Required for SEO */}
      {isCrawler && (
        <MegaMenu
          className={classes.megamenu}
          display={
            "none" /* Hidden on mobile- & crawler-versions for SEO support */
          }
        />
      )}
    </Box>
  )
}
const useStyles = makeStyles()(({ breakpoints, spacing }) => ({
  backArrow: {
    cursor: "pointer",
  },
  megamenu: {
    whiteSpace: "nowrap",
    [breakpoints.only("md")]: {
      "& > ul": {
        justifyContent: "flex-start",
        "& > li": {
          "& > div": {
            padding: `0 ${spacing(2)}`,
            "& > a": {
              fontSize: "16px",
            },
          },
        },
      },
    },
  },
}))
