import Box from "@mui/material/Box"
import React from "react"
import { makeStyles } from "tss-react/mui"

import FacebookIcon from "../Icons/FacebookIcon"
import InstagramIcon from "../Icons/InstagramIcon"
import LinkedinIcon from "../Icons/LinkedinIcon"
import TwitterIcon from "../Icons/TwitterIcon"
import YoutubeIcon from "../Icons/YoutubeIcon"

const useStyles = makeStyles()(() => ({
  root: {
    "& *": {
      color: "currentColor",
    },
  },
}))

type FooterSocialProps = {
  items: {
    href: string
    text: string
  }[]
}

const getIcon = (key: string) => {
  switch (key.toLocaleLowerCase()) {
    case "instagram":
      return <InstagramIcon size="lg" />
    case "facebook":
      return <FacebookIcon size="lg" />
    case "twitter":
      return <TwitterIcon size="lg" />
    case "youtube":
      return <YoutubeIcon size="lg" />
    case "linkedin":
      return <LinkedinIcon size="lg" />
    default:
      return key
  }
}
const FooterSocial: React.FC<FooterSocialProps> = ({ items }) => {
  const { classes } = useStyles()

  return (
    <Box
      className={classes.root}
      display="flex"
      justifyContent="space-between"
      py={6}
      width={`calc(32px * ${items.length} + (24px * ${items.length - 1}))`}
    >
      {items.map((item, idx) => (
        <a key={idx} href={item.href} target="blank">
          {getIcon(item.text)}
        </a>
      ))}
    </Box>
  )
}

export default FooterSocial
