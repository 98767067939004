import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import React from "react"
import { makeStyles } from "tss-react/mui"

import ExpandIcon from "../Icons/ExpandIcon"
import MinimizeIcon from "../Icons/MinimizeIcon"
import RelativeLink from "../RelativeLink"
import Text from "../Text"
const useStyles = makeStyles()(({ spacing, breakpoints }) => ({
  container: {
    padding: 0,
    margin: 0,
    [breakpoints.down("md")]: {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      borderTop: "solid 1px rgba(255, 255, 255, 0.1)",
      "&:last-of-type": {
        borderBottom: "solid 1px rgba(255, 255, 255, 0.1)",
      },
    },
  },
  item: {
    paddingTop: spacing(1.5),
    paddingBottom: spacing(1.5),
    "& a": {
      textDecoration: "underline",
    },
  },
  flex: {
    display: "flex",
  },
  collapsed: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))
type FooterMenuItemProps = {
  container?: boolean
  item?: boolean
  url: string
  title: string
  isAccordion?: boolean
  children?: React.ReactNode
}
type FooterMenuItemType = {
  title: string
  url: string
  isAccordion?: boolean
  children?: React.ReactNode
}

const Container: React.FC<FooterMenuItemType> = ({
  isAccordion,
  title,
  url,
  children,
}) => {
  const { classes, cx } = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <List role="menu" className={classes.container}>
      <ListItem disableGutters onClick={handleExpandClick}>
        <Text
          component="h4"
          variant="primary-400"
          bold
          className={classes.flex}
        >
          {url && <FooterMenuItemLink url={url} title={title} />}
          {!url && title}
        </Text>
        <Box flexGrow="1" textAlign="right">
          {!expanded && isAccordion && <ExpandIcon />}
          {expanded && isAccordion && <MinimizeIcon />}
        </Box>
      </ListItem>
      <ListItem
        disableGutters
        className={cx({ [classes.collapsed]: isAccordion || !expanded })}
      >
        <Collapse in={!isAccordion || expanded} timeout="auto" unmountOnExit>
          <List>{children}</List>
        </Collapse>
      </ListItem>
    </List>
  )
}
const Item: React.FC<FooterMenuItemType> = ({ title, url }) => {
  const { classes } = useStyles()
  return (
    <ListItem className={classes.item} disableGutters>
      <Text variant="primary-200">
        <FooterMenuItemLink url={url} title={title} />
      </Text>
    </ListItem>
  )
}
const FooterMenuItem: React.FC<FooterMenuItemProps> = ({
  container,
  item,
  url,
  title,
  isAccordion,
  children,
}) => (
  <>
    {container && (
      <Container isAccordion={isAccordion} url={url} title={title}>
        {children}
      </Container>
    )}
    {item && <Item url={url} title={title} />}
  </>
)
const FooterMenuItemLink: React.FC<{ url: string; title: string }> = ({
  url,
  title,
}) => {
  return (
    <>
      {url && url.startsWith("/") && (
        <RelativeLink href={url}>{title}</RelativeLink>
      )}
      {url && !url.startsWith("/") && <a href={url}>{title}</a>}
    </>
  )
}
export default FooterMenuItem
