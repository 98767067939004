import TextField, { TextFieldProps } from "components/Forms/TextField"
import { getIn, useFormikContext } from "formik"
import React from "react"

type FormikTextFieldProps = {
  TextFieldComponent?: typeof TextField
} & TextFieldProps

const FormikTextField = React.forwardRef<HTMLDivElement, FormikTextFieldProps>(
  ({ name, TextFieldComponent = TextField, children, ...restProps }, ref) => {
    const formik = useFormikContext()

    if (!formik) {
      console.error(`The textfield ${name} is not part of a Formik context`)
      return null
    }

    if (!name) {
      return null
    }

    const value = getIn(formik.values, name)
    const error = getIn(formik.errors, name)
    const touched = getIn(formik.touched, name)

    return (
      <TextFieldComponent
        name={name}
        error={touched && !!error}
        helperText={touched && error ? error : ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={value}
        ref={ref}
        {...restProps}
      >
        {children}
      </TextFieldComponent>
    )
  }
)

export default FormikTextField
