import { CancelToken } from "axios"

import request, { requestWithoutTokenUpdate } from "./request"

export type ConnectTokenResponse = {
  access_token: string
  expires_in: number
  refresh_token: string
  token_type: string
}

type ConnectApi = {
  login: (userName: string, password: string) => Promise<ConnectTokenResponse>
  refreshToken: (refreshToken: string) => Promise<ConnectTokenResponse>
}

const endpointUrl = "episerver/connect/token"
const clientId = "frontend"
const scope = "offline_access"

const connectApi: ConnectApi = {
  login: (userName: string, password: string, cancelToken?: CancelToken) =>
    request.post<ConnectTokenResponse>(
      endpointUrl,
      {
        password,
        username: userName,
        client_id: clientId,
        grant_type: "password",
        scope,
      },
      {
        cancelToken,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    ),
  refreshToken: (refreshToken: string) => {
    return requestWithoutTokenUpdate.post<ConnectTokenResponse>(
      endpointUrl,
      {
        refresh_token: refreshToken,
        grant_type: "refresh_token",
        client_id: clientId,
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    ) as any as Promise<ConnectTokenResponse>
  },
}

export default connectApi
