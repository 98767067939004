import { Market } from "@ignite/api/markets"
import { useApiContext } from "@ignite/react/context/ApiContextContainer"
import { useMarketContext } from "@ignite/react/context/marketContext"
import React, { useEffect } from "react"

import MarketPickerDesktop from "./MarketPickerDesktop"
import MarketPickerMobile from "./MarketPickerMobile"

type MarketPickerProps = {
  mobile?: boolean
}

const MarketPicker: React.FC<MarketPickerProps> = ({ mobile }) => {
  const {
    state: { marketOptions },
  } = useMarketContext()

  const api = useApiContext()

  const [selectedMarket, setMarket] = React.useState<Market>()

  const handleChange = async (selectedMarket: string) => {
    if (selectedMarket) {
      await api.markets.setMarket(selectedMarket)
      window.location.reload()
    }
  }

  useEffect(() => {
    if (marketOptions && marketOptions.data) {
      const selected = marketOptions.data.markets.find(
        (mrkt) => mrkt.isSelected
      )

      if (selected) {
        setMarket(selected)
      }
    }
  }, [marketOptions, marketOptions.data])

  if (
    !marketOptions ||
    !marketOptions.data ||
    !selectedMarket ||
    marketOptions.data.markets?.length === 1
  ) {
    return null
  }

  return mobile ? (
    <MarketPickerMobile
      markets={marketOptions.data?.markets}
      selectedMarket={selectedMarket}
      onChange={handleChange}
    />
  ) : (
    <MarketPickerDesktop
      markets={marketOptions.data?.markets}
      selectedMarket={selectedMarket}
      onChange={handleChange}
    />
  )
}

export default MarketPicker
