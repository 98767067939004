import Slide from "@mui/material/Slide"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import React from "react"

type HideOnScrollProps = {
  target?: Window | Node
  children: React.ReactElement
  enabled?: boolean
}

const HideOnScroll: React.FC<HideOnScrollProps> = ({
  target = window,
  children,
  enabled = true,
}) => {
  const trigger = useScrollTrigger({ target })

  return (
    <>
      {enabled && (
        <Slide appear={false} direction="down" in={!trigger}>
          {children}
        </Slide>
      )}
      {!enabled && <>{children}</>}
    </>
  )
}

export default HideOnScroll
