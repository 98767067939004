const colors = {
  body: {
    background: "#f5f5f5",
  },
  text: {
    secondary: "#FFFFFF",
  },
  white: {
    main: "#FFFFFF",
  },
  black: {
    main: "#000000",
  },
  grey: {
    50: "#fafafa",
    100: "#f6f6f5",
    200: "#eee",
    300: "#e0e0e0",
    400: "#f5f5f5",
    500: "#eee",
    600: "#c4c4c4",
    700: "#696961",
    800: "#424242",
    900: "#212121",
  },
  red: {
    main: "#BB1100",
    light: "#E36367",
  },
  system: {
    red: "#BB1100",
    yellow: "#FFB62D",
    green: "#88BB00",
    blue: "#4A90E2",
  },
  ui: {
    light: "#F6F6F5",
    main: "#DEDEDC",
    dark: "#C6C6C2",
  },
  status: {
    instock: "#24BA74",
  },
  backdrop: {
    light: "rgba(0, 0, 0, 0.20)",
    main: "rgba(0, 0, 0, 0.25)",
    dark: "rgba(0, 0, 0, 0.5)",
    contrastText: "#FFFFFF",
  },
  branding: {
    black: { 400: "#8E8E85", 450: "#696961", 500: "#121210", 600: "#111111" },
    green: {
      300: "#EBF3F0",
      main: "#006441",
      500: "#006441",
      dark: "#003C27",
      550: "#005C3C",
    },
    purple: {
      light: "#F2EAF4",
      200: "#FAF7FB",
      300: "#F2EAF4",
      350: "#782B9026",
      500: "#782D91",
      main: "#510b61",
      dark: "#481B57",
    },
    brown: {
      300: "#F5F0EA",
      main: "#90632C",
      dark: "#563B1A",
    },
    yellow: {
      main: "#ffd30f",
      dark: "#eec200",
    },
  },
}

/**
 * Nelson Theme - Colors
 */
export default colors
