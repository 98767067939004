import { ImpersonatableUsersResponse } from "@ignite/api/users"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import LoadingButton from "components/LoadingButton"
import Text from "components/Text"
import { Form, Formik, FormikHelpers } from "formik"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "tss-react/mui"
import * as yup from "yup"

const useStyles = makeStyles()(({ spacing }) => ({
  radioRoot: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  radioColorPrimary: {
    "&:hover": {
      background: "transparent !important",
    },
  },
  actions: {
    minWidth: "260px",
  },
}))

export type ImpersonationUser = ImpersonatableUsersResponse

export type FormValues = {
  items: ImpersonationUser[]
  user?: string
}

export type ImpersonationSearchComponentType = React.ComponentType<{
  loading?: boolean
  inputRef?: React.Ref<HTMLInputElement | undefined>
  placeholder?: string
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
}>

type ImpersonateUsersFormProps = {
  loading?: boolean
  initialValues: FormValues
  innerRef?: any
  enableReinitialize?: boolean
  SearchComponent: ImpersonationSearchComponentType
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues> | Promise<any>
  ) => void
  onQueryChange: (query: string) => void
}

const validationSchema = yup.object().shape({
  user: yup.string().required(),
})

const ImpersonateForm: React.FC<ImpersonateUsersFormProps> = ({
  initialValues,
  innerRef,
  enableReinitialize,
  loading,
  onQueryChange,
  onSubmit,
  SearchComponent,
}) => {
  const { classes } = useStyles()
  const inputRef = useRef<HTMLInputElement | undefined>()
  const { t } = useTranslation()

  const handleInputChange = () => {
    onQueryChange(inputRef?.current?.value || "")
  }

  return (
    <Formik
      innerRef={innerRef}
      initialValues={initialValues}
      enableReinitialize={enableReinitialize}
      validationSchema={validationSchema}
      validateOnChange
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, setFieldValue, isValid, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" minHeight="25vh">
            <Box mb={6}>
              <Text>{t("impersonation_dialog.text_description")}</Text>
            </Box>
            <Box mb={6}>
              <SearchComponent
                inputRef={inputRef}
                placeholder={t("impersonation_dialog.placeholder_search_field")}
                loading={loading}
                onChange={handleInputChange}
                onClick={handleInputChange}
              />
            </Box>
            {!loading &&
              values?.items?.length === 0 &&
              (inputRef?.current as HTMLInputElement)?.value !== "" && (
                <Text italic>
                  {t("impersonation_dialog.text_empty_search_result")}
                </Text>
              )}
            <RadioGroup
              name="user"
              onChange={(event) => {
                setFieldValue("user", event.currentTarget.value, true)
              }}
            >
              {values?.items.map((item) => (
                <Box key={item.id}>
                  <FormControlLabel
                    classes={{
                      root: classes.radioRoot,
                    }}
                    label={<Text component="span">{item.name}</Text>}
                    control={
                      <Radio
                        color="primary"
                        classes={{
                          colorPrimary: classes.radioColorPrimary,
                        }}
                        value={item.id}
                        checked={values.user === item.id}
                      />
                    }
                  />
                  <Divider />
                </Box>
              ))}
            </RadioGroup>
            <Box
              key="submit"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width="630px"
              maxWidth="100%"
              mt={8}
              flexDirection="column"
              flexGrow="1"
            >
              {values.items.length === 0 && (
                <Box my={6} width="100%">
                  <Divider />
                </Box>
              )}
              <LoadingButton
                classes={{ root: classes.actions }}
                type="submit"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
              >
                {t("impersonation_dialog.cta_select_user")}
              </LoadingButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default ImpersonateForm
