import { ButtonProps } from "@mui/material/Button"
import Button from "components/Button"
import React from "react"

type SnackbarButtonProps = ButtonProps & {
  buttonText: string
  cta: () => void
}

const SnackbarButton: React.FC<SnackbarButtonProps> = ({
  buttonText,
  cta,
  ...buttonProps
}) => {
  return (
    <Button onClick={cta} variant="contained" {...buttonProps}>
      {buttonText}
    </Button>
  )
}

export default SnackbarButton
