import { Market } from "@ignite/api/markets"
import MenuItem from "@mui/material/MenuItem"
import Dropdown from "components/Dropdown"
import React from "react"

type MarketPickerDesktopProps = {
  markets: Market[]
  selectedMarket: Market
  onChange: (selectedMarket: string) => void
}

const MarketPickerDesktop: React.FC<MarketPickerDesktopProps> = ({
  markets,
  selectedMarket,
  onChange,
  ...restProps
}) => {
  if (!markets || !selectedMarket) {
    return null
  }

  return (
    <Dropdown
      defaultButtonTitle={selectedMarket.displayName || ""}
      {...restProps}
    >
      {markets.map((item, i) => (
        <MenuItem onClick={() => onChange(item.marketId)} key={i}>
          {item.displayName}
        </MenuItem>
      ))}
    </Dropdown>
  )
}

export default MarketPickerDesktop
