import { ContentResponse } from "@ignite/api/contents"

import { asyncState, createStateContext } from "."

const [usePageContext, pageContextCreator] = createStateContext(
  {
    page: asyncState<ContentResponse>(),
  },
  {}
)

export { pageContextCreator, usePageContext }
