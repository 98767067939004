import { CartItem } from "@ignite/api/cart"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import { useTheme } from "@mui/material/styles"
import { debounce } from "@mui/material/utils"
import TrashIcon from "components/Icons/TrashIcon"
import Counter from "components/QuantityCounter"
import ResponsiveImage from "components/ResponsiveImage"
import Text from "components/Text"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "tss-react/mui"

import Price from "../Price"
import RelativeLink from "../RelativeLink"
import CartLineItemMessage from "./CartLineItemMessage"

const useStyles = makeStyles()(({ palette }) => ({
  removeCta: {
    padding: 0,
    color: palette.ui.dark,
  },
  productImage: {
    width: 98,
    height: 98,
    borderRadius: 8,
  },
  name: {
    maxWidth: "200px",
    overflowY: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "1.6em",
  },
  description: {
    maxWidth: "200px",
    overflowY: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "2.6em",
  },
}))

export type CartLineItemStatusType = "adjusted" | "changed"

type CartLineItemProps = {
  loading: boolean
  onQuantityChange: ({
    code,
    quantity,
  }: {
    code: string
    quantity: number
  }) => void
  onRemove: ({ code }: { code: string }) => void
  CounterComponent?: typeof Counter
  CounterComponentProps?: typeof Counter
  children?: React.ReactNode
} & CartItem

const CartLineItem: React.FC<CartLineItemProps> = ({
  loading,
  code,
  displayName,
  quantity,
  totalPriceString,
  discountTotal,
  totalExcludingDisountsString,
  properties,
  onQuantityChange,
  onRemove,
  CounterComponent = Counter,
  CounterComponentProps,
  children,
}) => {
  const [itemQuantity, setItemQuantity] = useState<number>(0)
  const [statusState, setStatusState] = useState<
    "init" | "loading" | "success" | CartLineItemStatusType
  >("init")

  const [t] = useTranslation()
  const { classes } = useStyles()
  const theme = useTheme()
  const delayedQuantityUpdateRequest = useRef(
    debounce((qty: number) => {
      onQuantityChange({ code, quantity: qty })
    }, 500)
  ).current

  const handleDecrement = () => {
    const qty = typeof itemQuantity === "number" ? itemQuantity - 1 : 1
    setItemQuantity(qty)
    delayedQuantityUpdateRequest(qty)
  }

  const handleIncrement = () => {
    const qty = typeof itemQuantity === "number" ? itemQuantity + 1 : 1
    setItemQuantity(qty)
    delayedQuantityUpdateRequest(qty)
  }

  const handleQuantityBlur = () => {
    const qty = typeof itemQuantity === "number" ? itemQuantity : 1
    setItemQuantity(qty)
    delayedQuantityUpdateRequest(qty)
  }

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const qtyNumber = parseInt(e.currentTarget.value)

    if (!isNaN(qtyNumber)) {
      setItemQuantity(qtyNumber === 0 ? 1 : qtyNumber)
    } else {
      setItemQuantity(1)
    }
  }

  useEffect(() => {
    if (!loading) {
      if (statusState === "init") {
        setStatusState("success")
        setItemQuantity(quantity)
      }
      if (statusState === "loading") {
        setStatusState("changed")
        if (itemQuantity > quantity) {
          setStatusState("adjusted")
          setItemQuantity(quantity)
        }
      }
    } else {
      if (statusState !== "init") {
        setStatusState("loading")
      }
    }
  }, [quantity, loading, statusState, itemQuantity])

  const image = properties.productImageUrl
    ? {
        id: "",
        type: "image",
        url: properties.productImageUrl,
        title: displayName,
        color: theme.palette.ui.dark,
        width: 98,
        height: 98,
        aspectRatio: 1,
      }
    : null

  return (
    <>
      <Box className="ig-cart__lineitem" display="flex">
        <Box mr={{ xs: 2, md: 4 }} minWidth={98} minHeight={98}>
          {image && (
            <RelativeLink href={properties.productUrl}>
              <ResponsiveImage
                objectFit="contain"
                image={image}
                width={{ xs: 52, md: 98 }}
                height={{ xs: 52, md: 98 }}
                containerProps={{
                  className: classes.productImage,
                }}
              />
            </RelativeLink>
          )}
        </Box>

        <Box flexGrow="1" display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <RelativeLink href={properties.productUrl}>
                <Text
                  title={displayName}
                  variant="primary-200"
                  bold
                  className={classes.name}
                >
                  {displayName}
                </Text>
              </RelativeLink>
            </Box>
            <IconButton
              className={classes.removeCta}
              onClick={() => onRemove({ code })}
              data-testid="cart-item-remove"
              size="large"
            >
              <TrashIcon size="sm" strokeWidth={2} fontSize="inherit" />
            </IconButton>
          </Box>

          <Text variant="secondary-100" className={classes.description}>
            {properties.description}
          </Text>

          <Box
            pt={2}
            display="flex"
            flexDirection="row"
            flexGrow="1"
            justifyContent="space-between"
            alignItems="center"
          >
            <CounterComponent
              max={9999}
              quantity={itemQuantity}
              onBlur={handleQuantityBlur}
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
              onInputChange={handleQuantityChange}
              disabled={loading || statusState === "loading"}
              size="narrow"
              {...CounterComponentProps}
            />
            <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
              {discountTotal > 0 && (
                <Price variant="primary-200" sale>
                  {totalPriceString}
                </Price>
              )}
              <Price variant="primary-200" disabled={!!discountTotal}>
                {totalExcludingDisountsString}
              </Price>
            </Box>
          </Box>
        </Box>
      </Box>
      {statusState === "adjusted" && (
        <CartLineItemMessage mt={3}>
          {t("cart.adjusted_quantity_notification")}
        </CartLineItemMessage>
      )}
      {children}
    </>
  )
}

export default CartLineItem
