import { TFunction } from "i18next"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

const useValidationSchema = (schemaFunction: (t: TFunction) => any) => {
  const { t } = useTranslation()

  return useMemo(() => schemaFunction(t), [schemaFunction, t])
}

export default useValidationSchema
