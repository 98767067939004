import { usePageContext } from "@ignite/react/context/pageContext"
import useBreakpoint from "@ignite/react/hooks/useBreakpoint"
import Box from "@mui/material/Box"
import RelativeLink from "components/RelativeLink"
import Text from "components/Text"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ palette }) => {
  return {
    section: {
      position: "absolute",
      paddingLeft: 224,
      paddingTop: 32,
      background: palette.common.white,
      zIndex: 2,
    },
    sectionSmall: {
      padding: "48px 0 16px 20px",
      background: palette.common.white,
      zIndex: 2,
      width: "100%",
    },
    text: {
      display: "inline",
    },
    transparentSection: {
      background: "transparent",
    },
    relative: {
      position: "relative",
      margin: 0,
      padding: 0,
    },
    top: {
      top: 0,
      margin: 0,
      padding: 0,
    },
  }
})

type Parent = {
  properties: {
    url: string
    title: string
    type: string
  }
}

type BreadcrumbsProps = {
  parents: Parent[]
  relative?: boolean
  top?: boolean
  className?: string
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  parents,
  relative,
  top,
  className,
}) => {
  const { classes, cx } = useStyles()
  const isSmall = useBreakpoint("md")

  const {
    state: { page },
  } = usePageContext()

  if (!parents) {
    return null
  }

  // If we are on a campaign or situation page, we only want to display the home breadcrumb
  const isOnlyHome =
    page.data?.properties.isCampaignNode ||
    page.data?.properties.isSituationNode
  const breadcrumbs = isOnlyHome ? parents.slice(0, 1) : parents

  return (
    <Box
      className={cx(
        {
          [classes.sectionSmall]: isSmall,
          [classes.section]: !isSmall,
          [classes.transparentSection]: page.loading,
          [classes.relative]: relative,
          [classes.top]: top,
        },
        className
      )}
    >
      {breadcrumbs.map((p, index) => (
        <RelativeLink href={p.properties.url} key={index}>
          <Text variant={"primary-200"} className={classes.text}>
            {` ${p.properties.title} ${
              index + 1 === breadcrumbs.length ? "" : "/"
            }`}
          </Text>
        </RelativeLink>
      ))}
    </Box>
  )
}

export default Breadcrumbs
