import Button, { ButtonProps } from "components/Button"
import UserIcon from "components/Icons/UserIcon"
import React from "react"
import { makeStyles } from "tss-react/mui"

export type TopSectionButtonClassKey = "root" | "icon"

type Props = ButtonProps

const useStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderColor: "rgba(255, 255, 255, 0.4)",
    color: palette.common.white,
    fontSize: 12,
    padding: `${spacing(0.5)} ${spacing(6)}`,

    "&:hover": {
      backgroundColor: "transparent !important",
      borderColor: "rgba(255, 255, 255, 0.4) !important",
    },
  },
  icon: {
    marginRight: spacing(2),
  },
}))

const TopSectionButton: React.FC<Props> = ({
  variant = "outlined",
  color = "primary",
  children,
  ...restProps
}) => {
  const { classes } = useStyles()

  return (
    <Button
      variant={variant}
      color={color}
      className={classes.root}
      {...restProps}
    >
      <UserIcon className={classes.icon} size="sm" />
      {children}
    </Button>
  )
}

export default TopSectionButton
