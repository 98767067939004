import { ApiLink } from "@ignite/api"
import { useSiteSettingsContext } from "@ignite/react/context/siteSettingsContext"
import Box, { BoxProps } from "@mui/material/Box"
import { IconProps } from "components/Icons"
import RelativeLink from "components/RelativeLink"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ palette }) => ({
  root: {
    backgroundColor: palette.header.marquee,
    color: palette.header.secondary,
    whiteSpace: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    scrollbarWidth: "none",
    WebkitOverflowScrolling: "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    alignItems: "center",
    minHeight: "40px",
  },
}))

type MarqueeProps = BoxProps & {
  iconStart?: IconProps
  iconEnd?: IconProps
}

const Marquee: React.FC<MarqueeProps> = ({
  className,
  iconStart,
  iconEnd,
  ...props
}) => {
  const {
    state: {
      siteSettings: { data: siteSettings },
    },
  } = useSiteSettingsContext()

  const firstBannerItem: ApiLink | undefined = (siteSettings?.properties
    .topBanners ?? [])[0]

  const { classes, cx } = useStyles()

  return (
    (firstBannerItem && (
      <Box
        className={cx(classes.root, className)}
        display="flex"
        p={2}
        justifyContent="center"
        textAlign="center"
        boxShadow="0px 1px 0px rgba(0, 0, 0, 0.13), inset 0px 1px 0px #DDDDDD"
        {...props}
      >
        <>
          {iconStart}
          <RelativeLink href={firstBannerItem.href} variant="primary-100">
            {firstBannerItem.title}
          </RelativeLink>
          {iconEnd}
        </>
      </Box>
    )) ||
    null
  )
}

export default Marquee
