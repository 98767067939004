import EpiConfig from "@ignite/config"
import { usePageContext } from "@ignite/react/context/pageContext"
import { isSearchBot } from "@ignite/utils/device"
import { useEffect } from "react"

const Prerender = () => {
  const {
    state: { page },
  } = usePageContext()

  useEffect(() => {
    let prerenderTimerID: number

    if (page && page.data && isSearchBot() && !window.prerenderReady) {
      const pageType = page?.data?.type || "UnknownPage"

      prerenderTimerID = window.setTimeout(() => {
        if (!EpiConfig.prerenderReadyIgnorePageTypes.includes(pageType)) {
          window.prerenderReady = true
        }
      }, EpiConfig.prerenderDefaultTimeout)
    }

    return () => {
      clearTimeout(prerenderTimerID)
    }
  }, [page])

  return null
}

export default Prerender
