import React from "react"
import Footer from "shell/Footer"
import Header from "shell/Header"

import Content from "../components/Content"

const DefaultApp: React.FC = () => {
  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  )
}

export default DefaultApp
