import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"
import React from "react"
import HeaderContentContainer, {
  HeaderContentContainerProps,
} from "shell/Header/HeaderContentContainer"

import SettingsMenu from "./SettingsMenu"
import TopMenu from "./TopMenu"

const StyledHeaderContentContainer = styled(HeaderContentContainer)(
  ({ theme }) => ({
    backgroundColor: theme.palette.header.secondary,
    color: theme.palette.getContrastText(theme.palette.header.secondary),
    height: theme.spacing(10),
    display: "flex",
  })
)

const TopSection: React.FC<HeaderContentContainerProps> = ({
  children,
  ...props
}) => (
  <StyledHeaderContentContainer {...props}>
    {children || (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <TopMenu />
        </Grid>

        <Grid item>
          <SettingsMenu />
        </Grid>
      </Grid>
    )}
  </StyledHeaderContentContainer>
)

export default TopSection
