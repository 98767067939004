import {
  CustomVariants,
  Typography as TypographyOpts,
  Variant,
} from "@mui/material/styles/createTypography"
import Typography, { TypographyProps } from "@mui/material/Typography"
import React from "react"
import { TypographyVariants } from "theme/typography"
import { makeStyles } from "tss-react/mui"

export type TextProps = Omit<TypographyProps, "variant"> & {
  variant?: CustomVariants
  bold?: boolean
  italic?: boolean
  fontFamily?: string
  component?: React.ElementType
}

const filterCustomVariants = (typography: TypographyOpts) => {
  return TypographyVariants.reduce(
    (acc, variant) => {
      acc[variant] = typography[variant]
      return acc
    },
    {} as Record<CustomVariants, any>
  )
}

const useStyles = makeStyles()(({ typography }) => ({
  fontFamilySecondary: {
    fontFamily: typography.fontFamilySecondary,
  },
  ...filterCustomVariants(typography),
  bold: {
    fontWeight: typography.fontWeightBold,
  },
  boldSecondary: {
    fontWeight: typography.fontWeightBoldSecondary,
  },
  italic: {
    fontStyle: "italic",
  },
}))

const variants: Record<string, Variant> = {
  button: "button",
  caption: "caption",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "subtitle1",
  subtitle2: "subtitle2",
  body1: "body1",
  body2: "body2",
  overline: "overline",
}

const Text = React.forwardRef<HTMLElement, TextProps>(
  (
    { variant = "primary-300", children, className, bold, italic, ...props },
    ref
  ) => {
    const { classes, cx } = useStyles()
    const isSecondary = variant.startsWith("secondary-")
    const muiVariant = variants[variant]

    return (
      // eslint-disable-next-line react/forbid-elements
      <Typography
        className={cx(
          {
            [classes[variant]]: !muiVariant && variant,
            [classes.fontFamilySecondary]: isSecondary,
            [classes.bold]: !isSecondary && bold,
            [classes.boldSecondary]: isSecondary && bold,
            [classes.italic]: italic,
          },
          className
        )}
        ref={ref}
        {...props}
        // To set the variant to null,
        // we avoid variant being fallback with "body1" class.
        variant={muiVariant || null}
      >
        {children}
      </Typography>
    )
  }
)

export default Text
