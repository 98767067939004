import { SiteSettingsResponse } from "@ignite/api/siteSettings"

import { asyncState, createStateContext } from "."

const [useSiteSettingsContext, siteSettingsContextCreator] = createStateContext(
  {
    siteSettings: asyncState<SiteSettingsResponse>(),
  },
  {}
)

export { siteSettingsContextCreator, useSiteSettingsContext }
