import React from "react"

import Icon, { IconProps } from "./"

const ExpandIcon: React.FC<IconProps> = ({
  fill = "currentColor",
  ...restProps
}) => {
  return (
    <Icon {...restProps} fill={fill} stroke="none">
      <path
        vectorEffect="non-scaling-stroke"
        d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
      />
    </Icon>
  )
}

export default ExpandIcon
