import React from "react"
import { makeStyles } from "tss-react/mui"

import Text, { TextProps } from "./Text"

const useStyles = makeStyles()(({ palette, typography, spacing }) => ({
  root: {
    "& a": {
      color: palette.text.primary,
      textDecoration: "underline",
      textUnderlinePosition: "under",
      "&:hover": {
        color: palette.primary.main,
      },
    },
    "& ul": {
      listStyle: "disc",
    },
    "& ol": {
      listStyle: "decimal",
    },
    "& ul, ol": {
      "& li": {
        marginTop: spacing(2),
        paddingLeft: spacing(2),
      },
    },
    "& dl": {
      paddingLeft: 0,
      "& dt": {
        fontWeight: typography.fontWeightBold,
        marginTop: spacing(2),
      },
      "& dd": {
        marginLeft: 0,
      },
      "& dl": {
        paddingLeft: spacing(4),
      },
    },
    "& table": {
      borderCollapse: "collapse",
    },
    "& th": {
      ...typography["primary-400-bold"],
      padding: spacing(4),
    },
    "& tr:nth-of-type(odd) td": {
      backgroundColor: palette.ui.light,
    },
    "& td": {
      fontFamily: typography.fontFamilySecondary,
      ...typography["secondary-400"],
      padding: spacing(4),
    },
    "& h1": {
      ...typography["primary-800"],
    },
    "& h2": {
      ...typography["primary-600"],
      fontWeight: typography.fontWeightBold,
    },
    "& h3": {
      ...typography["primary-450"],
    },
    "& img": {
      maxWidth: "100%",
      objectFit: "cover",
    },
    "& iframe": {
      maxWidth: "100%",
      objectFit: "cover",
    },
    "& blockquote": {
      quotes: `"“" "”" "‘" "’"`,
      borderLeft: `6px ${palette.primary.main} solid`,
      padding: spacing(6),
      margin: `${spacing(8)} 0`,
      "& > p": {
        margin: 0,
        marginBottom: spacing(4),
        "&:before": {
          content: "open-quote",
        },
        "&:after": {
          content: "close-quote",
        },
        ...typography["primary-600"],
        fontWeight: typography.fontWeightBold,
      },
      "& > cite": {
        fontStyle: "normal",
        "&:before": {
          content: "'— '",
        },
      },
    },
  },
}))

export type RichTextProps = TextProps & {
  text: string
}

const RELATIVE_SEARCH_PATHS_REGEX =
  /src="(?!(?:https?:|\/\/|data:))([^"]+\.(?:jpg|jpeg|png|svg))"/gi

const getHtml = (html: string | undefined) => {
  if (
    html &&
    process.env.NODE_ENV === "development" &&
    Boolean(process.env.REACT_APP_GLOBAL_ASSETS_DOMAIN)
  ) {
    // In local development the HTML is served with relative paths to the images.
    // This is a way of replacing these with the absolute path so they turn up on page.
    return html.replace(
      RELATIVE_SEARCH_PATHS_REGEX,
      (_, p1) =>
        `src="${process.env.REACT_APP_GLOBAL_ASSETS_DOMAIN}/${p1.replace(/^\//, "")}"`
    )
  }

  return html || ""
}

const RichText: React.FC<RichTextProps> = ({
  text,
  component = "div",
  ...props
}) => {
  const { classes } = useStyles()

  const html = getHtml(text)

  return (
    <Text
      className={classes.root}
      component={component}
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
    />
  )
}
export default RichText
