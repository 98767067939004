import { isSearchBot } from "@ignite/utils/device"
import { Breakpoint, Theme, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

const useBreakpoint = (breakpoint: Breakpoint | number) => {
  const theme = useTheme<Theme>()

  const mediaQueryResult = useMediaQuery(theme.breakpoints.down(breakpoint))

  if (isSearchBot()) {
    switch (breakpoint) {
      case "xs":
        return false
      case "sm":
        return true // Mobile first for crawlers like Google, Prerender.io, etc.
      case "md":
        return false
      case "lg":
        return false
      case "xl":
        return false
    }
  }

  return mediaQueryResult
}

export default useBreakpoint
