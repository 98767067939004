import Icon, { IconProps } from "components/Icons"
import React from "react"

const UserIcon: React.FC<IconProps> = ({ ...restProps }) => (
  <Icon {...restProps} stroke="none" viewBox="0 0 12 14">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.333"
      d="M11.335 13v-1.333A2.667 2.667 0 008.668 9H3.335a2.667 2.667 0 00-2.667 2.667V13M6.003 6.333a2.667 2.667 0 100-5.333 2.667 2.667 0 000 5.333z"
    />
  </Icon>
)

export default UserIcon
