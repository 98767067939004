import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ChevronIcon from "components/Icons/ChevronIcon"
import React from "react"
import { makeStyles } from "tss-react/mui"

import { MenuData } from "."

type MobileMenuItemProps = {
  item: MenuData
  onClick?: (item: MenuData) => void
  children?: React.ReactNode
}

const useStyles = makeStyles()(({ typography, spacing, palette }) => ({
  root: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    fontSize: typography["primary-300"].fontSize,
    fontWeight: typography["primary-300"].fontWeight,
    "&:after": {
      content: "''",
      backgroundColor: palette.ui.main,
      display: "block",
      height: "1px",
      position: "absolute",
      bottom: 0,
      left: spacing(3),
      right: spacing(3),
    },
  },
  rootTopMenu: {
    fontSize: typography["primary-400"].fontSize,
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    fontWeight: typography.fontWeightBold,
  },
}))

const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  children,
  item,
  onClick,
}) => {
  const { classes, cx } = useStyles()

  const handleListItemOnClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (onClick) {
      onClick(item)
    }
  }

  return (
    <ListItem
      component="a"
      href={item.url}
      className={cx(
        classes.root,
        item.parent && !item.parent.parent && classes.rootTopMenu
      )}
      onClick={handleListItemOnClick}
    >
      <ListItemText disableTypography>{children}</ListItemText>
      {item.children && item.children.length > 0 && (
        <ChevronIcon color="primary" size="sm" direction="right" />
      )}
    </ListItem>
  )
}

export default MobileMenuItem
