import { ImageType } from "@ignite/api/contents"
import useBreakpoint from "@ignite/react/hooks/useBreakpoint"
import React from "react"

import Image, { ImageProps } from "../components/Image"

type ResponsiveImageProps = Omit<ImageProps, "src"> & {
  image?: ImageType
  enableColor?: boolean
}

/** Wrapper for Images with lazy loading and sets correct source sets */
const ResponsiveImage: React.FunctionComponent<ResponsiveImageProps> = ({
  image,
  enableColor = true,
  ...rest
}) => {
  const isSmall = useBreakpoint("md")

  if (!image) {
    return null
  }

  const imageProps =
    (image["properties"] && isSmall && image["properties"].smLink) || image.url

  return (
    // eslint-disable-next-line react/forbid-elements
    <Image
      src={imageProps}
      color={enableColor ? imageProps.color : "transparent"}
      lazy
      alt={image.title}
      aspectRatio={image.aspectRatio}
      {...rest}
    />
  )
}

export default ResponsiveImage
