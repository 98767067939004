import useBreakpoint from "@ignite/react/hooks/useBreakpoint"
import Box, { BoxProps } from "@mui/material/Box"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ spacing, breakpoints }) => ({
  root: {
    "& *": {
      color: "currentColor",
    },
    [breakpoints.down("md")]: {
      flexDirection: "column",
      margin: `${spacing(4)} 0`,
    },
  },
}))

type FooterMenuProps = BoxProps & {
  justifyAlignment?: string
}

const FooterMenu: React.FC<FooterMenuProps> = ({
  children,
  justifyAlignment = "space-between",
  ...restProps
}) => {
  const isAccordion = useBreakpoint("md")
  const { classes } = useStyles()

  return (
    <Box
      {...restProps}
      display="flex"
      justifyContent={justifyAlignment}
      color="currentColor"
      className={classes.root}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child as any, {
          isAccordion,
        })
      )}
    </Box>
  )
}

export default FooterMenu
