import React from "react"

import Icon, { IconProps } from "./"

const CartIcon: React.FC<IconProps> = ({ ...restProps }) => {
  return (
    <Icon {...restProps} stroke="none">
      <path
        d="M4 19.312V7.5h16v11.812c0 .448-.187.877-.52 1.193a1.827 1.827 0 0 1-1.258.495H5.778c-.472 0-.924-.178-1.257-.495A1.645 1.645 0 0 1 4 19.312z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M8.573 12V6.392c0-.9.36-1.762 1.004-2.398A3.447 3.447 0 0 1 12 3c.91 0 1.782.357 2.425.994a3.374 3.374 0 0 1 1.004 2.398V12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect="non-scaling-stroke"
      />
    </Icon>
  )
}

export default CartIcon
