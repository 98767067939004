import { MarketsResponse } from "@ignite/api/markets"

import { asyncState, createStateContext } from "."

const [useMarketContext, marketContextCreator] = createStateContext(
  {
    marketOptions: asyncState<MarketsResponse>(),
  },
  {}
)

export { marketContextCreator, useMarketContext }
