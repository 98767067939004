import { asyncState, createStateContext } from "."

const [useSearchContext, searchContextCreator] = createStateContext(
  {
    open: false,
    popularPhrases: asyncState<string[]>(),
  },
  {
    toggle: ({ open, ...state }) => ({ ...state, open: !open }),
  }
)

export { searchContextCreator, useSearchContext }
