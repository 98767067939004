import Box from "@mui/material/Box"
import { Theme } from "@mui/material/styles"
import { Button } from "components/Button"
import Text from "components/Text"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "tss-react/mui"

type GlobalErrorFallbackProps = {
  image?: React.ReactElement
}

const GlobalErrorFallback: React.FC<GlobalErrorFallbackProps> = ({ image }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const handleClick = () => window.location.reload()

  const errorImageComponent = useMemo(
    () =>
      image &&
      React.cloneElement(image, {
        className: classes.image,
      }),
    [classes.image, image]
  )

  return (
    <Box
      py={20}
      alignItems="center"
      textAlign="center"
      display="flex"
      flexDirection="column"
    >
      {errorImageComponent && errorImageComponent}
      <Text gutterBottom variant="primary-600" bold>
        {t("ErrorFallback.error")}
      </Text>
      <Text className={classes.text}>
        {t("ErrorFallback.error_description")}
      </Text>

      <Box mt={1}>
        <Button event="error-btn-clicked" onClick={handleClick} color="primary">
          {t("ErrorFallback.button_text")}
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles()(({ spacing, palette }: Theme) => ({
  text: {
    color: palette.text.disabled,
  },
  image: {
    maxWidth: 400,
    marginBottom: spacing(2),
  },
}))

export default GlobalErrorFallback
