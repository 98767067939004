import { MenuResponse } from "@ignite/api/menus"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import { Theme } from "@mui/material/styles"
import RelativeLink from "components/RelativeLink"
import React from "react"
import { makeStyles } from "tss-react/mui"

type MiniMenuComponentProps = {
  item: MenuResponse

  MediaComponent?: React.FC
  onClose: () => void
}

const useStyles = makeStyles()(({ palette, spacing }: Theme) => ({
  miniMenu: {
    opacity: 0,
    color: palette.getContrastText(palette.header.megaMenu),
    backgroundColor: palette.header.megaMenu,
    transition:
      "opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1), visibility 200ms cubic-bezier(0.4, 0.0, 0.2, 1)",
    borderRadius: `0 0 ${spacing(3)} ${spacing(3)}`,
  },
  miniMenuList: {
    paddingTop: spacing(3),
  },
  listItem: {
    padding: 0,
  },
  miniMenuLink: {
    marginTop: spacing(3),
    marginBottom: spacing(3),
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

const MiniMenuComponent: React.FC<MiniMenuComponentProps> = ({
  item,

  onClose,
}) => {
  const { children } = item
  const { classes } = useStyles()
  return (
    <Box
      visibility="hidden"
      position="absolute"
      className={`megaMenuContent ${classes.miniMenu}`}
      width="auto"
      minWidth="320px"
      marginLeft={`-24px`}
      padding={6}
    >
      <Box
        alignItems="flex-start"
        display="flex"
        flexWrap="wrap"
        width="100%"
        margin="0 auto"
      >
        {children && (
          <List className={classes.miniMenuList}>
            {children.map((child) => {
              return (
                <ListItem
                  key={child.id + child.title}
                  className={classes.listItem}
                >
                  <RelativeLink
                    onClick={onClose}
                    className={classes.miniMenuLink}
                    variant="primary-300"
                    href={child.url}
                  >
                    {child.title}
                  </RelativeLink>
                </ListItem>
              )
            })}
          </List>
        )}
      </Box>
    </Box>
  )
}

export default MiniMenuComponent
