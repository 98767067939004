import { ImageType } from "@ignite/api/contents"
import ContentContainer from "@ignite/react/components/Content/ContentContainer"
import Grid from "@mui/material/Grid"
import ResponsiveImage from "components/ResponsiveImage"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(
  ({ breakpoints, typography, palette, spacing }) => ({
    root: {
      alignItems: "center",
      ...typography["primary-200"],
      [breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    container: {
      backgroundColor: palette.footer.secondary,
      color: palette.getContrastText(palette.footer.secondary),
    },
    icons: {
      [breakpoints.down("md")]: {
        justifyContent: "center",
      },
      "& > .MuiGrid-item": {
        paddingTop: spacing(4),
        paddingBottom: 0,
      },
    },
    copyright: {
      flexGrow: 1,
      textAlign: "right",
      [breakpoints.down("md")]: {
        marginTop: spacing(6),
      },
    },
  })
)

type SecondaryFooterProps = {
  icons?: ImageType[]
  copyrightNotice?: string
}

const SecondaryFooter: React.FC<SecondaryFooterProps> = ({
  icons,
  copyrightNotice,
}) => {
  const { classes } = useStyles()

  return (
    <ContentContainer
      className={classes.container}
      containerProps={{
        py: 8,
      }}
    >
      <Grid container spacing={5} className={classes.root}>
        {icons && icons.length > 0 && (
          <Grid item md={8}>
            <Grid container spacing={10} className={classes.icons}>
              {icons.map((icon, index: number) => (
                <Grid item key={index}>
                  <ResponsiveImage
                    width={80}
                    height={80}
                    image={icon}
                    containerProps={{
                      sx: {
                        width: { xs: 50, sm: 80 },
                        height: { xs: 50, sm: 80 },
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {copyrightNotice && (
          <Grid item className={classes.copyright}>
            {copyrightNotice}
          </Grid>
        )}
      </Grid>
    </ContentContainer>
  )
}

export default SecondaryFooter
