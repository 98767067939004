import { useApiContext } from "@ignite/react/context/ApiContextContainer"
import { useSearchContext } from "@ignite/react/context/searchContext"
import useBreakpoint from "@ignite/react/hooks/useBreakpoint"
import emitEvent from "@ignite/utils/eventEmitter"
import useSystemLinks from "@ignite/utils/systemLinksUtil"
import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import Drawer, { DrawerProps } from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import Input from "@mui/material/Input"
import InputAdornment from "@mui/material/InputAdornment"
import Toolbar from "@mui/material/Toolbar"
import { debounce } from "@mui/material/utils"
import DrawerContainer from "components/DrawerContainer"
import SearchIcon from "components/Icons/SearchIcon"
import SearchTextLink from "components/IgniteSearch/SearchTextLink"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ breakpoints, typography, spacing }) => ({
  inputRoot: {
    padding: `${spacing(2)} 0`,
  },
  input: {
    borderWidth: 0,
    padding: `${spacing(2)} 0`,
    ...typography["primary-450"],
    "&::-webkit-search-cancel-button": {
      appearance: "none",
    },
    [breakpoints.down("md")]: {
      ...typography["primary-300"],
    },
  },
  pointer: {
    cursor: "pointer",
  },
}))

type SearchProps = Pick<DrawerProps, "anchor">

const Search: React.FC<SearchProps> = ({ anchor }) => {
  const [query, setQuery] = useState("")
  const [autocomplete, setAutcomplete] = useState<string[]>([])

  const {
    state: { open },
    actions: { toggle },
  } = useSearchContext()
  const api = useApiContext()
  const links = useSystemLinks()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { classes } = useStyles()

  useEffect(() => {
    if (open) {
      emitEvent("clickOnSearch")
    }
  }, [open])

  const padding = (useBreakpoint("md") && 4) || 16

  const delayedAutocomplete = useRef(
    debounce((query) => {
      const qy = query.trim()
      if (!qy) return

      if (qy.length < 3) {
        setAutcomplete([])
        return
      }

      api.search.getAutocomplete(qy).then((_) => setAutcomplete(_))
    }, 500)
  ).current

  const submitQuery = () => {
    toggle()
    emitEvent("submitSearch")
    // in production the systemLinks (url) is absolute
    if (/^https?:\/\/|^\/\//i.test(url)) {
      navigate(`${new URL(url).pathname}?q=${encodeURIComponent(query)}`)
    } else {
      navigate(`${url}?q=${encodeURIComponent(query)}`)
    }
  }

  const inputRef = useCallback(
    (node: HTMLInputElement) => {
      if (query === "") {
        requestAnimationFrame(() => {
          // Focus on input
          const inputElement = Array.from(
            node?.childNodes || []
          )?.[0] as HTMLInputElement
          inputElement?.focus()
        })
      }

      if (node !== null && query !== "") {
        Array.from(node?.childNodes).forEach((child: ChildNode) => {
          if (child instanceof HTMLInputElement) {
            child.select()
          }
        })
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  )

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    delayedAutocomplete(e.target.value)
  }

  const handleQuerySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    submitQuery()
  }

  const anchorValue = anchor || "top"
  const url = links["search"]

  return (
    <Drawer anchor={anchorValue} open={open} onClose={toggle}>
      <DrawerContainer anchor={anchorValue}>
        <Toolbar>
          <Box flexGrow={1} />
          <IconButton
            aria-label="close"
            onClick={toggle}
            color="inherit"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Box display="flex" justifyContent="center">
          <Box
            p={padding}
            pt={0}
            m="0 auto"
            width="100%"
            maxWidth={800 + 4 * padding}
          >
            <form
              onSubmit={handleQuerySubmit}
              action={`${url}?q=${encodeURIComponent(query)}`}
            >
              <Input
                className={classes.inputRoot}
                inputProps={{ className: classes.input }}
                fullWidth
                ref={inputRef}
                value={query}
                onChange={handleQueryChange}
                placeholder={t("search.for_input_placeholder")}
                autoFocus
                disableUnderline={false}
                type="search"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon
                      onClick={submitQuery}
                      className={classes.pointer}
                    />
                  </InputAdornment>
                }
              />
            </form>
            <Box mt={4}>
              {autocomplete.map((item, idx) => (
                <SearchTextLink
                  key={idx}
                  onClick={toggle}
                  href={`${url}?q=${encodeURIComponent(item)}`}
                  highlight={query}
                >
                  {item}
                </SearchTextLink>
              ))}
            </Box>
          </Box>
        </Box>
      </DrawerContainer>
    </Drawer>
  )
}

export default Search
