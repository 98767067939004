import { CancelToken } from "axios"

import { Properties } from "."
import { Address, Organization } from "./checkout"
import request, { requestWithoutTokenUpdate } from "./request"

export type UserResponse = {
  id: string
  email: string
  firstName: string
  fullName: string
  lastName: string
  isApproved: true
  isLocked: false
  createdDate: string
  preferredCurrency: string
  preferredLanguage: string
  customerNumber: string
  phoneNumber?: string
  organization?: Organization
  canImpersonate: boolean
  impersonatingUser: ImpersonatingUser | null
  properties?: Properties
}

export type TokenResponse = {
  access_token: string
  expires_in: number
  refresh_token: string
}

type ImpersonatingUser = {
  organizationName: string
  customerId: string
  id: string
  username: string
  name: string
}

export type ImpersonatableUsersResponse = {
  organizationName: string
  customerId: string
  id: string
  username: string
  name: string
}

type UsersApi = {
  getMe: () => Promise<UserResponse>
  getUser: (userName: string) => Promise<UserResponse>
  login: (userName: string, password: string) => Promise<TokenResponse>
  refreshToken: (refreshToken: string) => Promise<TokenResponse>
  updateAddress: (
    username: string,
    address: Partial<Address>
  ) => Promise<Address>
  updateOrganizationAddress: (
    username: string,
    address: Partial<Address>
  ) => Promise<Address>
  getImpersonatableUsers: (
    search?: string,
    take?: number,
    skip?: number
  ) => Promise<ImpersonatableUsersResponse[]>
  impersonate: (userId?: string) => Promise<TokenResponse>
  requestPasswordReset: (customerNumber: string) => Promise<void>
  resetPassword: (
    customerNumber: string,
    password: string,
    token: string
  ) => Promise<void>
}

const usersApi: UsersApi = {
  getMe: () => request.get<UserResponse>("/membership/user/me"),
  getUser: (userName: string) =>
    request.get<UserResponse>(`/membership/user/${userName}`),
  /**
   * @deprecated since version 0.5.19 recommended to use api.connect.login instead.
   */
  login: (userName: string, password: string, cancelToken?: CancelToken) =>
    request.post<TokenResponse>(
      "/token",
      {
        password,
        username: userName,
        grant_type: "password",
      },
      {
        cancelToken,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    ),
  /**
   * @deprecated since version 0.5.19 recommended to use api.connect.refreshToken instead.
   */
  refreshToken: (refreshToken: string) => {
    return requestWithoutTokenUpdate.post<TokenResponse>(
      "/token",
      {
        refresh_token: refreshToken,
        grant_type: "refresh_token",
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    ) as any as Promise<TokenResponse>
  },
  updateAddress: (username: string, address: Partial<Address>) =>
    request.put<Address>(`/membership/user/${username}/address`, address),
  updateOrganizationAddress: (username: string, address: Partial<Address>) =>
    request.put<Address>(
      `/membership/user/${username}/organization/address`,
      address
    ),
  getImpersonatableUsers: (search?: string, take?: number, skip?: number) =>
    request.get<ImpersonatableUsersResponse[]>(
      `/membership/impersonate/users/me?search=${
        search || ""
      }&take=${take}&skip=${skip}`
    ),
  impersonate: (userId?: string, cancelToken?: CancelToken) =>
    request.post<TokenResponse>(
      "/token",
      {
        impersonateUserId: userId || "",
        grant_type: "password",
      },
      {
        cancelToken,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    ),
  /** Delivers a token that will be used for resetPassword() */
  requestPasswordReset: (customerNumber: string) =>
    request.get<void>(`/membership/user/${customerNumber}/resetpassword`),
  resetPassword: (customerNumber: string, password: string, token: string) =>
    request.post<void>(
      `/membership/user/resetpassword?username=${customerNumber}`,
      {
        password,
        token,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    ),
}

export default usersApi
