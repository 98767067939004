import { MarketOption } from "@ignite/api/markets"
import { useMarketContext } from "@ignite/react/context/marketContext"
import { usePageContext } from "@ignite/react/context/pageContext"
import emitEvent from "@ignite/utils/eventEmitter"
import React, { useEffect, useMemo } from "react"

import LanguagePickerDesktop from "./LanguagePickerDesktop"
import LanguagePickerMobile from "./LanguagePickerMobile"

type LanguagePickerProps = {
  mobile?: boolean
}

const LanguagePicker: React.FC<LanguagePickerProps> = ({ mobile }) => {
  const {
    state: { marketOptions },
  } = useMarketContext()

  const {
    state: { page },
  } = usePageContext()

  const hrefLangs = useMemo(
    () =>
      page?.data?.hrefLangs?.reduce((acc, href) => {
        acc[href.lang] = href
        return acc
      }, []),
    [page]
  )

  const onChangeLanguage = (fromLanguage: string | any, toLanguage: string) => {
    emitEvent("changeLanguage", { fromLanguage, toLanguage })
  }

  const [selectedLanguage, setLanguage] = React.useState<MarketOption>()

  useEffect(() => {
    if (marketOptions && marketOptions.data) {
      const selected = marketOptions.data.languages.find(
        (lang) => lang.isSelected
      )
      if (selected) {
        setLanguage(selected)
      }
    }
  }, [marketOptions, marketOptions.data])

  if (
    !selectedLanguage ||
    !marketOptions ||
    !marketOptions.data ||
    marketOptions.data.languages?.length === 1 ||
    !hrefLangs
  ) {
    return null
  }

  return mobile ? (
    <LanguagePickerMobile
      languages={marketOptions.data?.languages}
      selectedLanguage={selectedLanguage}
      hrefLangs={hrefLangs}
      onChange={onChangeLanguage}
    />
  ) : (
    <LanguagePickerDesktop
      defaultButtonTitle={selectedLanguage?.displayName || ""}
      languages={marketOptions.data?.languages}
      selectedLanguage={selectedLanguage}
      hrefLangs={hrefLangs}
      onClick={onChangeLanguage}
    />
  )
}

export default LanguagePicker
