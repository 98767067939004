import useBreakpoint from "@ignite/react/hooks/useBreakpoint"
import AppBar from "@mui/material/AppBar"
import { Theme } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import HideOnScroll from "components/HideOnScroll"
import React from "react"
import { makeStyles } from "tss-react/mui"

import Marquee from "./Marquee"
import TopSection from "./TopSection"

const useStyles = makeStyles()(({ palette, breakpoints, spacing }: Theme) => ({
  root: {
    backgroundColor: palette.header.primary,
    color: palette.getContrastText(palette.header.primary),
    borderBottom: "none",
    justifyContent: "center",
  },
  toolbar: {
    position: "initial",
    [breakpoints.down("md")]: {
      position: "relative",
    },
  },
  pseudoElement: {
    minHeight: "80px",
    padding: `0 ${spacing(9)}`,
    "&:before": {
      minWidth: "131px",
      content: "''",
      marginLeft: "auto",
      flex: 1,
    },
    [breakpoints.down("md")]: {
      minHeight: "64px",
      padding: `0 0 0 ${spacing(4)}`,
      "&:before": {
        display: "none",
      },
    },
  },
}))

export type HeaderContainerProps = {
  topSection?: React.ReactNode
  marquee?: React.ReactNode
  className?: string
  hideOnScroll?: boolean
  hasLeftContent?: boolean
  children?: React.ReactNode
}

const HeaderContainer: React.FC<HeaderContainerProps> = ({
  topSection = <TopSection />,
  children,
  marquee = <Marquee />,
  className,
  hideOnScroll = true,
  hasLeftContent = false,
}) => {
  const { classes, cx } = useStyles()
  const isSmall = useBreakpoint("md")

  return (
    <HideOnScroll enabled={hideOnScroll}>
      <AppBar
        component="header"
        position="sticky"
        className={cx(classes.root, className)}
      >
        {isSmall && marquee}
        {topSection}
        <Toolbar
          className={cx(classes.toolbar, {
            [classes.pseudoElement]: !hasLeftContent,
          })}
        >
          {children}
        </Toolbar>
        {!isSmall && marquee}
      </AppBar>
    </HideOnScroll>
  )
}

export default HeaderContainer
