import { MarketsResponse } from "@ignite/api/markets"

type MarketType = "b2c" | "b2b" | "b2bRetailPortal"

const getSelectedMarketId = (
  marketOptions: MarketsResponse | null
): string | undefined =>
  (marketOptions?.markets || []).find((market) => market.isSelected)?.marketId

// TODO: Consider converting this utility function into a custom React Hook using useMemo.
// This would allow us to memoize the result of the expensive operation (finding the selected market ID and determining its type),
// thus improving performance by avoiding unnecessary recalculations on every render.
// The hook could accept marketOptions as a dependency and return the determined market type,
// which would then only recompute when marketOptions changes.
export const getMarketType = (
  marketOptions: MarketsResponse | null
): MarketType => {
  const marketIdSubString = getSelectedMarketId(marketOptions)?.substring(0, 4)

  switch (true) {
    case marketIdSubString === "B2B_":
      return "b2b"
    case marketIdSubString === "B2BS":
      return "b2bRetailPortal"
    default:
      return "b2c"
  }
}
