import Box, { BoxProps } from "@mui/material/Box"
import RichText from "components/RichText"
import React from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(({ typography }) => ({
  root: {
    "& strong": {
      fontWeight: typography.fontWeightBold,
    },
    "& span": {
      whiteSpace: "nowrap",
    },
  },
}))

type SloganProps = BoxProps & {
  content: string
}

const Slogan: React.FC<SloganProps> = ({ content, ...restProps }) => {
  const { classes } = useStyles()

  return (
    <Box {...restProps}>
      <RichText className={classes.root} variant="primary-200" text={content} />
    </Box>
  )
}

export default Slogan
