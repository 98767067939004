import * as React from "react"

export type SkeletonProps = {
  animate?: boolean
  ariaLabel?: string
  children?: React.ReactNode
  className?: string
  height?: number | string
  preserveAspectRatio?:
    | "none"
    | "xMinYMin meet"
    | "xMidYMin meet"
    | "xMaxYMin meet"
    | "xMinYMid meet"
    | "xMidYMid meet"
    | "xMaxYMid meet"
    | "xMinYMax meet"
    | "xMidYMax meet"
    | "xMaxYMax meet"
    | "xMinYMin slice"
    | "xMidYMin slice"
    | "xMaxYMin slice"
    | "xMinYMid slice"
    | "xMidYMid slice"
    | "xMaxYMid slice"
    | "xMinYMax slice"
    | "xMidYMax slice"
    | "xMaxYMax slice"
  primaryColor?: string
  primaryOpacity?: number
  rtl?: boolean
  secondaryColor?: string
  secondaryOpacity?: number
  speed?: number
  style?: React.CSSProperties
  uniquekey?: string
  width?: number | string
}

const uid = (): string => Math.random().toString(36).substring(2)

const Skeleton: React.FC<SkeletonProps> = ({
  rtl,
  speed = 2,
  style,
  width = "100%",
  height = "100%",
  animate = true,
  ariaLabel,
  children,
  className,
  uniquekey,
  primaryColor = "#f3f3f3",
  primaryOpacity = 1,
  secondaryColor = "#ecebeb",
  secondaryOpacity = 1,
  preserveAspectRatio,
  ...props
}) => {
  const idClip = uniquekey ? `${uniquekey}-idClip` : uid()
  const idGradient = uniquekey ? `${uniquekey}-idGradient` : uid()

  const defautlAnimation = ["-3; 1", "-2; 2", "-1; 3"]
  const rtlAnimation = ["1; -3", "2; -2", "3; -1"]

  const animationValues = rtl ? rtlAnimation : defautlAnimation

  const clipWidth =
    (typeof width === "string" && width.endsWith("%") && "100%") || width
  const clipHeight =
    (typeof height === "string" && height.endsWith("%") && "100%") || height

  return (
    <svg
      width={width}
      height={height}
      role="img"
      style={style}
      className={className}
      aria-labelledby={ariaLabel}
      preserveAspectRatio={preserveAspectRatio}
      {...props}
    >
      <title>{ariaLabel}</title>
      <rect
        x="0"
        y="0"
        width={clipWidth}
        height={clipHeight}
        clipPath={`url(#${idClip})`}
        style={{ fill: `url(#${idGradient})` }}
      />

      <defs>
        <clipPath id={idClip}>{children}</clipPath>

        <linearGradient id={idGradient}>
          <stop
            offset="0%"
            stopColor={primaryColor}
            stopOpacity={primaryOpacity}
          >
            {animate && (
              <animate
                attributeName="offset"
                values={animationValues[0]}
                dur={`${speed}s`}
                repeatCount="indefinite"
              />
            )}
          </stop>

          <stop
            offset="50%"
            stopColor={secondaryColor}
            stopOpacity={secondaryOpacity}
          >
            {animate && (
              <animate
                attributeName="offset"
                values={animationValues[1]}
                dur={`${speed}s`}
                repeatCount="indefinite"
              />
            )}
          </stop>

          <stop
            offset="100%"
            stopColor={primaryColor}
            stopOpacity={primaryOpacity}
          >
            {animate && (
              <animate
                attributeName="offset"
                values={animationValues[2]}
                dur={`${speed}s`}
                repeatCount="indefinite"
              />
            )}
          </stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Skeleton
