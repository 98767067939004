import { ProductCardProps } from "components/ProductCard"
import qs from "qs"

import request from "./request"

export type ProductFilterRequest = {
  /** Max products to receive. */
  limit: number

  /** Used for pagination. */
  offset: number

  /** E.g productnamelatest. */
  sort?: string

  /** If true - then you will get products in all subcategories. */
  descendants?: boolean

  /** Facets to filter on. Must match those received by facets in initial response. */
  filters?: Record<string, string[]>[]
}

type ProductFilterResponse = {
  /** Available facets for the returned items. */
  facets: Facet[]

  /** Sortings available for the returned items. */
  sortSettings: SortSetting[]

  /** Items matching your filter. */
  items: ProductCardProps[]

  /** Items matching your filter. */
  limit: number

  /** Available facets for the returned items. */
  offset: number

  /** Total items for this request - i.e if we can continue paging. */
  totalItems: number
}

export type Facet = {
  /** Available terms to filter on. */
  terms: FacetTerm[]

  /** If multiple terms can be applied. */
  multiSelect: boolean

  /** Name to pass to API when requesting updated results. */
  filterName: string

  /** For customizing the appearance of this facet. E.g list or swatch. */
  displayType: string

  /** Translated and user friendly name for this facet. */
  name: string
}

type FacetTerm = {
  /** Term that can be applied on the facet. */
  value: string

  /** Friendly name. */
  name: string

  /** Items matching your this term. */
  count: number

  /** If this term is selected by the user. */
  isSelected: boolean
}

export type SortSetting = {
  /** Pass to API for sorting. */
  id: string

  /** Translated and user friendly name for this sorting. */
  name: string

  /** If this sorting is active. */
  isSelected: boolean
}

type ProductFilterAPi = {
  getProducts: (
    /** Path to category like category/category2. */
    route: string,

    /** Filtering, sorting and pagination limits. */
    filterRequest: ProductFilterRequest
  ) => Promise<ProductFilterResponse>
}

/**
 * @deprecated since version 0.5.19 recommended to use api.products.search instead.
 */
const productFilterApi: ProductFilterAPi = {
  getProducts: (route, filterRequest) =>
    request.get<ProductFilterResponse>(
      `/products/${route}?${qs.stringify(filterRequest, { indices: false })}`
    ),
}

export default productFilterApi
