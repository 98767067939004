import React from "react"

import Icon, { IconProps } from "./"

const RemoveIcon: React.FC<IconProps> = ({ ...restProps }) => {
  return (
    <Icon {...restProps} stroke="none">
      <path
        vectorEffect="non-scaling-stroke"
        d="M19 13H5v-2h14v2z"
        fill="currentColor"
      ></path>
    </Icon>
  )
}

export default RemoveIcon
