import React from "react"

import Icon, { IconProps } from "./"

const LinkedinIcon: React.FC<IconProps> = ({
  stroke = "none",
  ...restProps
}) => {
  return (
    <Icon {...restProps} stroke={stroke}>
      <path
        d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11z"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeWidth="2"
      />
      <path
        d="M9.529 15.75h-1.82V9.767H9.58v5.983h-.052zm-.937-6.815A1.099 1.099 0 017.5 7.842c0-.572.468-1.092 1.092-1.092.573 0 1.093.468 1.093 1.092 0 .625-.468 1.093-1.093 1.093zM16.5 15.75h-1.873v-2.913c0-.677 0-1.561-.936-1.561-.989 0-1.093.728-1.093 1.509v2.965h-1.873V9.767h1.77v.833c.26-.468.832-.937 1.768-.937 1.873 0 2.237 1.249 2.237 2.862v3.225z"
        fill="currentColor"
      />
    </Icon>
  )
}

export default LinkedinIcon
