import React from "react"

import Icon, { IconProps } from "."

const CloseIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon stroke="none" fill="currentColor" {...props}>
      <path
        vectorEffect="non-scaling-stroke"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      ></path>
    </Icon>
  )
}

export default CloseIcon
