import React from "react"

import Icon, { IconProps } from "./"

const SearchIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <circle cx="11" cy="11" r="8" vectorEffect="non-scaling-stroke" />
      <path d="M21 21l-4.35-4.35" vectorEffect="non-scaling-stroke" />
    </Icon>
  )
}

export default SearchIcon
