import { MenuResponse } from "@ignite/api/menus"
import { useMenuContext } from "@ignite/react/context/menuContext"
import Drawer, { DrawerProps } from "@mui/material/Drawer"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import HeaderLogo from "shell/Header/HeaderLogo"
import { makeStyles } from "tss-react/mui"

import DrawerContainer from "../DrawerContainer"
import { toRelativeLink } from "../RelativeLink"
import MobileMenuAuthForm from "./MobileMenuAuthForm"
import MobileMenuContent from "./MobileMenuContent"
import RootMenu from "./RootMenu"

const useStyles = makeStyles()(() => ({
  drawer: {
    "& .MuiDrawer-paperAnchorRight": {
      left: 0,
    },
  },
  page: {
    transition: "transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1)",
    height: "100%",
    justifyContent: "space-between",
  },
  transitionOut: {
    transform: "translateX(100%)",
  },
  transitionIn: {
    transform: "translateX(-100%)",
  },
  reset: {
    transition: "initial",
  },
}))

type ChangeType<T, K extends keyof T, V> = Omit<T, K> & {
  [P in K]: V
}

type MenuParent = {
  parent?: MenuData
}

export type MenuData = ChangeType<
  MenuResponse,
  "children",
  (MenuResponse & MenuParent)[]
> &
  MenuParent

type MobileMenuProps = Pick<DrawerProps, "anchor" | "className"> & {
  toolbarContent?: React.ReactNode
  LogoComponent?: typeof HeaderLogo
  LogoComponentProps?: typeof HeaderLogo.defaultProps
  AuthFormComponent?: typeof MobileMenuAuthForm
  children?: React.ReactNode
}

type MenuOrPanel = MenuData | React.ReactElement | undefined

const MobileMenu: React.FC<MobileMenuProps> = ({
  anchor,
  className,
  toolbarContent,
  LogoComponent,
  LogoComponentProps,
  AuthFormComponent = MobileMenuAuthForm,
  children,
}) => {
  const {
    state: {
      open,
      menus: { data: menus },
    },
    actions: { toggle },
  } = useMenuContext()

  const [currentMenu, setCurrentMenu] = useState<MenuOrPanel>(undefined)
  const [rootMenu, setRootMenu] = useState<MenuData | undefined>(undefined)
  const [nextMenu, setNextMenu] = useState<MenuOrPanel>(undefined)
  const [prevMenu, setPrevMenu] = useState<MenuOrPanel>(undefined)
  const [transitionClass, setTransitionClass] = useState("")

  const anchorValue = anchor || "left"
  const navigate = useNavigate()

  useEffect(() => {
    if (menus) {
      const rootMenu = menus?.find(
        (menu: MenuData) => menu.id === "main"
      ) as MenuData
      const stack = [rootMenu]

      while (stack.length > 0) {
        const item = stack.pop()
        if (!item) {
          continue
        }
        if (item.children) {
          item.children.forEach((child) => {
            stack.push(child)
            child.parent = item
          })
        }
      }

      setRootMenu(rootMenu)
      setCurrentMenu(rootMenu)
    }
  }, [menus])
  const { classes, cx } = useStyles()

  const handleMenuItemClicked = useCallback(
    (item: MenuData) => {
      if (!item.children || item.children.length === 0) {
        toggle()
        setTimeout(() => {
          setCurrentMenu(rootMenu)
          navigate(toRelativeLink(item.url))
        }, 300)

        return
      }
      setNextMenu(item)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggle, rootMenu]
  )

  const handleBackClicked = useCallback(
    (item: MenuData) => {
      setPrevMenu(item)
    },
    [setPrevMenu]
  )

  useEffect(() => {
    if (nextMenu || prevMenu) {
      setTransitionClass(
        (nextMenu && classes.transitionIn) || classes.transitionOut
      )

      const timeout = setTimeout(() => {
        setTransitionClass(classes.reset)
        setNextMenu(undefined)
        setPrevMenu(undefined)
        setCurrentMenu(nextMenu || prevMenu)
      }, 300)

      return () => {
        clearTimeout(timeout)
      }
    }
    return () => {
      //
    }
  }, [
    classes.reset,
    classes.transitionIn,
    classes.transitionOut,
    nextMenu,
    prevMenu,
  ])

  const rootOrSubMenu = (menu?: MenuData, className?: string) => {
    if (!menu || !menu.parent) {
      return (
        <RootMenu
          toolbarContent={toolbarContent}
          menu={menu}
          className={className}
          LogoComponent={LogoComponent}
          LogoComponentProps={LogoComponentProps}
          onMenuItemClicked={handleMenuItemClicked}
          setNextMenu={setNextMenu}
          setPrevMenu={setPrevMenu}
          AuthFormComponent={AuthFormComponent}
        >
          {children}
        </RootMenu>
      )
    }

    return (
      <MobileMenuContent
        className={className}
        onBackClicked={handleBackClicked}
        menu={menu}
        onMenuItemClicked={handleMenuItemClicked}
      />
    )
  }

  const showPanel = (panel: any, className?: string) => {
    return <div className={className}>{panel}</div>
  }

  const menuComponent =
    currentMenu && Object.hasOwnProperty.call(currentMenu, "children")
      ? rootOrSubMenu(currentMenu as MenuData)
      : showPanel(currentMenu)

  const prevMenuComponent =
    prevMenu && rootOrSubMenu(prevMenu as MenuData, classes.transitionIn)

  return (
    <Drawer
      className={classes.drawer}
      anchor={anchorValue}
      open={open}
      onClose={toggle}
    >
      <DrawerContainer anchor={anchorValue} height="100%">
        <div className={cx(classes.page, transitionClass, className)}>
          {prevMenu && prevMenuComponent}

          {menuComponent}

          {nextMenu && (
            <MobileMenuContent
              menu={nextMenu as MenuData}
              className={classes.transitionOut}
            />
          )}
        </div>
      </DrawerContainer>
    </Drawer>
  )
}

export default MobileMenu
